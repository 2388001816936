import {
  APCITankFunctionType,
  APCIUnitType,
  AssetType,
  AuditType,
  TelecommunicationsCarrier,
  DataChannelDataSource,
  DataChannelType,
  EventComparatorType,
  EventImportanceLevelType,
  EventInventoryStatusType,
  EventRuleInventoryStatus,
  EventRuleType,
  ForecastModeType,
  ForecastMode,
  FtpFileFormatCategory,
  GasMixerDataChannelType,
  GeoAreaCategory,
  HornerRtuCategory,
  HornerMessageTemplateType,
  HornerRtuMode,
  OverallPasswordStrength,
  RtuPacketStatus,
  RtuPacketType,
  RtuPacketCategory,
  ProblemReportPriorityEnum,
  RcmJournalItemStatusEnum,
  RTUAutoTimingCorrectionSourceEnum,
  RTUCategoryType,
  RTUPollScheduleType,
  RtuProtocolTypeEnum,
  RTUTransportTypeEnum,
  RTUType,
  ScalingModeType,
  SupportedUOMType,
  TankType,
  TransferAssetResultStatusType,
  UnitConversionModeEnum,
  UnitType,
  UnitTypeEnum,
  UserRoleTypeEnum,
  UserType,
  VirtualChannelOperatorType,
  RtuCallDirection,
  RtuDeviceType,
  RtuDevicePollFilter,
  Metron2RTUChannelInputSignalType,
  RcmTransactionTypeEnum,
  Rtu400SignalSource,
  Rtu400SeriesPortConfigurationType,
  NetworkTechnologyType,
  SmsRtuPowerSource,
  RTUPollTypeEnum,
  MaxDailyTransmissionSmsRtuType,
  SensorLoopVoltageType,
  RTUPowerModeTypeEnum,
  DigitalChannelMode,
  DataLogAlignmentUnit,
  DataLoggingMode,
  ModbusReversePollEdge,
  RawUnitTypeEnum,
  ReversePollDirection,
  Rtu400PowerSource,
  Rtu400DisplayOnError,
  Rtu400DisplayOnUnused,
  Rtu400Board,
  RTU400ExpansionCard,
  RateOfChangeAggregationMode,
  TankSensor,
  Transducer,
  DeviceOperationalState,
  ReportType,
  ReportOutputCategory,
  ReportSendCategory,
  ReportSendFrequencyCategory,
  ReportTimePeriodType,
  UtmRtuCommunicationHourlyMaskType,
  DevicePlatformState,
  RTU400CommunicationSlotModule,
  LBShellRequestStatusType,
  LbShellRequest,
  APCIPaymeterType,
  RTUExpansionCardType,
  DolErrorCode,
} from 'api/admin/api';
import { AssetSubTypeEnum } from 'apps/freezers/types';
import { ProblemReportStatusFilterEnum, DaysRunOut } from 'apps/ops/types';
import { TFunction } from 'i18next';
import { ReadingsChartZoomLevel, UnitDisplayType } from 'types';

export enum RtuPacketsChannelTypeForFilter {
  Any = -1,
  Inbound = 0,
  Outbound = 1,
}

export const buildPaymeterTypeTextMapping = (
  t: TFunction
): Record<APCIPaymeterType, string> => {
  return {
    [APCIPaymeterType.DualRun60835]: t(
      'enum.apcipaymetertype.dualrun60835',
      '60835 - Dual Run'
    ),
    [APCIPaymeterType.MultivariableSingleRun60798]: t(
      'enum.apcipaymetertype.multivariablesinglerun60798',
      '60798 - Multivariable Single Run'
    ),
    [APCIPaymeterType.SingleRun60374]: t(
      t('enum.apcipaymetertype.singlerun60374', '60374 - Single Run')
    ),
    [APCIPaymeterType.StandardDualStationTotalRun60958]: t(
      'enum.apcipaymetertype.standarddualrun60958',
      '60958 - Standard Dual Run w/Station Total'
    ),
  };
};

export const buildReportRequestStatusTextMapping = (
  t: TFunction
): Record<LBShellRequestStatusType, string> => {
  return {
    [LBShellRequestStatusType.InQueue]: t(
      'enum.LBShellRequestStatusType.inqueue',
      'In Queue'
    ),
    [LBShellRequestStatusType.Dropped]: t(
      'enum.LBShellRequestStatusType.dropped',
      'Dropped'
    ),
    [LBShellRequestStatusType.Failed]: t(
      'enum.LBShellRequestStatusType.failed',
      'Failed'
    ),
    [LBShellRequestStatusType.Sent]: t(
      'enum.LBShellRequestStatusType.sent',
      'Sent'
    ),
  };
};

export const buildReportRequesTypeTextMapping = (
  t: TFunction
): Record<LbShellRequest, string> => {
  return {
    [LbShellRequest.GetPriority]: t(
      'enum.lbshellrequesttype.getpriority',
      'Get Priority'
    ),
    [LbShellRequest.PollCustomer]: t(
      'enum.lbshellrequesttype.pollcustomer',
      'Poll Customer'
    ),
    [LbShellRequest.PollFailure]: t(
      'enum.lbshellrequesttype.pollfailure',
      'Poll Failure'
    ),
    [LbShellRequest.RegisterShipTo]: t(
      'enum.lbshellrequesttype.registershipto',
      'Register Ship To'
    ),
    [LbShellRequest.SendPriority]: t(
      'enum.lbshellrequesttype.sendpriority',
      'Send Priority'
    ),
    [LbShellRequest.UnregisterShipTo]: t(
      'enum.lbshellrequesttype.unregistershipto',
      'Unregister Ship To'
    ),
    [LbShellRequest.UpdateReading]: t(
      'enum.lbshellrequesttype.updatereading',
      'Update Reading'
    ),
    [LbShellRequest.UpdateShipTo]: t(
      'enum.lbshellrequesttype.updateshipto',
      'Update Ship To'
    ),
  };
};

export const buildReportTimePeriodTypeTextMapping = (
  t: TFunction
): Record<ReportTimePeriodType, string> => {
  return {
    [ReportTimePeriodType.Custom]: t(
      'enum.reporttimeperiodtype.custom',
      'Custom'
    ),
    [ReportTimePeriodType.PreviousCalendarDay]: t(
      'enum.reporttimeperiodtype.previouscalendarday',
      'Previous Calendar Day'
    ),
    [ReportTimePeriodType.PreviousCalendarWeek]: t(
      'enum.reporttimeperiodtype.previouscalendarweek',
      'Previous Calendar Week'
    ),
    [ReportTimePeriodType.PreviousCalendarMonth]: t(
      'enum.reporttimeperiodtype.previouscalendarmonth',
      'Previous Calendar Month'
    ),
    [ReportTimePeriodType.PreviousCalendarQuarter]: t(
      'enum.reporttimeperiodtype.previouscalendarquarter',
      'Previous Calendar Quarter'
    ),
    [ReportTimePeriodType.PreviousCalendarYear]: t(
      'enum.reporttimeperiodtype.previouscalendaryear',
      'Previous Calendar Year'
    ),
  };
};
export const buildReportSendCategoryTextMapping = (
  t: TFunction
): Record<ReportSendCategory, string> => {
  return {
    [ReportSendCategory.EmailReportInBody]: t(
      'enum.reportsendcategory.emailreportinbody',
      'Email Report In Body'
    ),
    [ReportSendCategory.EmailReportAsAttachment]: t(
      'enum.reportsendcategory.emailreportasattachment',
      'Email Report As Attachment'
    ),
    [ReportSendCategory.FAXReport]: t(
      'enum.reportsendcategory.faxreport',
      'FAX Report'
    ),
  };
};
export const buildReportOutputCategoryTypeTextMapping = (
  t: TFunction
): Record<ReportOutputCategory, string> => {
  // @ts-ignore
  return {
    [ReportOutputCategory.PDF]: t('enum.reportoutputcategory.pdf', 'PDF'),
    [ReportOutputCategory.CSV]: t('enum.reportoutputcategory.csv', 'CSV'),
    [ReportOutputCategory.Excel]: t('enum.reportoutputcategory.excel', 'Excel'),
  };
};

export const buildReportOutputCategoryTypeBodyTextMapping = (
  t: TFunction
): Record<ReportOutputCategory, string> => {
  // @ts-ignore

  return {
    [ReportOutputCategory.HTML]: t('enum.reportoutputcategory.html', 'HTML'),
    [ReportOutputCategory.CSV]: t('enum.reportoutputcategory.csv', 'CSV'),
  };
};

export const buildReportSendFrequencyTextMapping = (
  t: TFunction
): Record<ReportSendFrequencyCategory, string> => {
  return {
    [ReportSendFrequencyCategory.Daily]: t(
      'enum.reportfrequency.daily',
      'Daily'
    ),
    [ReportSendFrequencyCategory.Weekly]: t(
      'enum.reportfrequency.weekly',
      'Weekly'
    ),
    [ReportSendFrequencyCategory.Monthly]: t(
      'enum.reportfrequency.monthly',
      'Monthly'
    ),
    [ReportSendFrequencyCategory.Quarterly]: t(
      'enum.reportfrequency.quarterly',
      'Quarterly'
    ),
  };
};
export const buildRTUTransducterTypeTextMapping = (
  t: TFunction
): Record<Transducer, string> => {
  return {
    [Transducer.Disabled]: t('enum.transductertype.disabled', 'Disabled'),
    [Transducer.Passive]: t('enum.transductertype.passive', 'Passive'),
    [Transducer.Active]: t('enum.transductertype.active', 'Active'),
    [Transducer.Serial]: t('enum.transductertype.serial', 'Serial'),
    [Transducer.Coulomb]: t('enum.transductertype.coulomb', 'Coulomb'),
    [Transducer.Voltage]: t('enum.transductertype.voltage', 'Voltage'),
    [Transducer.Switch]: t('enum.transductertype.switch', 'Switch'),
    [Transducer.ChangeOver]: t('enum.transductertype.changeOver', 'ChangeOver'),
    [Transducer.SmartSerial]: t(
      'enum.transductertype.smartserial',
      'SmartSerial'
    ),
    [Transducer.StandardRange]: t(
      'enum.transductertype.standardrange',
      'StandardRange'
    ),
    [Transducer.ExtendedRange]: t(
      'enum.transductertype.extendedrange',
      'ExtendedRange'
    ),
    [Transducer.ModbusAdaptor5V]: t(
      'enum.transductertype.modbusadaptor5V',
      'ModbusAdaptor5V'
    ),
    [Transducer.AnalogAdaptor5V]: t(
      'enum.transductertype.analogadaptor5V',
      'AnalogAdaptor5V'
    ),
  };
};

export const buildRTUCommunicationDirectionTextMapping = (
  t: TFunction
): Record<RtuPacketsChannelTypeForFilter, string> => {
  return {
    [RtuPacketsChannelTypeForFilter.Any]: t(
      'enum.commdirectiontype.any',
      'Any'
    ),
    [RtuPacketsChannelTypeForFilter.Inbound]: t(
      'enum.rtucommunicationdirection.inbound',
      'Inbound'
    ),
    [RtuPacketsChannelTypeForFilter.Outbound]: t(
      'enum.rtucommunicationdirection.outbound',
      'Outbound'
    ),
  };
};

export const buildRTUSensorTypeTextMapping = (
  t: TFunction
): Record<TankSensor, string> => {
  return {
    [TankSensor.None]: t('enum.rtusensortype.none', 'None'),
    [TankSensor.SC462A020]: t(
      'enum.rtusensortype.SC462A020',
      'SC462A020 - Liquid Level Pressure Sensor, 0.2 bar with 2 meter cable'
    ),
    [TankSensor.SC462B21]: t(
      'enum.rtusensortype.SC462B21',
      'SC462B21 - Liquid Level Pressure Sensor, 0.3 bar with 2 meter cable'
    ),
    [TankSensor.SC462B51]: t(
      'enum.rtusensortype.SC462B51',
      'SC462B51 - Liquid Level Pressure Sensor, 1.0 bar with 5 meter cable'
    ),
    [TankSensor.SC462C050]: t(
      'enum.rtusensortype.SC462C050',
      'SC462C050 - Liquid Level Pressure Sensor, 0.5 bar with 5 meter cable'
    ),
    [TankSensor.SC462D100]: t(
      'enum.rtusensortype.SC462D100',
      'SC462D100 - Liquid Level Pressure Sensor, 1.0 bar with 10 meter cable'
    ),
    [TankSensor.SC462E150]: t(
      'enum.rtusensortype.SC462E150',
      'SC462E150 - Liquid Level Pressure Sensor, 1.5 bar with 15 meter cable'
    ),
    [TankSensor.RadarSensorUpTo3m]: t(
      'enum.rtusensortype.RadarSensorUpTo3m',
      'Radar Level Sensor Range'
    ),
    [TankSensor.RadarSensorUpTo7m]: t(
      'enum.rtusensortype.RadarSensorUpTo7m',
      'Radar Sensor Up To 7m'
    ),
    [TankSensor.SC461A0B0]: t(
      'enum.rtusensortype.SC461A0B0',
      'SC461A0B0 - Pressure Transmitter 400 bar, DIN connector, ATEX'
    ),
    [TankSensor.SC461A0B1]: t(
      'enum.rtusensortype.SC461A0B1',
      'SC461A0B1 - Pressure Transmitter 400 bar, DIN connector, Oxygen cleaned'
    ),
    [TankSensor.SC461A1B0]: t(
      'enum.rtusensortype.SC461A1B0',
      'SC461A1B0 - Pressure Transmitter 400 bar, M12 connector, ATEX'
    ),
    [TankSensor.SC461A1B1]: t(
      'enum.rtusensortype.SC461A1B1',
      'SC461A1B1 - Pressure Transmitter 400 bar, M12 connector, Oxygen cleaned'
    ),
    [TankSensor.SC461B0B0]: t(
      'enum.rtusensortype.SC461B0B0',
      'SC461B0B0 - Pressure Transmitter, 2.5 bar, 1m cable with DIN connector, ATEX'
    ),
    [TankSensor.SC461B1B0]: t(
      'enum.rtusensortype.SC461B1B0',
      'SC461B1B0 - Pressure Transmitter, 2.5 bar, 1m cable with M12 connector, ATEX'
    ),
    [TankSensor.RadarSensorPercentage]: t(
      'enum.rtusensortype.RadarSensorPercentage',
      'Radar Level Sensor Percentage'
    ),
    [TankSensor.RochesterMagnetel4R3D]: t(
      'enum.rtusensortype.RochesterMagnetel4R3D',
      '	Rochester Magnetel 4" R3D'
    ),
    [TankSensor.RochesterMagnetel8R3D]: t(
      'enum.rtusensortype.RochesterMagnetel8R3D',
      '	Rochester Magnetel 8" R3D'
    ),
    [TankSensor.SC469M22Magnetel4GenIV]: t(
      'enum.rtusensortype.SC469M22Magnetel4GenIV',
      'SC469M22 - Magnetel 4" R3D Gen IV'
    ),
    [TankSensor.SC469M22Magnetel8GenIV]: t(
      'enum.rtusensortype.SC469M22Magnetel8GenIV',
      'SC469M22 - Magnetel 8" R3D Gen IV'
    ),
    [TankSensor.SC469B11RochesterGenIVHorizontal]: t(
      'enum.rtusensortype.SC469B11RochesterGenIVHorizontal',
      'SC469B11 - Rochester R3D Gen IV Horizontal'
    ),
    [TankSensor.SC469B12RochesterGenIVHorizontalStraightCable]: t(
      'enum.rtusensortype.SC469B12RochesterGenIVHorizontalStraightCable',
      'SC469B12 - Rochester R3D Gen IV Horizontal - Straight cable'
    ),
    [TankSensor.SC469B11RochesterGenIVVertical]: t(
      'enum.rtusensortype.SC469B11RochesterGenIVVertical',
      'SC469B11 - Rochester R3D Gen IV Vertical'
    ),
    [TankSensor.SC469B12RochesterGenIVVerticalStraightCable]: t(
      'enum.rtusensortype.SC469B12RochesterGenIVVerticalStraightCable',
      'SC469B12 - Rochester R3D Gen IV Vertical - Straight cable'
    ),
    [TankSensor.SC670E11External420mA]: t(
      'enum.rtusensortype.SC670E11External420mA',
      'SC670E11 - External Power 4-20mA'
    ),
    [TankSensor.SENS1201Wika053]: t(
      'enum.rtusensortype.SENS1201Wika053',
      'SENS-1201 0-53" Wika Level (UTM)'
    ),
    [TankSensor.SENS1221Wika053]: t(
      'enum.rtusensortype.SENS1221Wika053',
      'SENS-1221 0-53" Wika Level (UTM)'
    ),
    [TankSensor.RotarexCSticLite]: t(
      'enum.rtusensortype.RotarexCSticLite',
      'Rotarex C-Stic Lite'
    ),
  };
};

export const buildMetronRTUCommunicationDirectionTextMapping = (
  t: TFunction
): Record<RtuCallDirection, string> => {
  return {
    [RtuCallDirection.Both]: t('enum.commdirectiontype.both', 'Both'),
    [RtuCallDirection.Inbound]: t(
      'enum.rtucommunicationdirection.inbound',
      'Inbound'
    ),
    [RtuCallDirection.Outbound]: t(
      'enum.rtucommunicationdirection.outbound',
      'Outbound'
    ),
  };
};

export const buildMetronRTOChannelInputSignalTypeTextMapping = (
  t: TFunction
): Record<Metron2RTUChannelInputSignalType, string> => {
  return {
    [Metron2RTUChannelInputSignalType.MAmps4To20]: t(
      'enum.inputsignaltype.4to20mA',
      '4 to 20 mA'
    ),
    [Metron2RTUChannelInputSignalType.Volts0To10]: t(
      'enum.inputsignaltype.0to10Volts',
      '0 to 10 Volts'
    ),
  };
};

export const buildMetronRTUPowerModeTextMapping = (t: TFunction) => {
  return {
    LowPower: t('enum.powermodetype.lowpower', 'Low Power'),
    Pollable: t('enum.powermodetype.pollable', 'Pollable'),
  };
};

export const buildMetronRTUAntennaTypeTextMapping = (t: TFunction) => {
  return {
    true: t('ui.rtu.externalAntenna', 'External'),
    false: t('ui.rtu.internalAntenna', 'Internal'),
  };
};

export const buildRTU400SetPointTypeTextMapping = (t: TFunction) => {
  return {
    true: t('ui.rtu.falling', 'Rising'),
    false: t('ui.rtu.rising', 'Falling'),
  };
};

export const buildRTUModbusReversePollDirectionTypeTextMapping = (
  t: TFunction
) => {
  return {
    [ReversePollDirection.Falling]: t(
      'enum.modbusreversepolldirection.falling',
      'Falling'
    ),
    [ReversePollDirection.Raising]: t(
      'enum.modbusreversepolldirection.rising',
      'Rising'
    ),
  };
};

export const buildRTU400PowerSourceTextMapping = (t: TFunction) => {
  return {
    [Rtu400PowerSource.PrimaryBattery]: t(
      'enum.rtu400seriespowersourcetype.primarybattery',
      'Primary Battery'
    ),
    [Rtu400PowerSource.MainsOnly]: t(
      'enum.rtu400seriespowersourcetype.mainsonly',
      'Mains Only'
    ),
    [Rtu400PowerSource.SolarBattery]: t(
      'enum.rtu400seriespowersourcetype.solarbattery',
      'Solar/Battery'
    ),
    [Rtu400PowerSource.MainsBattery]: t(
      'enum.rtu400seriespowersourcetype.mainsbattery',
      'Mains/Battery'
    ),
  };
};

export const buildRTU400DisplayErrorTextMapping = (t: TFunction) => {
  return {
    [Rtu400DisplayOnError.Value]: t(
      'enum.rtu400seriesdisplayonerrortype.value',
      'Value'
    ),
    [Rtu400DisplayOnError.Error]: t(
      'enum.rtu400seriesdisplayonerrortype.error',
      'Error'
    ),
    [Rtu400DisplayOnError.UnderOrOver]: t(
      'enum.rtu400seriesdisplayonerrortype.underorover',
      'Under or Over'
    ),
  };
};

export const buildRTU400DisplayUnusedTextMapping = (t: TFunction) => {
  return {
    [Rtu400DisplayOnUnused.Zeros]: t(
      'enum.rtu400seriesdisplayonunusedtype.zeros',
      '0.0'
    ),
    [Rtu400DisplayOnUnused.Dashes]: t(
      'enum.rtu400seriesdisplayonunusedtype.dashes',
      'Dashes'
    ),
    [Rtu400DisplayOnUnused.Blank]: t(
      'enum.rtu400seriesdisplayonunusedtype.blank',
      'Blank'
    ),
  };
};

export const buildRTU400CardTypeTextMapping = (t: TFunction) => {
  return {
    [Rtu400Board.IR246]: t('enum.rtuexpansioncardtype.ir246', 'IR246'),
    [Rtu400Board.AE280]: t('enum.rtuexpansioncardtype.ae280', 'AE280'),
    [Rtu400Board.DI303]: t('enum.rtuexpansioncardtype.di303', 'DI303'),
    [Rtu400Board.AR217]: t('enum.rtuexpansioncardtype.ar217', 'AR217'),
    [Rtu400Board.AE407]: t('enum.rtuexpansioncardtype.ae407', 'AE407'),
    [Rtu400Board.CO243]: t('enum.rtuexpansioncardtype.co243', 'CO243'),
    [Rtu400Board.CL310]: t('enum.rtuexpansioncardtype.cl310', 'CL310'),
    [Rtu400Board.IR247]: t('enum.rtuexpansioncardtype.ir247', 'IR247'),
    [Rtu400Board.SS468]: t('enum.rtuexpansioncardtype.ss468', 'SS468'),
    [Rtu400Board.IS453]: t('enum.rtuexpansioncardtype.is453', 'IS453'),
    [Rtu400Board.TM416]: t('enum.rtuexpansioncardtype.tm416', 'TM416'),
    [Rtu400Board.UT459]: t('enum.rtuexpansioncardtype.ut459', 'UT459'),
    [Rtu400Board.VI271]: t('enum.rtuexpansioncardtype.vi271', 'VI271'),
    [Rtu400Board.VI388]: t('enum.rtuexpansioncardtype.vi388', 'VI388'),
    [Rtu400Board.SB457]: t('enum.rtuexpansioncardtype.sb457', 'SB457'),
    [Rtu400Board.AD481]: t('enum.rtuexpansioncardtype.ad481', 'AD481'),
    [Rtu400Board.VM394]: t('enum.rtuexpansioncardtype.vm394', 'VM394'),
    [Rtu400Board.EG501]: t('enum.rtuexpansioncardtype.eg501', 'EG501'),
    [Rtu400Board.PR451]: t('enum.rtuexpansioncardtype.pr451', 'PR451'),
    [Rtu400Board.EG504]: t('enum.rtuexpansioncardtype.eg504', 'EG504'),
    [Rtu400Board.EX587]: t('enum.rtuexpansioncardtype.ex587', 'EX587'),
    [Rtu400Board.EX487]: t('enum.rtuexpansioncardtype.ex487', 'EX487'),
    [Rtu400Board.BC474]: t('enum.rtuexpansioncardtype.bc474', 'BC474'),
    [Rtu400Board.LC490]: t('enum.rtuexpansioncardtype.lc490', 'LC490'),
    [Rtu400Board.DP489]: t('enum.rtuexpansioncardtype.dp489', 'DP489'),
    [Rtu400Board.AS452]: t('enum.rtuexpansioncardtype.as452', 'AS452'),
    [Rtu400Board.GU476]: t('enum.rtuexpansioncardtype.gu476', 'GU476'),
  };
};

export const buildRTU400ExpansionCardTextMapping = (t: TFunction) => {
  return {
    [RTU400ExpansionCard.EX487]: t(
      'enum.rtu400seriesexpansioncardtype.ex487',
      'EX487'
    ),
    [RTU400ExpansionCard.SS468]: t('enum.rtuexpansioncardtype.ss468', 'SS468'),
    [RTU400ExpansionCard.AD481]: t('enum.rtuexpansioncardtype.ad481', 'AD481'),
    [RTU400ExpansionCard.EX587]: t('enum.rtuexpansioncardtype.ex587', 'EX587'),
  };
};
export const buildRTU400PortConfigurationTextMapping = (t: TFunction) => {
  return {
    [Rtu400SeriesPortConfigurationType.None]: t(
      'enum.rtu400seriesportconfiguration.none',
      'None'
    ),
    [Rtu400SeriesPortConfigurationType.MainBoard_0_Input]: t(
      'enum.rtu400seriesportconfiguration.mainboard0input',
      'INPUT1 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.MainBoard_1_Input]: t(
      'enum.rtu400seriesportconfiguration.mainboard1input',
      'INPUT2 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.MainBoard_2_Utd]: t(
      'enum.rtu400seriesportconfiguration.mainboard2utd',
      'DT1 - UTD20'
    ),
    [Rtu400SeriesPortConfigurationType.MainBoard_3_CStic]: t(
      'enum.rtu400seriesportconfiguration.mainboard3cstic',
      'DT1 - C-Stic'
    ),
    [Rtu400SeriesPortConfigurationType.MainBoard_4_Utd]: t(
      'enum.rtu400seriesportconfiguration.mainboard4utd',
      'DT2 - UTD20'
    ),
    [Rtu400SeriesPortConfigurationType.MainBoard_5_CStic]: t(
      'enum.rtu400seriesportconfiguration.mainboard5cstic',
      'DT2 - C-Stic'
    ),
    [Rtu400SeriesPortConfigurationType.MainBoard_6_Potentiometer]: t(
      'enum.rtu400seriesportconfiguration.mainboard6potentiometer',
      'MAG - Potentiometer'
    ),
    [Rtu400SeriesPortConfigurationType.AE280_0_Input]: t(
      'enum.rtu400seriesportconfiguration.ae2800input',
      'INPUT3 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.AE280_1_Input]: t(
      'enum.rtu400seriesportconfiguration.ae2801input',
      'INPUT4 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.DI303_0_NoNc]: t(
      'enum.rtu400seriesportconfiguration.di3030nonc',
      'DIG1 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.DI303_1_NoNc]: t(
      'enum.rtu400seriesportconfiguration.di3031nonc',
      'DIG2 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.DI303_2_NoNc]: t(
      'enum.rtu400seriesportconfiguration.di3032nonc',
      'DIG3 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.DI303_3_NoNc]: t(
      'enum.rtu400seriesportconfiguration.di3033nonc',
      'DIG4 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.AR217_0_Rc]: t(
      'enum.rtu400seriesportconfiguration.ar2170rc',
      'RL1 - RC'
    ),
    [Rtu400SeriesPortConfigurationType.AR217_1_Rc]: t(
      'enum.rtu400seriesportconfiguration.ar2171rc',
      'RL2 - RC'
    ),
    [Rtu400SeriesPortConfigurationType.AE407_0_Input]: t(
      'enum.rtu400seriesportconfiguration.ae4070input',
      'INPUT3 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.AE407_1_Input]: t(
      'enum.rtu400seriesportconfiguration.ae4071input',
      'INPUT4 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.AE407_2_Input]: t(
      'enum.rtu400seriesportconfiguration.ae4072input',
      'INPUT5 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.AE407_3_Input]: t(
      'enum.rtu400seriesportconfiguration.ae4073input',
      'INPUT6 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.AE407_0_NoNc]: t(
      'enum.rtu400seriesportconfiguration.ae4070nonc',
      'SOLENOID - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.CO243_0_Output]: t(
      'enum.rtu400seriesportconfiguration.cl3100output',
      'OUTPUT-4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.CL310_0_Output]: t(
      'enum.rtu400seriesportconfiguration.co2430output',
      'OUTPUT-4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_0_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex4870analoginput',
      'INPUT1 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_1_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex4871analoginput',
      'INPUT2 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_2_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex4872analoginput',
      'INPUT3 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_3_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex4873analoginput',
      'INPUT4 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_4_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex4874analoginput',
      'INPUT5 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_5_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex4875analoginput',
      'INPUT6 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_6_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex4876analoginput',
      'INPUT7 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_7_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex4877analoginput',
      'INPUT8 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_0_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex4870digitalinput',
      'DIG1 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_1_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex4871digitalinput',
      'DIG2 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_2_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex4872digitalinput',
      'DIG3 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_3_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex4873digitalinput',
      'DIG4 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_4_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex4874digitalinput',
      'DIG5 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_5_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex4875digitalinput',
      'DIG6 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_6_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex4876digitalinput',
      'DIG7 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX487_7_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex4877digitalinput',
      'DIG8 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.SS468_0_RS485LEVEL]: t(
      'enum.rtu400seriesportconfiguration.ss4680rs485level',
      'RS485 - LEVEL'
    ),
    [Rtu400SeriesPortConfigurationType.SS468_1_RS485TEMP]: t(
      'enum.rtu400seriesportconfiguration.ss4681rs485temp',
      'RS485 - TEMP'
    ),
    [Rtu400SeriesPortConfigurationType.IS453_0_INPUT3]: t(
      'enum.rtu400seriesportconfiguration.is4530input3',
      'INPUT3 - Analog Acceleration'
    ),
    [Rtu400SeriesPortConfigurationType.AS452_0_INPUT3]: t(
      'enum.rtu400seriesportconfiguration.as4520input3',
      'INPUT3 - Digital Acceleration'
    ),
    [Rtu400SeriesPortConfigurationType.TM416_0_INPUT3]: t(
      'enum.rtu400seriesportconfiguration.tm4160input3',
      'INPUT3 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.TM416_1_INPUT4]: t(
      'enum.rtu400seriesportconfiguration.tm4161input4',
      'INPUT4 - Resistive'
    ),
    [Rtu400SeriesPortConfigurationType.UT459_0_SGILoadCell]: t(
      'enum.rtu400seriesportconfiguration.ut4590sgiloadcell',
      'SGI - Load Cell'
    ),
    [Rtu400SeriesPortConfigurationType.VI271_0_INPUT3]: t(
      'enum.rtu400seriesportconfiguration.vi2710input3',
      'INPUT3 - Voltage'
    ),
    [Rtu400SeriesPortConfigurationType.VI271_1_INPUT4]: t(
      'enum.rtu400seriesportconfiguration.vi2711input4',
      'INPUT4 - Voltage'
    ),
    [Rtu400SeriesPortConfigurationType.VI388_0_INPUT3]: t(
      'enum.rtu400seriesportconfiguration.vi3880input3',
      'INPUT3 - Voltage'
    ),
    [Rtu400SeriesPortConfigurationType.VI388_1_INPUT4]: t(
      'enum.rtu400seriesportconfiguration.vi3881input4',
      'INPUT4 - Voltage'
    ),
    [Rtu400SeriesPortConfigurationType.SB457_0_STROBE_SIREN_RC]: t(
      'enum.rtu400seriesportconfiguration.sb457.0.strobe.siren.rc',
      '0 - STROBE/SIREN - RC'
    ),
    [Rtu400SeriesPortConfigurationType.LC490_0_0To5Vdc]: t(
      'enum.rtu400seriesportconfiguration.lc490.0.0to5vdc',
      '0 - 0-5VDC - Voltage'
    ),
    [Rtu400SeriesPortConfigurationType.LC490_1_Dt1_Utd]: t(
      'enum.rtu400seriesportconfiguration.lc490.1.dt1.utd',
      '1 - DT1 - UTD20'
    ),
    [Rtu400SeriesPortConfigurationType.LC490_2_Dt1_CStic]: t(
      'enum.rtu400seriesportconfiguration.lc490.2.dt2.cstic',
      '2 - DT2 - C-Stic'
    ),
    [Rtu400SeriesPortConfigurationType.LC490_3_Potentiometer]: t(
      'enum.rtu400seriesportconfiguration.lc490.3.potentiometer',
      '3 - MAG - Potentiometer'
    ),
    [Rtu400SeriesPortConfigurationType.LC490_4_4to20Ma]: t(
      'enum.rtu400seriesportconfiguration.lc490.4.4to20ma',
      '4 - 4-20mA - Current Loop'
    ),
    [Rtu400SeriesPortConfigurationType.LC490_0_Din]: t(
      'enum.rtu400seriesportconfiguration.lc490.0.din',
      '0 - DIN - Dry Contact'
    ),
    [Rtu400SeriesPortConfigurationType.BC474_0_0To5Vdc]: t(
      'enum.rtu400seriesportconfiguration.bc474.0.0to5vdc',
      '0 - 0-5VDC - Voltage'
    ),
    [Rtu400SeriesPortConfigurationType.BC474_1_Dt1_Utd]: t(
      'enum.rtu400seriesportconfiguration.bc474.1.dt1.utd',
      '1 - DT1 - UTD20'
    ),
    [Rtu400SeriesPortConfigurationType.BC474_2_Dt1_CStic]: t(
      'enum.rtu400seriesportconfiguration.bc474.2.dt1.cstic',
      '2 - DT1 - C-Stic'
    ),
    [Rtu400SeriesPortConfigurationType.BC474_3_Potentiometer]: t(
      'enum.rtu400seriesportconfiguration.bc474.3.potentiometer',
      '3 - MAG - Potentiometer'
    ),
    [Rtu400SeriesPortConfigurationType.BC474_4_Sonar]: t(
      'enum.rtu400seriesportconfiguration.bc474.4.sonar',
      '4 - SONAR - Serial'
    ),
    [Rtu400SeriesPortConfigurationType.BC474_5_Sonar]: t(
      'enum.rtu400seriesportconfiguration.bc474.5.sonar',
      '5 - SONAR - Serial'
    ),
    [Rtu400SeriesPortConfigurationType.BC474_6_Lc1Lc2Lc3]: t(
      'enum.rtu400seriesportconfiguration.bc474.6.lc1lc2lc3',
      '6 - LC1,LC2,LC3 - Volt Bridge'
    ),
    [Rtu400SeriesPortConfigurationType.GU476_2_0To5Vdc]: t(
      'enum.rtu400seriesportconfiguration.gu476.2.0to5vdc',
      '2 - 0-5VDC - Voltage'
    ),
    [Rtu400SeriesPortConfigurationType.GU476_0_Dt1_Utd]: t(
      'enum.rtu400seriesportconfiguration.gu476.0.dt1.utd20',
      '0 - DT1 - UTD20'
    ),
    [Rtu400SeriesPortConfigurationType.AD481_0_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ad481.0.4to20ma',
      'AI1 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.AD481_1_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ad481.1.4to20ma',
      'AI2 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.AD481_2_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ad481.2.4to20ma',
      'AI3 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.AD481_0_CounterInput]: t(
      'enum.rtu400seriesportconfiguration.ad481.0.ci1.counter',
      'CI1 - Counter'
    ),
    [Rtu400SeriesPortConfigurationType.AD481_1_CounterInput]: t(
      'enum.rtu400seriesportconfiguration.ad481.0.ci2.counter',
      'CI2 - Counter'
    ),
    [Rtu400SeriesPortConfigurationType.AD481_0_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ad481.0.ci1.digital',
      'CI1 - Digital'
    ),
    [Rtu400SeriesPortConfigurationType.AD481_1_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ad481.0.ci2.digital',
      'CI2 - Digital'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_0_C1]: t(
      'enum.rtu400seriesportconfiguration.dp489.0.c1',
      'C1'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_1_C2]: t(
      'enum.rtu400seriesportconfiguration.dp489.1.c2',
      'C2'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_2_C3]: t(
      'enum.rtu400seriesportconfiguration.dp489.2.c3',
      'C3'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_3_C4]: t(
      'enum.rtu400seriesportconfiguration.dp489.3.c4',
      'C4'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_4_C5]: t(
      'enum.rtu400seriesportconfiguration.dp489.4.c5',
      'C5'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_5_C6]: t(
      'enum.rtu400seriesportconfiguration.dp489.5.c6',
      'C6'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_6_C7]: t(
      'enum.rtu400seriesportconfiguration.dp489.6.c7',
      'C7'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_7_C8]: t(
      'enum.rtu400seriesportconfiguration.dp489.7.c8',
      'C8'
    ),
    [Rtu400SeriesPortConfigurationType.VM394_0_TC1]: t(
      'enum.rtu400seriesportconfiguration.vm394.0.tc1',
      '0 - TC1'
    ),
    [Rtu400SeriesPortConfigurationType.VM394_1_TC2]: t(
      'enum.rtu400seriesportconfiguration.vm394.1.tc2',
      '1 - TC2'
    ),
    [Rtu400SeriesPortConfigurationType.VM394_2_TC3]: t(
      'enum.rtu400seriesportconfiguration.vm394.2.tc3',
      '2 - TC3'
    ),
    [Rtu400SeriesPortConfigurationType.VM394_0_RL1]: t(
      'enum.rtu400seriesportconfiguration.vm394.0.rl1',
      '0 - RL1'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_0_I1B_Current]: t(
      'enum.rtu400seriesportconfiguration.eg501.0.i1b.current',
      '0 - I1B - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_1_I2B_Current]: t(
      'enum.rtu400seriesportconfiguration.eg501.1.i2b.current',
      '1 - I2B - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_2_I1A_Voltage_Absolute]: t(
      'enum.rtu400seriesportconfiguration.eg501.2.i1a.voltage.absolute',
      '2 - I1A - Voltage - Absolute'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_3_I1A_Voltage_Ratio]: t(
      'enum.rtu400seriesportconfiguration.eg501.3.i1a.voltage.ratio',
      '3 - I1A - Voltage - Ratio'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_4_I2A_Voltage_Absolute]: t(
      'enum.rtu400seriesportconfiguration.eg501.4.i2a.voltage.absolute',
      '4 - I2A - Voltage - Absolute'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_5_I2A_Voltage_Ratio]: t(
      'enum.rtu400seriesportconfiguration.eg501.5.i2a.voltage.ratio',
      '5 - I2A - Voltage - Ratio'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_6_Dt1_Utd]: t(
      'enum.rtu400seriesportconfiguration.eg501.6.dt1.utd',
      '6 - DT1 - UTD20'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_7_Dt1_Rotarex_Cstic]: t(
      'enum.rtu400seriesportconfiguration.eg501.7.dt1.rotarex.cstic',
      '7 - DT1 - C-Stic'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_8_Dt1_Quantum_Cstic]: t(
      'enum.rtu400seriesportconfiguration.eg501.8.dt1.quantum.cstic',
      '8 - DT1 - Q-Stic'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_9_LC1]: t(
      'enum.rtu400seriesportconfiguration.eg501.9.lc1',
      'LC1 - Load Cell'
    ),
    [Rtu400SeriesPortConfigurationType.VM394_3_DIFF_TC1_TC2]: t(
      'enum.rtu400seriesportconfiguration.vm394.3.diff.tc1.tc2',
      '9 - DIFF - TC1 - TC2'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_10_Dt1_Roto]: t(
      'enum.rtu400seriesportconfiguration.eg501.10.dt1.roto',
      '10 - DT1 - Roto-Tel + Roto-Cal'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_0_GasMixing]: t(
      'enum.rtu400seriesportconfiguration.dp489.0.gasmixing',
      '0 - GasMixing'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_1_GasMixing]: t(
      'enum.rtu400seriesportconfiguration.dp489.1.gasmixing',
      '1 - GasMixing'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_0_Remote]: t(
      'enum.rtu400seriesportconfiguration.dp489.0.remote',
      '0 - Remote'
    ),
    [Rtu400SeriesPortConfigurationType.PR451_0_INPUT1]: t(
      'enum.rtu400seriesportconfiguration.pr451.0.input1',
      '0 - INPUT1'
    ),
    [Rtu400SeriesPortConfigurationType.PR451_1_INPUT2]: t(
      'enum.rtu400seriesportconfiguration.pr451.1.input2',
      '1 - INPUT2'
    ),
    [Rtu400SeriesPortConfigurationType.PR451_0_INPUT3]: t(
      'enum.rtu400seriesportconfiguration.pr451.0.input3',
      '0 - INPUT3'
    ),

    [Rtu400SeriesPortConfigurationType.EX587_0_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex5870analoginput',
      '0 - INPUT1 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_1_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex5871analoginput',
      '1 - INPUT2 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_2_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex5872analoginput',
      '2 - INPUT3 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_3_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex5873analoginput',
      '3 - INPUT4 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_4_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex5874analoginput',
      '4 - INPUT5 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_5_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex5875analoginput',
      '5 - INPUT6 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_6_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex5876analoginput',
      '6 - INPUT7 - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_7_AnalogInput]: t(
      'enum.rtu400seriesportconfiguration.ex5877analoginput',
      '7 - INPUT8 - 4-20mA'
    ),

    [Rtu400SeriesPortConfigurationType.EX587_0_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex5870digitalinput',
      '0 - DIG1 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_1_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex5871digitalinput',
      '1 - DIG2 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_2_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex5872digitalinput',
      '2 - DIG3 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_3_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex5873digitalinput',
      '3 - DIG4 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_4_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex5874digitalinput',
      '4 - DIG5 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_5_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex5875digitalinput',
      '5 - DIG6 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_6_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex5876digitalinput',
      '6 - DIG7 - NO/NC'
    ),
    [Rtu400SeriesPortConfigurationType.EX587_7_DigitalInput]: t(
      'enum.rtu400seriesportconfiguration.ex5877digitalinput',
      '7 - DIG8 - NO/NC'
    ),

    [Rtu400SeriesPortConfigurationType.DP489_UTD22_8_DT1]: t(
      'enum.rtu400seriesportconfiguration.dp489utd228dt1',
      'DT1 - UTD22 - DP'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_UTD22_9_DT1]: t(
      'enum.rtu400seriesportconfiguration.dp489utd229dt1',
      'DT1 - UTD22 - Pressure'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_UTD22_10_DT2]: t(
      'enum.rtu400seriesportconfiguration.dp489utd2210dt2',
      'DT2 - UTD22 - DP'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_UTD22_11_DT2]: t(
      'enum.rtu400seriesportconfiguration.dp489utd2211dt2',
      'DT2 - UTD22 - Pressure'
    ),

    [Rtu400SeriesPortConfigurationType.BC474_UTD22_7_DT1]: t(
      'enum.rtu400seriesportconfiguration.bc474utd227dt1',
      'DT1 - UTD22 - DP'
    ),
    [Rtu400SeriesPortConfigurationType.BC474_UTD22_8_DT1]: t(
      'enum.rtu400seriesportconfiguration.bc474utd228dt1',
      'DT1 - UTD22 - Pressure'
    ),

    [Rtu400SeriesPortConfigurationType.EG501_UTD22_11_DT1]: t(
      'enum.rtu400seriesportconfiguration.eg501utd2211dt1',
      'DT1 - UTD22 - DP'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_UTD22_12_DT1]: t(
      'enum.rtu400seriesportconfiguration.eg501utd2212dt1',
      'DT1 - UTD22 - Pressure'
    ),

    [Rtu400SeriesPortConfigurationType.EG504_0_I1B_Current]: t(
      'enum.rtu400seriesportconfiguration.eg504.0.i1b.current',
      '0 - I1B - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_1_I2B_Current]: t(
      'enum.rtu400seriesportconfiguration.eg504.1.i2b.current',
      '1 - I2B - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_2_I1A_Voltage_Absolute]: t(
      'enum.rtu400seriesportconfiguration.eg504.2.i1a.voltage.absolute',
      'I1A - Voltage - Absolute'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_3_I1A_Voltage_Ratio]: t(
      'enum.rtu400seriesportconfiguration.eg504.3.i1a.voltage.ratio',
      'I1A - Voltage - Ratio'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_4_I2A_Voltage_Absolute]: t(
      'enum.rtu400seriesportconfiguration.eg504.4.i2a.voltage.absolute',
      'I2A - Voltage - Absolute'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_5_I2A_Voltage_Ratio]: t(
      'enum.rtu400seriesportconfiguration.eg504.5.i2a.voltage.ratio',
      'I2A - Voltage - Ratio'
    ),

    [Rtu400SeriesPortConfigurationType.EG504_6_Dt1_Utd]: t(
      'enum.rtu400seriesportconfiguration.eg504.6.dt1.utd',
      'DT1 - UTD20'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_7_Dt1_Rotarex_Cstic]: t(
      'enum.rtu400seriesportconfiguration.eg504.7.dt1.rotarex.cstic',
      'DT1 - C-Stic'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_8_Dt1_Quantum_Cstic]: t(
      'enum.rtu400seriesportconfiguration.eg504.8.dt1.quantum.cstic',
      'DT1 - Q-Stic'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_9_LC1]: t(
      'enum.rtu400seriesportconfiguration.eg504.9.lc1',
      'LC1 - Load Cell'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_10_Dt1_Roto]: t(
      'enum.rtu400seriesportconfiguration.eg504.10.dt1.roto',
      'DT1 - Roto20'
    ),

    [Rtu400SeriesPortConfigurationType.EG504_UTD22_11_DT1]: t(
      'enum.rtu400seriesportconfiguration.eg504utd2211dt1',
      '11 - UTD22 - DP'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_UTD22_12_DT1]: t(
      'enum.rtu400seriesportconfiguration.eg504utd2212dt1',
      '12 - UTD22 - Pressure'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_UTR30_12_DT1]: t(
      'enum.rtu400seriesportconfiguration.DP489_UTR30_12_DT1',
      '12 - DT1 - UTR30'
    ),
    [Rtu400SeriesPortConfigurationType.DP489_UTR30_13_DT2]: t(
      'enum.rtu400seriesportconfiguration.DP489_UTR30_13_DT2',
      '13 - DT2 - UTR30'
    ),
    [Rtu400SeriesPortConfigurationType.EG501_UTR30_12_DT1]: t(
      'enum.rtu400seriesportconfiguration.EG501_UTR30_12_DT1',
      '12 - DT1 - UTR30'
    ),
    [Rtu400SeriesPortConfigurationType.EG504_UTR30_12_DT1]: t(
      'enum.rtu400seriesportconfiguration.EG504_UTR30_12_DT1 ',
      '12 - DT1 - UTR30'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_UTR30_12_DT1]: t(
      'enum.rtu400seriesportconfiguration.DP505_UTR30_12_DT1',
      '12 - DT1 - UTR30'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_0_I1B_Current]: t(
      'enum.rtu400seriesportconfiguration.DP505_0_I1B_Current ',
      '0 - I1B - 4-20mA'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_1_I2B_Current]: t(
      'enum.rtu400seriesportconfiguration.DP505_1_I2B_Current',
      '1 - I2B - 4-20mA'
    ),

    [Rtu400SeriesPortConfigurationType.DP505_2_I1A_Voltage_Absolute]: t(
      'enum.rtu400seriesportconfiguration.DP505_2_I1A_Voltage_Absolute',
      '2 - I1A - Voltage - Absolute'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_3_I1A_Voltage_Ratio]: t(
      'enum.rtu400seriesportconfiguration.DP505_3_I1A_Voltage_Ratio',
      '3 - I1A - Voltage - Ratio'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_4_I2A_Voltage_Absolute]: t(
      'enum.rtu400seriesportconfiguration.DP505_4_I2A_Voltage_Absolute ',
      '4 - I2A - Voltage - Absolute'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_5_I2A_Voltage_Ratio]: t(
      'enum.rtu400seriesportconfiguration.DP505_5_I2A_Voltage_Ratio',
      '5 - I2A - Voltage - Ratio'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_6_Dt1_Utd]: t(
      'enum.rtu400seriesportconfiguration.DP505_6_Dt1_Utd',
      '6 - Dt1 - Utd20'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_7_Dt1_Rotarex_Cstic]: t(
      'enum.rtu400seriesportconfiguration.DP505_7_Dt1_Rotarex_Cstic',
      '7 - Dt1 - Rotarex C-stic'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_8_Dt1_Quantum_Cstic]: t(
      'enum.rtu400seriesportconfiguration.DP505_8_Dt1_Quantum_Cstic',
      '8 - Dt1 - Quantum C-stic'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_9_LC1]: t(
      'enum.rtu400seriesportconfiguration.DP505_9_LC1',
      '9 - LC1 - Load Cell'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_UTD22_10_DT1]: t(
      'enum.rtu400seriesportconfiguration. DP505_UTD22_10_DT1',
      '10 - DT1 - UTD22 - D.P.'
    ),
    [Rtu400SeriesPortConfigurationType.DP505_UTD22_11_DT1]: t(
      'enum.rtu400seriesportconfiguration.DP505_UTD22_11_DT1 ',
      '11 - DT1 - UTD22 - Pressure'
    ),
  };
};

export const buildRTU400SignalSourceTextMapping = (t: TFunction) => {
  return {
    [Rtu400SignalSource.Unused]: t(
      'enum.rtu400seriessignalsource.unused',
      'Unused'
    ),
    [Rtu400SignalSource.OnMainBoard]: t(
      'enum.rtu400seriessignalsource.onmainboard',
      'On Main Board'
    ),
    [Rtu400SignalSource.OptionCard]: t(
      'enum.rtu400seriessignalsource.optioncardwithcardtype',
      'Option Card'
    ),
    [Rtu400SignalSource.ExpansionCard]: t(
      'enum.rtu400seriessignalsource.expansioncard',
      'Expansion Card'
    ),
    [Rtu400SignalSource.DigitalLinked]: t(
      'enum.rtu400seriessignalsource.digitallinked',
      'Digital Linked'
    ),
    [Rtu400SignalSource.CounterLinked]: t(
      'enum.rtu400seriessignalsource.counterlinked',
      'Counter Linked'
    ),
    [Rtu400SignalSource.OptionExpansionCard]: t(
      'enum.rtu400seriessignalsource.optionexpansion',
      'Option/Expansion Card'
    ),
    [Rtu400SignalSource.GasMixingSystem]: t(
      'enum.rtu400seriessignalsource.gasmixingsystem',
      'Gas Mixing System'
    ),
    [Rtu400SignalSource.RemoteSensor]: t(
      'enum.rtu400seriessignalsource.remotesensor',
      'Remote Sensor'
    ),
  };
};

export const buildModuleTypeTextMapping = (t: TFunction) => {
  return {
    [RTU400CommunicationSlotModule.NoModem]: t(
      'enum.rtu400seriesmoduletype.nomodem',
      'No Modem'
    ),
    [RTU400CommunicationSlotModule.LocalWireless]: t(
      'enum.rtu400seriesmoduletype.localwireless',
      'Local Wireless'
    ),
    [RTU400CommunicationSlotModule.Telit910Series]: t(
      'enum.rtu400seriesmoduletype.telit910series',
      'Telit 910 Series'
    ),
    [RTU400CommunicationSlotModule.Iridium]: t(
      'enum.rtu400seriesmoduletype.iridium',
      'Iridium'
    ),
    [RTU400CommunicationSlotModule.IridiumGps]: t(
      'enum.rtu400seriesmoduletype.iridiumgps',
      'Iridium & GPS'
    ),
    [RTU400CommunicationSlotModule.Gps]: t(
      'enum.rtu400seriesmoduletype.gps',
      'GPS'
    ),
    [RTU400CommunicationSlotModule.GemaltoPLS62W]: t(
      'enum.rtu400seriesmoduletype.GemaltoPLS62W',
      'Gemalto PLS Series'
    ),
  };
};

export const buildPacketTypeGroupTextMapping = (t: TFunction) => {
  return {
    [RtuPacketCategory.Configuration]: t(
      'enum.packettypegroup.configuration',
      'Configuration'
    ),
    [RtuPacketCategory.Data]: t('enum.packettypegroup.data', 'Data'),
    [RtuPacketCategory.Initial]: t('enum.packettypegroup.initial', 'Initial'),
    [RtuPacketCategory.Unknown]: t('enum.packettypegroup.unknown', 'Unknown'),
  };
};

export const buildPacketTypeGroupUTMTextMapping = (t: TFunction) => {
  return {
    [RtuPacketCategory.Configuration]: t(
      'enum.packettypegroup.configuration',
      'Configuration'
    ),
    [RtuPacketCategory.Data]: t('enum.packettypegroup.readings', 'Readings'),
    [RtuPacketCategory.Initial]: t('enum.packettypegroup.initial', 'Initial'),
    [RtuPacketCategory.Unknown]: t('enum.packettypegroup.unknown', 'Unknown'),
  };
};

export enum RtuPacketsChannelType {
  ChannelOne = '1',
  ChannelTwo = '2',
  ChannelThree = '3',
  ChannelFour = '4',
  ChannelFive = '5',
  ChannelSix = '6',
  ChannelSeven = '7',
  ChannelEight = '8',
  GpsChannel = 'Gps',
}

export const buildRtuChannelTypeTextMapping = (t: TFunction) => {
  return {
    [RtuPacketsChannelType.ChannelOne]: t('enum.rtuchannelnumbertype.one', '1'),
    [RtuPacketsChannelType.ChannelTwo]: t('enum.rtuchannelnumbertype.two', '2'),
    [RtuPacketsChannelType.ChannelThree]: t(
      'enum.rtuchannelnumbertype.three',
      '3'
    ),
    [RtuPacketsChannelType.ChannelFour]: t(
      'enum.rtuchannelnumbertype.four',
      '4'
    ),
    [RtuPacketsChannelType.ChannelFive]: t(
      'enum.rtuchannelnumbertype.five',
      '5'
    ),
    [RtuPacketsChannelType.ChannelSix]: t('enum.rtuchannelnumbertype.six', '6'),
    [RtuPacketsChannelType.ChannelSeven]: t(
      'enum.rtuchannelnumbertype.seven',
      '7'
    ),
    [RtuPacketsChannelType.ChannelEight]: t(
      'enum.rtuchannelnumbertype.eight',
      '8'
    ),
    [RtuPacketsChannelType.GpsChannel]: t(
      'enum.rtuchannelnumbertype.gps',
      'GPS'
    ),
  };
};

export const buildRcmTransactionTypeEnumTextMapping = (t: TFunction) => {
  return {
    [RcmTransactionTypeEnum.ActivateDevice]: t(
      'enum.rcmtransactiontype.activatedevice',
      'Activate Device'
    ),
    [RcmTransactionTypeEnum.ClearAnalogChannelEvents]: t(
      'enum.rcmtransactiontype.clearanalogchannelevents',
      'Clear Analog Channel Events'
    ),
    [RcmTransactionTypeEnum.ClearAnalogChannelsEvents]: t(
      'enum.rcmtransactiontype.clearanalogchannelsevents',
      'Clear Analog Channels Events'
    ),
    [RcmTransactionTypeEnum.ClearDeviceEvents]: t(
      'enum.rcmtransactiontype.cleardeviceevents',
      'Clear Device Events'
    ),
    [RcmTransactionTypeEnum.ClearDigitalChannelEvents]: t(
      'enum.rcmtransactiontype.cleardigitalchannelevents',
      'Clear Digital Channel Events'
    ),
    [RcmTransactionTypeEnum.ClearDigitalChannelsEvents]: t(
      'enum.rcmtransactiontype.cleardigitalchannelsevents',
      'Clear Digital Channels Events'
    ),
    [RcmTransactionTypeEnum.ClearMaintenanceInProgress]: t(
      'enum.rcmtransactiontype.clearmaintenanceinprogress',
      'Clear Maintenance In Progress'
    ),
    [RcmTransactionTypeEnum.DeactivateDevice]: t(
      'enum.rcmtransactiontype.deactivatedevice',
      'Deactivate Device'
    ),
    [RcmTransactionTypeEnum.DisableUnusedChannels]: t(
      'enum.rcmtransactiontype.disableunusedchannels',
      'Disable Unused Channels'
    ),
    [RcmTransactionTypeEnum.DumpUnitsMemory]: t(
      'enum.rcmtransactiontype.dumpunitsmemory',
      'Dump Units Memory'
    ),
    [RcmTransactionTypeEnum.GetAnalogChannelConfig]: t(
      'enum.rcmtransactiontype.getanalogchannelconfig',
      'Get Analog Channel Config'
    ),
    [RcmTransactionTypeEnum.GetAnalogChannelDataLog]: t(
      'enum.rcmtransactiontype.getanalogchanneldatalog',
      'Get Analog Channel Data Log'
    ),
    [RcmTransactionTypeEnum.GetAnalogChannelReadingStatusEvents]: t(
      'enum.rcmtransactiontype.getanalogchannelreadingstatusevents',
      'Get Analog  Channel Reading Status Events'
    ),
    [RcmTransactionTypeEnum.GetAnalogChannelsDataLog]: t(
      'enum.rcmtransactiontype.getanalogchannelsdatalog',
      'Get Analog Channels Data Log'
    ),
    [RcmTransactionTypeEnum.GetAnalogChannelsDataLogHighSpeedReadings]: t(
      'enum.rcmtransactiontype.getanalogchannelsdataloghighspeedreadings',
      'Get Analog Channels Data Log HighSpeed Readings'
    ),
    [RcmTransactionTypeEnum.GetAnalogChannelsReadingStatusEvents]: t(
      'enum.rcmtransactiontype.getanalogchannelsreadingstatusevents',
      'Get Analog Channels Reading Status Events'
    ),
    [RcmTransactionTypeEnum.GetApplicationConfig]: t(
      'enum.rcmtransactiontype.getapplicationconfig',
      'Get Application Config'
    ),
    [RcmTransactionTypeEnum.GetChannelsConfig]: t(
      'enum.rcmtransactiontype.getchannelsconfig',
      'Get Channels Config'
    ),
    [RcmTransactionTypeEnum.GetDeviceConfig]: t(
      'enum.rcmtransactiontype.getdeviceconfig',
      'Get Device Config'
    ),
    [RcmTransactionTypeEnum.GetDeviceStatusAndEvents]: t(
      'enum.rcmtransactiontype.getdevicestatusandevents',
      'Get Device Status and Events'
    ),
    [RcmTransactionTypeEnum.GetDeviceTime]: t(
      'enum.rcmtransactiontype.getdevicetime',
      'Get Device Time'
    ),
    [RcmTransactionTypeEnum.GetDiagnosticChannelsReading]: t(
      'enum.rcmtransactiontype.getdiagnosticchannelsreading',
      'Get Diagnostic Channels Reading'
    ),
    [RcmTransactionTypeEnum.GetDigitalChannelConfig]: t(
      'enum.rcmtransactiontype.getdigitalchannelconfig',
      'Get Digital Channel Config'
    ),
    [RcmTransactionTypeEnum.GetDigitalChannelDataLog]: t(
      'enum.rcmtransactiontype.getdigitalchanneldatalog',
      'Get Digital Channel Data Log'
    ),
    [RcmTransactionTypeEnum.GetDigitalChannelReadingStatusEvents]: t(
      'enum.rcmtransactiontype.getdigitalchannelreadingstatusevents',
      'Get Digital Channel Reading Status Events'
    ),
    [RcmTransactionTypeEnum.GetDigitalChannelsDataLog]: t(
      'enum.rcmtransactiontype.getdigitalchannelsdatalog',
      'Get Digital Channels Data Log'
    ),
    [RcmTransactionTypeEnum.GetDigitalChannelsReadingStatusEvents]: t(
      'enum.rcmtransactiontype.getdigitalchannelsreadingstatusevents',
      'Get Digital Channels Reading Status Events'
    ),
    [RcmTransactionTypeEnum.GetDigitalOutputs]: t(
      'enum.rcmtransactiontype.getdigitaloutputs',
      'Get Digital Outputs'
    ),
    [RcmTransactionTypeEnum.GetEnabledChannels]: t(
      'enum.rcmtransactiontype.getenabledchannels',
      'Get Enabled Channels'
    ),
    [RcmTransactionTypeEnum.GetEventHistory]: t(
      'enum.rcmtransactiontype.geteventhistory',
      'Get Event History'
    ),
    [RcmTransactionTypeEnum.GetFillDetailRecords]: t(
      'enum.rcmtransactiontype.getfilldetailrecords',
      'Get Fill Detail Records'
    ),
    [RcmTransactionTypeEnum.GetFillTranAndDetailTableParameters]: t(
      'enum.rcmtransactiontype.getfilltrananddetailtableparameters',
      'Get Fill Transaction And Detail Table Parameters'
    ),
    [RcmTransactionTypeEnum.GetFillTransactionRecords]: t(
      'enum.rcmtransactiontype.getfilltransactionrecords',
      'Get Fill Transaction Records'
    ),
    [RcmTransactionTypeEnum.GetRtuId]: t(
      'enum.rcmtransactiontype.getrtuid',
      'Get RTU Id'
    ),
    [RcmTransactionTypeEnum.GetScalingMaps]: t(
      'enum.rcmtransactiontype.getscalingmaps',
      'Get Scaling Maps'
    ),
    [RcmTransactionTypeEnum.Login]: t('enum.rcmtransactiontype.login', 'Login'),
    [RcmTransactionTypeEnum.None]: t('enum.rcmtransactiontype.none', 'None'),
    [RcmTransactionTypeEnum.PulseDigitalOutPuts]: t(
      'enum.rcmtransactiontype.pulsedigitaloutputs',
      'Pulse Digital Outputs'
    ),
    [RcmTransactionTypeEnum.RebootDevice]: t(
      'enum.rcmtransactiontype.rebootdevice',
      'Reboot Device'
    ),
    [RcmTransactionTypeEnum.ResetDeviceToFactoryDefaults]: t(
      'enum.rcmtransactiontype.resetdevicetofactorydefaults',
      'Reset Device To Factory Defaults'
    ),
    [RcmTransactionTypeEnum.ResetDigitalCountersAndTimers]: t(
      'enum.rcmtransactiontype.resetdigitalcountersandtimers',
      'Reset Digital Counters and Timers'
    ),
    [RcmTransactionTypeEnum.SelectAnalogChannel]: t(
      'enum.rcmtransactiontype.selectanalogchannel',
      'Select Analog Channel'
    ),
    [RcmTransactionTypeEnum.SelectDigitalChannel]: t(
      'enum.rcmtransactiontype.selectdigitalchannel',
      'Select Digital Channel'
    ),
    [RcmTransactionTypeEnum.SendTestEvent]: t(
      'enum.rcmtransactiontype.sendtestevent',
      'Send Test Event'
    ),
    [RcmTransactionTypeEnum.SetAnalogChannelConfig]: t(
      'enum.rcmtransactiontype.setanalogchannelconfig',
      'Set Analog Channel Config'
    ),
    [RcmTransactionTypeEnum.SetAnalogChannelSetPoints]: t(
      'enum.rcmtransactiontype.setanalogchannelsetpoints',
      'Set Analog Channel Set Points'
    ),
    [RcmTransactionTypeEnum.SetApplicationConfig]: t(
      'enum.rcmtransactiontype.setapplicationconfig',
      'Set Application Config'
    ),
    [RcmTransactionTypeEnum.SetChannelsConfig]: t(
      'enum.rcmtransactiontype.setchannelsconfig',
      'Set Channels Config'
    ),
    [RcmTransactionTypeEnum.SetChannelsSetPoints]: t(
      'enum.rcmtransactiontype.setchannelssetpoints',
      'Set Channels Set Points'
    ),
    [RcmTransactionTypeEnum.SetDeviceCallinTimeAndPeriod]: t(
      'enum.rcmtransactiontype.setdevicecallintimeandperiod',
      'Set Device Call In Time and Period'
    ),
    [RcmTransactionTypeEnum.SetDeviceConfig]: t(
      'enum.rcmtransactiontype.setdeviceconfig',
      'Set Device Config'
    ),
    [RcmTransactionTypeEnum.SetDeviceGprsConfig]: t(
      'enum.rcmtransactiontype.setdevicegprsconfig',
      'Set Device GPRS Config'
    ),
    [RcmTransactionTypeEnum.SetDeviceTime]: t(
      'enum.rcmtransactiontype.setdevicetime',
      'Set Device Time'
    ),
    [RcmTransactionTypeEnum.SetDigitalChannelConfig]: t(
      'enum.rcmtransactiontype.setdigitalchannelconfig',
      'Set Digital Channel Config'
    ),
    [RcmTransactionTypeEnum.SetDigitalChannelSetPoints]: t(
      'enum.rcmtransactiontype.setdigitalchannelsetpoints',
      'Set Digital Channel Set Points'
    ),
    [RcmTransactionTypeEnum.SetDigitalOutputs]: t(
      'enum.rcmtransactiontype.setdigitaloutputs',
      'Set Digital Outputs'
    ),
    [RcmTransactionTypeEnum.SetHostAddress]: t(
      'enum.rcmtransactiontype.sethostaddress',
      'Set Host Address'
    ),
    [RcmTransactionTypeEnum.SetMaintenanceInProgress]: t(
      'enum.rcmtransactiontype.setmaintenanceinprogress',
      'Set Maintenance In Progress'
    ),
    [RcmTransactionTypeEnum.SetPassword]: t(
      'enum.rcmtransactiontype.setpassword',
      'Set Password'
    ),
    [RcmTransactionTypeEnum.SetRtuId]: t(
      'enum.rcmtransactiontype.setrtuid',
      'Set RTU Id'
    ),
    [RcmTransactionTypeEnum.SetScalingMaps]: t(
      'enum.rcmtransactiontype.setscalingmaps',
      'Set Scaling Maps'
    ),
    [RcmTransactionTypeEnum.WaitForStackedAlarms]: t(
      'enum.rcmtransactiontype.waitforstackedalarms',
      'Wait For Stacked Alarms'
    ),
  };
};

export const buildBatteryTypeTextMapping = (t: TFunction) => {
  return {
    [SmsRtuPowerSource.MainsPower]: t('enum.batterytype.mains', 'Mains'),
    [SmsRtuPowerSource.None]: t('enum.batterytype.none', 'None'),
    [SmsRtuPowerSource.NonRechargeable]: t(
      'enum.batterytype.nonrechargeable',
      'NonRechargeable'
    ),
    [SmsRtuPowerSource.SolarPower]: t('enum.batterytype.solar', 'Solar'),
  };
};

export const buildPollTypeTextMapping = (t: TFunction) => {
  return {
    [RTUPollTypeEnum.Disabled]: t('enum.rtupolltype.disabled', 'Disabled'),
    [RTUPollTypeEnum.Fast]: t('enum.rtupolltype.fast', 'Fast'),
    [RTUPollTypeEnum.Slow]: t('enum.rtupolltype.slow', 'Slow'),
    [RTUPollTypeEnum.SlowNetwork]: t(
      'enum.rtupolltype.slownetwork',
      'Slow Network'
    ),
  };
};

export const buildRtuNetworkTechnologyTypeTextMapping = (t: TFunction) => {
  return {
    [NetworkTechnologyType.Unknown]: t(
      'enum.rtunetworktechnologytype.unknown',
      '-'
    ),
    [NetworkTechnologyType.Satellite]: t(
      'enum.rtunetworktechnologytype.satellite',
      'Satellite'
    ),
    [NetworkTechnologyType.SecondG]: t(
      'enum.rtunetworktechnologytype.secondg',
      '2G'
    ),
    [NetworkTechnologyType.ThirdG]: t(
      'enum.rtunetworktechnologytype.thirdg',
      '3G'
    ),
    [NetworkTechnologyType.FourthG]: t(
      'enum.rtunetworktechnologytype.fourthg',
      '4G'
    ),
  };
};

export const buildRtuStatusTypeTextMapping = (t: TFunction) => {
  return {
    [RcmJournalItemStatusEnum.Complete]: t(
      'enum.rcmjournalstatustype.complete',
      'Complete'
    ),
    [RcmJournalItemStatusEnum.Failed]: t(
      'enum.rcmjournalstatustype.failed',
      'Failed'
    ),
    [RcmJournalItemStatusEnum.Initialized]: t(
      'enum.rcmjournalstatustype.initialized',
      'Initialized'
    ),
    [RcmJournalItemStatusEnum.Partial]: t(
      'enum.rcmjournalstatustype.partial',
      'Partial'
    ),
    [RcmJournalItemStatusEnum.Processing]: t(
      'enum.rcmjournalstatustype.processing',
      'Processing'
    ),
  };
};

export const buildPacketTypeTextMapping = (t: TFunction) => {
  return {
    [RtuPacketType.AdditionalConfigurationAndStatusTransmission]: t(
      'enum.packettype.additionalconfigurationtransmission',
      'Additional Configuration Transmission'
    ),
    [RtuPacketType.AdHocDataPacket]: t(
      'enum.packettype.adhocdatatransmission',
      'Adhoc Data Transmission'
    ),
    [RtuPacketType.CommunicationsUpdate]: t(
      'enum.packettype.communicationsupdate',
      'Communications Update'
    ),
    [RtuPacketType.CounterPacket]: t(
      'enum.packettype.counterpackettransmission',
      'Counter Packet Transmission'
    ),
    [RtuPacketType.CurrentConfigurationTransmission]: t(
      'enum.packettype.currentconfigurationtransmission',
      'Current Configuration Transmission'
    ),
    [RtuPacketType.FourHundredSeriesAdHocLoggedDataReport]: t(
      'enum.packettype.400seriesadhocloggeddatareport',
      '400 Series Ad-hoc Logged Data Report'
    ),
    [RtuPacketType.FourHundredSeriesAnalogInputAdditionalConfig]: t(
      'enum.packettype.400seriesanaloginputadditionalconfig',
      '400 Series Analog Input Additional Config'
    ),
    [RtuPacketType.FourHundredSeriesAnalogInputAdditionalConfigResponse]: t(
      'enum.packettype.400seriesanaloginputadditionalconfigresponse',
      '400 Series Analog Input Additional Config Response'
    ),
    [RtuPacketType.FourHundredSeriesAnalogInputConfig]: t(
      'enum.packettype.400seriesanaloginputconfig',
      '400 Series Analog Input Config'
    ),
    [RtuPacketType.FourHundredSeriesAnalogInputConfigResponse]: t(
      'enum.packettype.400seriesanaloginputconfigresponse',
      '400 Series Analog Input Config Response'
    ),
    [RtuPacketType.FourHundredSeriesAnalogInputStrappingChart]: t(
      'enum.packettype.400seriesanaloginputstrappingchart',
      '400 Series Analog Input Strapping Chart'
    ),
    [RtuPacketType.FourHundredSeriesAnalogInputStrappingChartResponse]: t(
      'enum.packettype.400seriesanaloginputstrappingchartresponse',
      '400 Series Analog Input Strapping Chart Response'
    ),
    [RtuPacketType.FourHundredSeriesAnalogLoggedDataReport]: t(
      'enum.packettype.400seriesanalogloggeddatareport',
      '400 Series Analog Logged Data Report'
    ),
    [RtuPacketType.FourHundredSeriesAnalogOutputConfig]: t(
      'enum.packettype.400seriesanalogoutputconfig',
      '400 Series Analog Output Config'
    ),
    [RtuPacketType.FourHundredSeriesAnalogOutputConfigResponse]: t(
      'enum.packettype.400seriesanalogoutputconfigresponse',
      '400 Series Analog Output Config Response'
    ),
    [RtuPacketType.FourHundredSeriesCounterInputConfig]: t(
      'enum.packettype.400seriescounterinputconfig',
      '400 Series Counter Input Config'
    ),
    [RtuPacketType.FourHundredSeriesCounterInputConfigResponse]: t(
      'enum.packettype.400seriescounterinputconfigresponse',
      '400 Series Counter Input Config Response'
    ),
    [RtuPacketType.FourHundredSeriesCounterLoggedDataReport]: t(
      'enum.packettype.400seriescounterloggeddatareport',
      '400 Series Counter Logged Data Report'
    ),
    [RtuPacketType.FourHundredSeriesDataNetworkAccessCommunicationsConfig]: t(
      'enum.packettype.400seriesdatanetworkaccesscommunicationsconfig',
      '400 Series Data Network Access Communications Config'
    ),
    [RtuPacketType.FourHundredSeriesDataNetworkAccessCommunicationsConfigResponse]: t(
      'enum.packettype.400seriesdatanetworkaccesscommunicationsconfigresponse',
      '400 Series Data Network Access Communications Config Response'
    ),
    [RtuPacketType.FourHundredSeriesDigitalInputConfig]: t(
      'enum.packettype.400seriesdigitalinputconfig',
      '400 Series Digital Input Config'
    ),
    [RtuPacketType.FourHundredSeriesDigitalInputConfigResponse]: t(
      'enum.packettype.400seriesdigitalinputconfigresponse',
      '400 Series Digital Input Config Response'
    ),
    [RtuPacketType.FourHundredSeriesDigitalLoggedDataReport]: t(
      'enum.packettype.400seriesdigitalloggeddatareport',
      '400 Series Digital Logged Data Report'
    ),
    [RtuPacketType.FourHundredSeriesDigitalOutputConfig]: t(
      'enum.packettype.400seriesdigitaloutputconfig',
      '400 Series Digital Output Config'
    ),
    [RtuPacketType.FourHundredSeriesDigitalOutputConfigResponse]: t(
      'enum.packettype.400seriesdigitaloutputconfigresponse',
      '400 Series Digital Output Config Response'
    ),
    [RtuPacketType.FourHundredSeriesDolv3AccessCommunicationsConfig]: t(
      'enum.packettype.400seriesdolv3accesscommunicationsconfig',
      '400 Series DOLv3 Access Communications Config'
    ),
    [RtuPacketType.FourHundredSeriesDolv3AccessCommunicationsConfigResponse]: t(
      'enum.packettype.400seriesdolv3accesscommunicationsconfigresponse',
      '400 Series DOLv3 Access Communications Config Response'
    ),
    [RtuPacketType.FourHundredSeriesGprsTerminationRequest]: t(
      'enum.packettype.400seriesgprsterminationrequest',
      '400 Series GPRS Termination Request'
    ),
    [RtuPacketType.FourHundredSeriesGpsConfig]: t(
      'enum.packettype.400seriesgpsconfig',
      '400 Series Gps Config'
    ),
    [RtuPacketType.FourHundredSeriesGpsConfigResponse]: t(
      'enum.packettype.400seriesgpsconfigresponse',
      '400 Series Gps Config Response'
    ),
    [RtuPacketType.FourHundredSeriesGpsLocationLoggedDataReport]: t(
      'enum.packettype.400seriesgpslocationloggeddatareport',
      '400 Series Gps Location Logged Data Report'
    ),
    [RtuPacketType.FourHundredSeriesInitialConfig]: t(
      'enum.packettype.400seriesinitialconfig',
      '400 Series Initial Config'
    ),
    [RtuPacketType.FourHundredSeriesIridiumSatelliteCommunicationsConfig]: t(
      'enum.packettype.400seriesiridiumsatellitecommunicationsconfig',
      '400 Series Iridium Satellite Communications Config'
    ),
    [RtuPacketType.FourHundredSeriesIridiumSatelliteCommunicationsConfigResponse]: t(
      'enum.packettype.400seriesiridiumsatellitecommunicationsconfigresponse',
      '400 Series Iridium Satellite Communications Config Response'
    ),
    [RtuPacketType.FourHundredSeriesPollRequest]: t(
      'enum.packettype.400seriespollrequest',
      '400 Series Poll Request'
    ),
    [RtuPacketType.FourHundredSeriesRealtimeOutputDataReport]: t(
      'enum.packettype.400seriesrealtimeoutputdatareport',
      '400 Series Realtime Output Data Report'
    ),
    [RtuPacketType.FourHundredSeriesRealtimeRtuStatusInputDataReport]: t(
      'enum.packettype.400seriesrealtimertustatusinputdatareport',
      '400 Series Realtime RTU Status Input Data Report'
    ),
    [RtuPacketType.FourHundredSeriesSmsCommunicationsConfig]: t(
      'enum.packettype.400seriessmscommunicationconfig',
      '400 Series Sms Communication Config'
    ),
    [RtuPacketType.FourHundredSeriesSmsCommunicationsConfigResponse]: t(
      'enum.packettype.400seriessmscommunicationconfigresponse',
      '400 Series Sms Communication Config Response'
    ),
    [RtuPacketType.FourHundredSeriesSystemConfigResponse]: t(
      'enum.packettype.400seriessystemconfigresponse',
      '400 Series System Config Response'
    ),
    [RtuPacketType.FourHundredSeriesSystemConfigUpdate]: t(
      'enum.packettype.400seriessystemconfigupdate',
      '400 Series System Config Update'
    ),
    [RtuPacketType.FourHundredSeriesTimeUpdateRequest]: t(
      'enum.packettype.400seriestimeupdaterequest',
      '400 Series Time Update Request'
    ),
    [RtuPacketType.FourHundredSeriesTimeUpdateResponse]: t(
      'enum.packettype.400seriestimeupdateresponse',
      '400 Series Time Update Response'
    ),
    [RtuPacketType.GpsLocationTransmission]: t(
      'enum.packettype.gpslocationtransmission',
      'GPS Location Transmission'
    ),
    [RtuPacketType.Kt09InitializeSimRequest]: t(
      'enum.packettype.kt09initializesimrequest',
      'KT09 Initialize Request'
    ),
    [RtuPacketType.Kt09PollRequest]: t(
      'enum.packettype.kt09pollrequest',
      'KT09 Poll Request'
    ),
    [RtuPacketType.None]: t('ui.common.none', 'None'),
    [RtuPacketType.NetworkTimeSync]: t(
      'enum.packettype.networktimesync',
      'Network Time Sync'
    ),
    [RtuPacketType.PeriodicDataTransmission]: t(
      'enum.packettype.periodicdatatransmission',
      'Periodic Data Transmission'
    ),
    [RtuPacketType.RcmConfig]: t(
      'enum.packettype.rcmconfiguration',
      'Rcm RTU Configuration'
    ),
    [RtuPacketType.RcmReadings]: t(
      'enum.packettype.rcmreadings',
      'Rcm Readings'
    ),
    [RtuPacketType.ReadingsService]: t(
      'enum.packettype.readingsservice',
      'Readings Service Data Transmission'
    ),
    [RtuPacketType.TestConfigurationDataTransmission]: t(
      'enum.packettype.testconfigurationtransmission',
      'Test Configuration Transmission'
    ),
    [RtuPacketType.UpdateAdditionalConfiguration]: t(
      'enum.packettype.updateadditionalconfiguration',
      'Update Additional Configuration'
    ),
    [RtuPacketType.UpdateSystemConfiguration]: t(
      'enum.packettype.updatesystemconfiguration',
      'Update System Configuration'
    ),
    [RtuPacketType.UserRequestedDataTransmission]: t(
      'enum.packettype.userrequesteddatatransmission',
      'User Requested Data Transmission'
    ),
    [RtuPacketType.VistarAlarmDataTransmission]: t(
      'enum.packettype.vistarconfigurationrequest',
      'Vistar Configuration Request'
    ),
    [RtuPacketType.VistarConfigurationRecordType1]: t(
      'enum.packettype.vistarconfigurationupdate',
      'Vistar Configuration Update'
    ),
    [RtuPacketType.VistarConfigurationRecordType2]: t(
      'enum.packettype.vistarcurrentconfig1transmission',
      'Vistar Current Config 1 Transmission'
    ),
    [RtuPacketType.VistarConfigurationRequest]: t(
      'enum.packettype.vistarcurrentconfig2transmission',
      'Vistar Current Config 2 Transmission'
    ),
    [RtuPacketType.VistarConfigurationUpdate]: t(
      'enum.packettype.vistargpslocationtransmission',
      'Vistar GPS Location Transmission'
    ),
    [RtuPacketType.VistarDataRequest]: t(
      'enum.packettype.vistarleveleventdatatransmission',
      'Vistar Level Event Data Transmission'
    ),
    [RtuPacketType.VistarGpsLocationTransmission]: t(
      'enum.packettype.vistarperiodicdatatransmission',
      'Vistar Periodic Data Transmission'
    ),
    [RtuPacketType.VistarPeriodicDataTransmission]: t(
      'enum.packettype.vistarpollrequest',
      'Vistar Poll Request'
    ),
    [RtuPacketType.VistarTerminalAcknowledgment]: t(
      'enum.packettype.vistarterminalacknowledgement',
      'Vistar Terminal Acknowledgement'
    ),
    [RtuPacketType.VistarTerminalRegistration]: t(
      'enum.packettype.vistarterminalregistration',
      'Vistar Terminal Registration'
    ),
    // Manually translated enums
    [RtuPacketType.AlarmFillDataTransmission]: t(
      'enum.packettype.alarmFillDataTransmission',
      'Alarm Fill Data Transmission'
    ),
    [RtuPacketType.AlarmLowLevelTransmission]: t(
      'enum.packettype.alarmLowLevelTransmission',
      'Alarm Low Level Transmission'
    ),
    [RtuPacketType.FourHundredSeriesRtuCommand]: t(
      'enum.packettype.400SeriesRtuCommand',
      '400 Series Rtu Command'
    ),
    [RtuPacketType.FourHundredSeriesRtuCommandAcknowledge]: t(
      'enum.packettype.400seriessmscommandacknowledge',
      '400 Series Rtu Command Acknowledge'
    ),
    [RtuPacketType.RequestAdditionalConfigurationAndStatus]: t(
      'enum.packettype.requestAdditionalConfigurationAndStatus',
      'Request Additional Configuration and Status'
    ),
    [RtuPacketType.RequestCurrentConfiguration]: t(
      'enum.packettype.requestCurrentConfiguration',
      'Request Current Configuration'
    ),
    [RtuPacketType.RequestDataTransmission]: t(
      'enum.packettype.requestDataTransmission',
      'Request Data Configuration'
    ),
    [RtuPacketType.UnifyReading]: t(
      'enum.packettype.unifyReading',
      'Unify Reading'
    ),
    [RtuPacketType.UnifyConfiguration]: t(
      'enum.packettype.unifyConfiguration',
      'Unify Configuration'
    ),
    [RtuPacketType.UnifyEvent]: t('enum.packettype.unifyEvent', 'Unify Event'),
    [RtuPacketType.UnifyDevices]: t(
      'enum.packettype.unifyDevices',
      'Unify Devices'
    ),
  };
};

export const buildCommunicationMethodGroupTextMapping = (
  t: TFunction
): Record<TelecommunicationsCarrier | number, string> => {
  return {
    [TelecommunicationsCarrier.Ais]: t('enum.carrier.ais', 'AIS'),
    [TelecommunicationsCarrier.All]: t('enum.carrier.all', 'All'),
    [TelecommunicationsCarrier.Aql]: t('enum.carrier.aql', 'Aql'),
    [TelecommunicationsCarrier.Att]: t('enum.carrier.att', 'ATT'),
    [TelecommunicationsCarrier.Att4GApci]: t(
      'enum.carrier.att4gapci',
      'AT&T-APCI-4G'
    ),
    [TelecommunicationsCarrier.AttApci]: t('enum.carrier.attapci', 'AT&T-APCI'),
    [TelecommunicationsCarrier.ChinaMobile]: t(
      'enum.carrier.chinamobile',
      'China Mobile'
    ),
    [TelecommunicationsCarrier.ChinaMobileDirect]: t(
      'enum.carrier.chinamobiledirect',
      'China Mobile Direct'
    ),
    [TelecommunicationsCarrier.Dtac]: t('enum.carrier.dtac', 'DTAC'),
    [TelecommunicationsCarrier.Email]: t('enum.carrier.email', 'Email'),
    [TelecommunicationsCarrier.EmailHosted]: t(
      'enum.carrier.email(hosted)',
      'EmailHosted'
    ),
    [TelecommunicationsCarrier.Email2]: t('enum.carrier.email2', 'Email2'),
    [TelecommunicationsCarrier.FileTransfer]: t(
      'enum.carrier.filetransfer',
      'File Transfer'
    ),
    [TelecommunicationsCarrier.ICE]: t('enum.carrier.ice', 'ICE'),
    [TelecommunicationsCarrier.Iridium]: t('enum.carrier.iridium', 'Iridium'),
    [TelecommunicationsCarrier.KoreAtt]: t('enum.carrier.koreatt', 'KoreAtt'),
    [TelecommunicationsCarrier.KoreAtt4G]: t(
      'enum.carrier.koreatt4g',
      'KoreAtt 4G'
    ),
    [TelecommunicationsCarrier.KT09]: t('enum.carrier.kt09', 'KT09'),
    [TelecommunicationsCarrier.Modem]: t('enum.carrier.modem', 'Modem'),
    [TelecommunicationsCarrier.Numerex]: t('enum.carrier.numerex', 'Numerex'),
    [TelecommunicationsCarrier.NumerexAtt]: t(
      'enum.carrier.numerexatt',
      'NumerexAtt'
    ),
    [TelecommunicationsCarrier.NxLocate]: t(
      'enum.carrier.nxlocate',
      'NxLocate'
    ),
    [TelecommunicationsCarrier.O2]: t('enum.carrier.o2', 'O2'),
    [TelecommunicationsCarrier.Particle]: t(
      'enum.carrier.particle',
      'Particle'
    ),
    [TelecommunicationsCarrier.Raco]: t('enum.carrier.raco', 'Raco'),
    [TelecommunicationsCarrier.ReadingsService]: t(
      'enum.carrier.readingsservice',
      'Readings Service'
    ),
    [TelecommunicationsCarrier.Rogers]: t('enum.carrier.rogers', 'Rogers'),
    [TelecommunicationsCarrier.Skytel]: t('enum.carrier.skytel', 'Skytel'),
    [TelecommunicationsCarrier.SkyWaveIGWS]: t(
      'enum.carrier.skywaveigws',
      'Sky Wave IGWS'
    ),
    [TelecommunicationsCarrier.Tdc]: t('enum.carrier.tdc', 'TDC'),
    [TelecommunicationsCarrier.Telcel]: t('enum.carrier.telcel', 'Telcel'),
    [TelecommunicationsCarrier.Telenor]: t('enum.carrier.telenor', 'Telenor'),
    [TelecommunicationsCarrier.TelenorSweden]: t(
      'enum.carrier.telenorsweden',
      'Telenor Sweden'
    ),
    [TelecommunicationsCarrier.Telit]: t('enum.carrier.telit', 'Telit'),
    [TelecommunicationsCarrier.Tigo]: t('enum.carrier.tigo', 'Tigo'),
    [TelecommunicationsCarrier.TMobile]: t('enum.carrier.tmobile', 'TMobile'),
    [TelecommunicationsCarrier.Transfer]: t(
      'enum.carrier.transfer',
      'Transfer'
    ),
    [TelecommunicationsCarrier.Udp]: t('enum.carrier.udp', 'Udp'),
    [TelecommunicationsCarrier.Unknown]: t('enum.carrier.unknown', 'Unknown'),
    [TelecommunicationsCarrier.Verizon]: t('enum.carrier.verizon', 'Verizon'),
    [TelecommunicationsCarrier.Vistar]: t('enum.carrier.vistar', 'Vistar'),
    [TelecommunicationsCarrier.Vivo]: t('enum.carrier.vivo', 'Vivo'),
    [TelecommunicationsCarrier.VivoApci]: t(
      'enum.carrier.vivoapci',
      'VivoApci'
    ),
    [TelecommunicationsCarrier.VivoDol]: t('enum.carrier.vivodol', 'VivoDol'),
    [TelecommunicationsCarrier.Vodafone]: t(
      'enum.carrier.vodafone',
      'Vodafone'
    ),
    [TelecommunicationsCarrier.Weblink]: t('enum.carrier.weblink', 'Weblink'),
    [TelecommunicationsCarrier.WorldTextAustralia]: t(
      'enum.carrier.worldtextaustralia',
      'WorldTextAustralia'
    ),
    // Should this translation actually use World Text India?
    [TelecommunicationsCarrier.WorldTextIndia]: t(
      'enum.carrier.worldtextindia',
      'WorldTextIndia'
    ),
    [TelecommunicationsCarrier.WorldTextIndiaIdea]: t(
      'enum.carrier.worldtextindiaidea',
      'WorldTextIndiaIdea'
    ),
    [TelecommunicationsCarrier.WorldTextIndiaTata]: t(
      'enum.carrier.worldtextindiatata',
      'WorldTextIndiaTata'
    ),
    [TelecommunicationsCarrier.WorldTextIndonesia]: t(
      'enum.carrier.worldtextindonesia',
      'WorldTextIndonesia'
    ),
    [TelecommunicationsCarrier.WorldTextUk]: t(
      'enum.carrier.worldtextuk',
      'WorldTextUk'
    ),
    [TelecommunicationsCarrier.WorldTextUsa]: t(
      'enum.carrier.worldtextusa',
      'WorldTextUsa'
    ),
    // Manual Translations (Might need Verification)
    [TelecommunicationsCarrier.AWG]: t('enum.carrier.awg', 'AWG'),
    [TelecommunicationsCarrier.ChinaMobileCmpp090528]: t(
      'enum.carrier.chinaMobileCmpp090528',
      'China Mobile - 090528'
    ),
    [TelecommunicationsCarrier.ChinaMobileCmpp091430]: t(
      'enum.carrier.chinaMobileCmpp091430',
      'China Mobile - 091430'
    ),
    [TelecommunicationsCarrier.Dtac2]: t('enum.carrier.dtac2', 'DTAC2'),
    [TelecommunicationsCarrier.Fieldgate]: t(
      'enum.carrier.fieldGate',
      'Fieldgate'
    ),
    [TelecommunicationsCarrier.GDC]: t('enum.carrier.gdc', 'GDC'),
    [TelecommunicationsCarrier.KoreAtt4G2]: t(
      'enum.carrier.koreatt4g2',
      'KoreAtt-4G-2'
    ),
    [TelecommunicationsCarrier.KoreTelstra]: t(
      'enum.carrier.koreTelestra',
      'Kore Telestra'
    ),
    [TelecommunicationsCarrier.KoreTMobile]: t(
      'enum.carrier.koreTMobile',
      'Kore TMobile'
    ),
    [TelecommunicationsCarrier.Raco2]: t('enum.carrier.raco2', 'Raco2'),
    [TelecommunicationsCarrier.RCM]: t('enum.carrier.rcm', 'RCM'),
    [TelecommunicationsCarrier.Skybitz]: t('enum.carrier.skybitz', 'Skybitz'),
    [TelecommunicationsCarrier.Telcel88449]: t(
      'enum.carrier.telcel88449',
      'Telcel 88449'
    ),
    [TelecommunicationsCarrier.TelitAnova]: t(
      'enum.carrier.telitAnova',
      'Telit ANOVA'
    ),
    [TelecommunicationsCarrier.TestSMPP]: t(
      'enum.carrier.testSmpp',
      'Test SMPP'
    ),
    // NOTE: Removed
    [TelecommunicationsCarrier.UdpKore]: t('enum.carrier.udpKore', 'Udp Kore'),
    [TelecommunicationsCarrier.UdpTurkey]: t(
      'enum.carrier.udpTurkey',
      'Udp - Turkey'
    ),
    [TelecommunicationsCarrier.WorldTextBrazil]: t(
      'enum.carrier.worldTextBrazil',
      'WorldTextBrazil'
    ),
    [TelecommunicationsCarrier.WorldTextGermany]: t(
      'enum.carrier.worldTextGermany',
      'WorldTextGermany'
    ),
    [TelecommunicationsCarrier.WorldTextHongKong]: t(
      'enum.carrier.worldTextHongKong',
      'WorldTextHongKong'
    ),
    [TelecommunicationsCarrier.WorldTextIndonesiaBackup]: t(
      'enum.carrier.worldTextIndonesiaBackup',
      'WorldTextIndonesiaBackup'
    ),
    [TelecommunicationsCarrier.WorldTextNetherlands]: t(
      'enum.carrier.worldTextNetherlands',
      'WorldTextNetherlands'
    ),
    [TelecommunicationsCarrier.WorldTextTurkey]: t(
      'enum.carrier.worldTextTurkey',
      'WorldTextTurkey'
    ),
    [TelecommunicationsCarrier.WorldTextUAE]: t(
      'enum.carrier.worldTextUae',
      'WorldTextUAE'
    ),
    [TelecommunicationsCarrier.Unify]: t('enum.carrier.unify', 'Unify'),
    [TelecommunicationsCarrier.KoreRogers]: t(
      'enum.carrier.korerogers',
      'KoreRogers'
    ),
    [TelecommunicationsCarrier.KoreAttK1]: t(
      'enum.carrier.koreattk1',
      'KoreAttK1'
    ),
  };
};

export const buildPacketStatusTextMapping = (t: TFunction) => {
  return {
    [RtuPacketStatus.CannotBeProcessed]: t(
      'enum.packetstatus.cannotbeprocessed',
      'Cannot Be Processed'
    ),
    [RtuPacketStatus.Completed]: t('enum.packetstatus.completed', 'Completed'),
    [RtuPacketStatus.DecodeVistarGPSFailed]: t(
      'enum.packetstatus.decodevistargpsfailed',
      'Unable to Decode Vistar GPS data'
    ),
    [RtuPacketStatus.FtpFileNotCreatedNoTimeZoneInfo]: t(
      'enum.packetstatus.ftpfilenotcreatednotimezoneinfo',
      'FTP File Not Created No Timezone Info'
    ),
    [RtuPacketStatus.FtpInvalidDataChannelUnits]: t(
      'enum.packetstatus.ftpinvaliddatachannelunits',
      'The Units for setting the FTP Reading are Invalid'
    ),
    [RtuPacketStatus.FtpOptionSetToNone]: t(
      'enum.packetstatus.ftpoptionsettonone',
      'FTP Option Set To None'
    ),
    [RtuPacketStatus.FtpVolumetricConvertFailed]: t(
      'enum.packetstatus.ftpvolumetricconvertfailed',
      'Unable to Convert Reading for FTP'
    ),
    [RtuPacketStatus.InProcessingQueue]: t(
      'enum.packetstatus.inprocessingqueue',
      'In Processing Queue'
    ),
    [RtuPacketStatus.InvalidAlarmPointsADC]: t(
      'enum.packetstatus.invalidalarmpointsadc',
      'Invalid Alarm Points ADC'
    ),
    [RtuPacketStatus.InvalidAmbientLightLevel]: t(
      'enum.packetstatus.invalidambientlightlevel',
      'Invalid Ambient Light Level'
    ),
    [RtuPacketStatus.InvalidAverageCharge]: t(
      'enum.packetstatus.invalidaveragecharge',
      'Invalid Average Charge'
    ),
    [RtuPacketStatus.InvalidBatteryChargeCurrent]: t(
      'enum.packetstatus.invalidbatterychargecurrent',
      'Invalid Battery Charge Current'
    ),
    [RtuPacketStatus.InvalidBatteryLowSetpoint]: t(
      'enum.packetstatus.invalidbatterylowsetpoint',
      'Invalid Battery Low Setpoint'
    ),
    [RtuPacketStatus.InvalidBatteryNightVolts]: t(
      'enum.packetstatus.invalidbatterynightvolts',
      'Invalid Battery Night Volts'
    ),
    [RtuPacketStatus.InvalidBatteryType]: t(
      'enum.packetstatus.invalidbatterytype',
      'Invalid Battery Type'
    ),
    [RtuPacketStatus.InvalidBatteryVoltReading]: t(
      'enum.packetstatus.invalidbatteryvoltreading',
      'Invalid Battery Volt Reading'
    ),
    [RtuPacketStatus.InvalidCommunicationMethod]: t(
      'enum.packetstatus.invalidcommunicationmethod',
      'Invalid Communication Method'
    ),
    [RtuPacketStatus.InvalidControlFlag]: t(
      'enum.packetstatus.invalidcontrolflag',
      'Invalid Control Flag'
    ),
    [RtuPacketStatus.InvalidCounterData]: t(
      'enum.packetstatus.invalidcounterdata',
      'Invalid Counter Data'
    ),
    [RtuPacketStatus.InvalidDataChannelType]: t(
      'enum.packetstatus.invaliddatachanneltype',
      'Invalid Data Channel Type'
    ),
    [RtuPacketStatus.InvalidDataReading]: t(
      'enum.packetstatus.invaliddatareading',
      'Invalid Data Reading'
    ),
    [RtuPacketStatus.InvalidDestEmail]: t(
      'enum.packetstatus.invaliddestemail',
      'Invalid Dest Email'
    ),
    [RtuPacketStatus.InvalidDestinationPIN]: t(
      'enum.packetstatus.invaliddestinationpin',
      'Invalid Destination PIN'
    ),
    [RtuPacketStatus.InvalidDestNo]: t(
      'enum.packetstatus.invaliddestno',
      'Invalid Destination No'
    ),
    [RtuPacketStatus.InvalidDeviceID]: t(
      'enum.packetstatus.invaliddeviceid',
      'Invalid Device Id'
    ),
    [RtuPacketStatus.InvalidDomain]: t(
      'enum.packetstatus.invaliddomain',
      'Invalid Domain'
    ),
    [RtuPacketStatus.InvalidFillAlarm]: t(
      'enum.packetstatus.invalidfillalarm',
      'Invalid Fill Alarm'
    ),
    [RtuPacketStatus.InvalidFillTime]: t(
      'enum.packetstatus.invalidfilltime',
      'Invalid Fill Time'
    ),
    [RtuPacketStatus.InvalidFillUpdate]: t(
      'enum.packetstatus.invalidfillupdate',
      'Invalid Fill Update'
    ),
    [RtuPacketStatus.InvalidFtpFileFormat]: t(
      'enum.packetstatus.invalidftpfileformat',
      'Invalid FTP File Format'
    ),
    [RtuPacketStatus.InvalidFtpId]: t(
      'enum.packetstatus.invalidftpid',
      'The FTP Id is empty or invalid'
    ),
    [RtuPacketStatus.InvalidFtpTimeZone]: t(
      'enum.packetstatus.invalidftptimezone',
      'Unable to create FTP Record. Invalid Timezone.'
    ),
    [RtuPacketStatus.InvalidLogLength]: t(
      'enum.packetstatus.invalidloglength',
      'Invalid Log Length'
    ),
    [RtuPacketStatus.InvalidLoopVolts]: t(
      'enum.packetstatus.invalidloopvolts',
      'Invalid Loop Volts'
    ),
    [RtuPacketStatus.InvalidNumberDecimalPlaces]: t(
      'enum.packetstatus.invalidnumberdecimalplaces',
      'Invalid Number Decimal Places'
    ),
    [RtuPacketStatus.InvalidOwnNo]: t(
      'enum.packetstatus.invalidownno',
      'Invalid Own No'
    ),
    [RtuPacketStatus.InvalidOwnPIN]: t(
      'enum.packetstatus.invalidownpin',
      'Invalid Own PIN'
    ),
    [RtuPacketStatus.InvalidPacketPrefix]: t(
      'enum.packetstatus.invalidpacketprefix',
      'Invalid Packet Prefix'
    ),
    [RtuPacketStatus.InvalidPacketType]: t(
      'enum.packetstatus.invalidpackettype',
      'Invalid Packet Type'
    ),
    [RtuPacketStatus.InvalidPollHour]: t(
      'enum.packetstatus.invalidpollhour',
      'Invalid Poll Hour'
    ),
    [RtuPacketStatus.InvalidPollMode]: t(
      'enum.packetstatus.invalidpollmode',
      'Invalid Poll Mode'
    ),
    [RtuPacketStatus.InvalidRecordingRate]: t(
      'enum.packetstatus.invalidrecordingrate',
      'Invalid Recording Rate'
    ),
    [RtuPacketStatus.InvalidRtuChannelConfigBlock]: t(
      'enum.packetstatus.invalidrtuchannelconfigblock',
      'Invalid RTU Channel Config Block'
    ),
    [RtuPacketStatus.InvalidRtuChannelDiagnosticBlock]: t(
      'enum.packetstatus.invalidrtuchanneldiagnosticblock',
      'Invalid RTU Channel Diagnostic Block'
    ),
    [RtuPacketStatus.InvalidRtuConfigBlock]: t(
      'enum.packetstatus.invalidrtuconfigblock',
      'Invalid RTU Config Block'
    ),
    [RtuPacketStatus.InvalidRtuTimestamp]: t(
      'enum.packetstatus.invalidrtutimestamp',
      'Invalid RTU Timestamp'
    ),
    [RtuPacketStatus.InvalidRtuTypeInPacket]: t(
      'enum.packetstatus.invalidrtutypeinpacket',
      'Invalid RTU Type In Packet'
    ),
    [RtuPacketStatus.InvalidSensorDelay]: t(
      'enum.packetstatus.invalidsensordelay',
      'Invalid Sensor Delay'
    ),
    [RtuPacketStatus.InvalidSensorSpan]: t(
      'enum.packetstatus.invalidsensorspan',
      'Invalid Sensor Span'
    ),
    [RtuPacketStatus.InvalidSequenceNumber]: t(
      'enum.packetstatus.invalidsequencenumber',
      'Invalid Sequence Number'
    ),
    [RtuPacketStatus.InvalidServCtrNo]: t(
      'enum.packetstatus.invalidservctrno',
      'Invalid Service Centre No'
    ),
    [RtuPacketStatus.InvalidServerTimestamp]: t(
      'enum.packetstatus.invalidservertimestamp',
      'Invalid Server Timestamp'
    ),
    [RtuPacketStatus.InvalidSignalStrength]: t(
      'enum.packetstatus.invalidsignalstrength',
      'Invalid Signal Strength'
    ),
    [RtuPacketStatus.InvalidSite]: t(
      'enum.packetstatus.invalidsite',
      'Invalid Site'
    ),
    [RtuPacketStatus.InvalidSWVersion]: t(
      'enum.packetstatus.invalidswversion',
      'Invalid SW Version'
    ),
    [RtuPacketStatus.InvalidTemperatureReading]: t(
      'enum.packetstatus.invalidtemperaturereading',
      'Invalid Temperature Reading'
    ),
    [RtuPacketStatus.InvalidTimeUpdateFlag]: t(
      'enum.packetstatus.invalidtimeupdateflag',
      'Invalid Time Update Flag'
    ),
    [RtuPacketStatus.InvalidTxHour]: t(
      'enum.packetstatus.invalidtxhour',
      'Invalid Tx Hour'
    ),
    [RtuPacketStatus.InvalidTxRate]: t(
      'enum.packetstatus.invalidtxrate',
      'Invalid Tx Rate'
    ),
    [RtuPacketStatus.InvalidZeroOffset]: t(
      'enum.packetstatus.invalidzerooffset',
      'Invalid Zero Offset'
    ),
    [RtuPacketStatus.ManualSettingNotProcessed]: t(
      'enum.packetstatus.manualsettingnotprocessed',
      'Manual Setting Not Processed'
    ),
    [RtuPacketStatus.NoCounterDataChannelFound]: t(
      'enum.packetstatus.nocounterdatachannelfound',
      'No Counter Type DataChannel Found'
    ),
    [RtuPacketStatus.NoDataChannelFound]: t(
      'enum.packetstatus.nodatachannelfound',
      'No Data Channel Found'
    ),
    [RtuPacketStatus.NoGPSDataChannelFound]: t(
      'enum.packetstatus.nogpsdatachannelfound',
      'No GPS Data Channel Found'
    ),
    [RtuPacketStatus.NoRtuChannelFound]: t(
      'enum.packetstatus.nortuchannelfound',
      'No RTU Channel Found'
    ),
    [RtuPacketStatus.NoRtuFound]: t(
      'enum.packetstatus.nortufound',
      'No RTU Found'
    ),
    [RtuPacketStatus.NotProcessed]: t(
      'enum.packetstatus.notprocessed',
      'Not Processed'
    ),
    [RtuPacketStatus.NotSupported]: t(
      'enum.packetstatus.notsupported',
      'Packet Type Not Supported'
    ),
    [RtuPacketStatus.OutdatedConfigPacket]: t(
      'enum.packetstatus.outdatedconfigpacket',
      'Outdated Config Packet'
    ),
    [RtuPacketStatus.Processed]: t('enum.packetstatus.processed', 'Processed'),
    [RtuPacketStatus.ProcessedWithErrors]: t(
      'enum.packetstatus.processedwitherrors',
      'Processed With Errors'
    ),
    [RtuPacketStatus.Processing]: t(
      'enum.packetstatus.processing',
      'Processing'
    ),
    [RtuPacketStatus.RtuChannelCreationFailed]: t(
      'enum.packetstatus.rtuchannelcreationfailed',
      'RTU Channel Creation Failed'
    ),
    [RtuPacketStatus.RtuCreationFailed]: t(
      'enum.packetstatus.rtucreationfailed',
      'RTU Creation Failed'
    ),
    [RtuPacketStatus.RtuIsDeleted]: t(
      'enum.packetstatus.rtuisdeleted',
      'RTU Is Deleted'
    ),
    [RtuPacketStatus.RtuTypeNoLongerSupported]: t(
      'enum.packetstatus.rtutypenolongersupported',
      'RTU Type No Longer Supported'
    ),
    [RtuPacketStatus.UnknownError]: t(
      'enum.packetstatus.unknownerror',
      'Unknown Error - Packet Cannot Be Processed'
    ),
    [RtuPacketStatus.UnknownPacketType]: t(
      'enum.packetstatus.unknownpackettype',
      'Unknown Packet Type'
    ),
    // Manually Translated
    [RtuPacketStatus.FtpFileFormatSetToNone]: t(
      'enum.packetstatus.ftpFileFormatSetToNone',
      'FTP File Format Set To None'
    ),
  };
};

export const buildUserTypeTextMapping = (
  t: TFunction
): Record<UserType, string> => {
  return {
    [UserType.WebUser]: t('enum.userType.webUser', 'Web User'),
    [UserType.WebServiceUser]: t(
      'enum.userType.webServiceUser',
      'Web Service User'
    ),
    [UserType.WebUserAndWebServiceUser]: t(
      'enum.userType.webUserAndWebServiceUser',
      'Web User and Web Service User'
    ),
    [UserType.FTPUser]: t('enum.userType.ftpUser', 'FTP User'),
    // Subsystem user isn't used in the app, keeping it here to keep TypeScript
    // happy with the Record<UserType, string> type
    [UserType.SubsystemUser]: '',
  };
};

export const buildApplicationUserRoleTypeTextMapping = (
  t: TFunction
): Record<UserRoleTypeEnum, string> => {
  return {
    [UserRoleTypeEnum.SystemAdministrator]: t(
      'enum.appUserRoleType.systemAdmin',
      'System Administrator'
    ),
    [UserRoleTypeEnum.SystemUser]: t(
      'enum.appUserRoleType.systemUser',
      'System User'
    ),
    [UserRoleTypeEnum.DomainAdministrator]: t(
      'enum.appUserRoleType.domainAdmin',
      'Domain Administrator'
    ),
    [UserRoleTypeEnum.DomainUser]: t(
      'enum.appUserRoleType.domainUser',
      'Domain User'
    ),
    [UserRoleTypeEnum.Unauthenticated]: t(
      'enum.appUserRoleType.unauthenticated',
      'Unauthenticated'
    ),
  };
};

export const buildForecastModeTypeTextMapping = (t: TFunction) => {
  return {
    [ForecastModeType.NoForecast]: t(
      'enum.forecastmodetype.noforecast',
      'No Forecast'
    ),
    [ForecastModeType.HistoricalUsageRate]: t(
      'enum.forecastmodetype.historicalusageratesmoothed',
      'Historical Usage Rate Smoothed'
    ),
    [ForecastModeType.ManualUsageRate]: t(
      'enum.forecastmodetype.manualusagerate',
      'Manual Usage Rate'
    ),
    [ForecastModeType.Unsmoothed]: t(
      'enum.forecastmodetype.historicalusagerateunsmoothed',
      'Historical Usage Rate Unsmoothed'
    ),
  };
};

export const buildSupportedUOMTypeTextMapping = (t: TFunction) => {
  return {
    [SupportedUOMType.Basic]: t('enum.supportedUOMType.basic', 'Basic'),
    [SupportedUOMType.BasicWithPercentFull]: t(
      'enum.supportedUOMType.basicWithPercentFull',
      'Basic with % Full'
    ),
    [SupportedUOMType.SimplifiedVolumetric]: t(
      'enum.supportedUOMType.simplifiedVolumetric',
      'Simplified Volumetric'
    ),
    [SupportedUOMType.Volumetric]: t(
      'enum.supportedUOMType.volumetric',
      'Volumetric'
    ),
  };
};

export const buildScalingModeTypeTextMapping = (t: TFunction) => {
  return {
    [ScalingModeType.NotSet]: t('enum.scalingmodetype.notset', 'Not Set'),
    [ScalingModeType.Linear]: t('enum.scalingmodetype.linear', 'Linear'),
    [ScalingModeType.Ratio]: t('enum.scalingmodetype.ratio', 'Ratio'),
    [ScalingModeType.Mapped]: t('enum.scalingmodetype.mapped', 'Mapped'),
    [ScalingModeType.Prescaled]: t(
      'enum.scalingmodetype.noScaling',
      'No Scaling'
    ),
  };
};

export const buildUnitDisplayTypeTextMapping = (t: TFunction) => {
  return {
    [UnitDisplayType.Scaled]: t('ui.datachannel.scaled', 'Full'),
    [UnitDisplayType.Display]: t('enum.unitDisplayType.display', 'Display'),
    [UnitDisplayType.PercentFull]: t('enum.unittype.percentfull', '% Full'),
  };
};

export const eventComparatorTypeTextMapping = {
  [EventComparatorType.EqualTo]: '=',
  [EventComparatorType.GreaterThan]: '>',
  [EventComparatorType.GreaterThanEqualTo]: '>=',
  [EventComparatorType.LessThan]: '<',
  [EventComparatorType.LessThanEqualTo]: '<=',
};

export const buildInventoryStatusTypeTextMaping = (t: TFunction) => {
  return {
    [EventInventoryStatusType.Full]: t(
      'enum.eventinventorystatustype.full',
      'Full'
    ),
    [EventInventoryStatusType.Reorder]: t(
      'enum.eventinventorystatustype.reorder',
      'Reorder'
    ),
    [EventInventoryStatusType.Critical]: t(
      'enum.eventinventorystatustype.critical',
      'Critical'
    ),
    [EventInventoryStatusType.UserDefined]: t(
      'enum.eventinventorystatustype.userdefined',
      'User Defined'
    ),
    [EventInventoryStatusType.Empty]: t(
      'enum.eventinventorystatustype.empty',
      'Empty'
    ),
  };
};

export const buildInventoryStatusTypeEnumTextMapping = (t: TFunction) => {
  return {
    [EventRuleInventoryStatus.Normal]: t(
      'enum.eventinventorystatustype.normal',
      'Normal'
    ),
    [EventRuleInventoryStatus.Full]: t(
      'enum.eventinventorystatustype.full',
      'Full'
    ),
    [EventInventoryStatusType.Reorder]: t(
      'enum.eventinventorystatustype.reorder',
      'Reorder'
    ),
    [EventInventoryStatusType.Critical]: t(
      'enum.eventinventorystatustype.critical',
      'Critical'
    ),
    [EventInventoryStatusType.UserDefined]: t(
      'enum.eventinventorystatustype.userdefined',
      'User Defined'
    ),
    [EventInventoryStatusType.Empty]: t(
      'enum.eventinventorystatustype.empty',
      'Empty'
    ),
  };
};

export const buildAuditTypeTextMapping = (t: TFunction) => {
  return {
    [AuditType.DataChannel]: t(
      'enum.auditHistoryType.dataChannel',
      'Data Channel'
    ),
    [AuditType.Asset]: t('enum.auditHistoryType.asset', 'Asset'),
    [AuditType.RTU]: t('enum.auditHistoryType.rtu', 'RTU'),
    [AuditType.RTUChannel]: t(
      'enum.auditHistoryType.rtuChannel',
      'RTU Channel'
    ),
    [AuditType.CustomProperty]: t(
      'enum.auditHistoryType.customProperty',
      'Custom Property'
    ),
    [AuditType.DataChannelEventRule]: t(
      'enum.auditHistoryType.dataChannelEventRule',
      'Data Channel Event Rule'
    ),
    [AuditType.DcRawToScaledMapPoint]: t(
      'enum.auditHistoryType.dcRawToScaledMapPoint',
      'Data Channel Raw To Scaled Map Point'
    ),
    [AuditType.None]: t('enum.auditHistoryType.none', 'None'),
  };
};

export const buildTransferAssetResultTextMapping = (t: TFunction) => {
  return {
    [TransferAssetResultStatusType.Pending]: t(
      'enum.transferAssetResultStatusType.pending',
      'Pending'
    ),
    [TransferAssetResultStatusType.Transferred]: t(
      'enum.transferAssetResultStatusType.transferred',
      'Transferred'
    ),
    [TransferAssetResultStatusType.Error]: t(
      'enum.transferAssetResultStatusType.error',
      'Error'
    ),
    [TransferAssetResultStatusType.Rollback]: t(
      'enum.transferAssetResultStatusType.rollback',
      'Rollback'
    ),
  };
};
export const buildImportanceLevelTextMapping = (
  t: TFunction
): Record<EventImportanceLevelType, string> => {
  return {
    [EventImportanceLevelType.High]: t(
      'enum.eventimportanceleveltype.high',
      'High'
    ),
    [EventImportanceLevelType.Information]: t(
      'enum.eventimportanceleveltype.Information',
      'Information'
    ),
    [EventImportanceLevelType.Normal]: t(
      'enum.eventimportanceleveltype.normal',
      'Normal'
    ),
    [EventImportanceLevelType.Urgent]: t(
      'enum.eventimportanceleveltype.urgent',
      'Urgent'
    ),
    [EventImportanceLevelType.Warning]: t(
      'enum.eventimportanceleveltype.warning',
      'Warning'
    ),
  };
};

export const buildCardTextMapping = (t: TFunction) => {
  return {
    [RTUExpansionCardType.AD481]: t('enum.rtuexpansioncardtype.AD481', 'AD481'),
    [RTUExpansionCardType.AE280]: t('enum.rtuexpansioncardtype.AE280', 'AE280'),
    [RTUExpansionCardType.AE313]: t('enum.rtuexpansioncardtype.AE313', 'AE313'),
    [RTUExpansionCardType.AE370]: t('enum.rtuexpansioncardtype.AE370', 'AE370'),
    [RTUExpansionCardType.AE407]: t('enum.rtuexpansioncardtype.AE407', 'AE407'),
    [RTUExpansionCardType.AP188]: t('enum.rtuexpansioncardtype.AP188', 'AP188'),
    [RTUExpansionCardType.AR217]: t('enum.rtuexpansioncardtype.AR217', 'AR217'),
    [RTUExpansionCardType.AS452]: t('enum.rtuexpansioncardtype.AS452', 'AS452'),
    [RTUExpansionCardType.BC474]: t('enum.rtuexpansioncardtype.BC474', 'BC474'),
    [RTUExpansionCardType.CL310]: t('enum.rtuexpansioncardtype.CL310', 'CL310'),
    [RTUExpansionCardType.CO243]: t('enum.rtuexpansioncardtype.CO243', 'CO243'),
    [RTUExpansionCardType.DI303]: t('enum.rtuexpansioncardtype.DI303', 'DI303'),
  };
};

export const buildDataChannelTypeTextMapping = (t: TFunction) => {
  return {
    [DataChannelType.None]: t('enum.datachanneltype.notype', 'No Type'),
    [DataChannelType.Level]: t('enum.datachanneltype.level', 'Level'),
    [DataChannelType.Pressure]: t('enum.datachanneltype.pressure', 'Pressure'),
    [DataChannelType.DigitalInput]: t(
      'enum.datachanneltype.digitalinput',
      'Digital Input'
    ),
    [DataChannelType.BatteryVoltage]: t(
      'enum.datachanneltype.batteryvoltage',
      'Battery Voltage'
    ),
    [DataChannelType.Gps]: t('enum.datachanneltype.gps', 'GPS'),
    [DataChannelType.FlowMeter]: t(
      'enum.datachanneltype.flowmeter',
      'Flow Meter'
    ),
    [DataChannelType.Counter]: t('enum.datachanneltype.counter', 'Counter'),
    [DataChannelType.Temperature]: t(
      'enum.datachanneltype.temperature',
      'Temperature'
    ),
    [DataChannelType.OtherAnalog]: t(
      'enum.datachanneltype.otheranalog',
      'Other Analog'
    ),
    [DataChannelType.RtuCaseTemperature]: t(
      'enum.datachanneltype.rtucasetemperature',
      'RTU Case Temperature'
    ),
    [DataChannelType.Diagnostic]: t(
      'enum.datachanneltype.diagnostic',
      'Diagnostic'
    ),
    [DataChannelType.TotalizedLevel]: t(
      'enum.datachanneltype.totalizedlevel',
      'Totalized Level'
    ),
    [DataChannelType.VirtualChannel]: t(
      'enum.datachanneltype.virtualchannel',
      'Virtual Channel'
    ),
    [DataChannelType.Rtu]: t('enum.datachanneltype.rtu', 'RTU'),
    [DataChannelType.Shock]: t('enum.datachanneltype.shock', 'Shock'),
    [DataChannelType.RateOfChange]: t(
      'enum.datachanneltype.rateofchange',
      'Rate of Change'
    ),
    [DataChannelType.SignalStrength]: t(
      'enum.datachanneltype.signalstrength',
      'Signal Strength'
    ),
    [DataChannelType.ChargeCurrent]: t(
      'enum.datachanneltype.chargecurrent',
      'Average Charge Current'
    ),
  };
};

export const buildAssetTypeTextMapping = (t: TFunction) => {
  return {
    [AssetType.None]: t('enum.assettype.none', 'None'),
    [AssetType.Tank]: t('enum.assettype.tank', 'Tank'),
    [AssetType.HeliumIsoContainer]: t(
      'enum.assettype.heliumisocontainer',
      'Helium ISO Container'
    ),
    [AssetType.Vaporizer]: t('enum.assettype.vaporizer', 'Vaporizer'),
    [AssetType.PressurePump]: t('enum.assettype.pressurepump', 'Pressure Pump'),
    [AssetType.CompositeAsset]: t(
      'enum.assettype.compositeasset',
      'Composite Asset'
    ),
    [AssetType.FlowMeter]: t('enum.assettype.flowmeter', 'Flow Meter'),
    [AssetType.GasMixer]: t('enum.assettype.gasmixer', 'Gas Mixer'),
    [AssetType.Plant]: t('enum.assettype.plant', 'Plant'),
    [AssetType.TubeTrailer]: t('enum.assettype.tubetrailer', 'Tube Trailer'),
    [AssetType.Pipeline]: t('enum.assettype.pipeline', 'Pipeline'),
    [AssetType.HVAC]: t('enum.assettype.hvac', 'HVAC'),
    [AssetType.CFM]: t('enum.assettype.cfm', 'CFM'),
    [AssetType.EM]: t('enum.assettype.em', 'EM'),
    [AssetType.MkLine]: t('enum.assettype.mkline', 'MKLINE'),
    [AssetType.FoodFreezer]: t('enum.assettype.foodFreezer', 'Food Freezer'),
  };
};

export const buildAssetTypeTextMappingNoTranslation = () => {
  return {
    [AssetType.None]: 'None',
    [AssetType.Tank]: 'Tank',
    [AssetType.HeliumIsoContainer]: 'Helium ISO Container',
    [AssetType.Vaporizer]: 'Vaporizer',
    [AssetType.PressurePump]: 'Pressure Pump',
    [AssetType.CompositeAsset]: 'Composite Asset',
    [AssetType.FlowMeter]: 'Flow Meter',
    [AssetType.GasMixer]: 'Gas Mixer',
    [AssetType.Plant]: 'Plant',
    [AssetType.TubeTrailer]: 'Tube Trailer',
    [AssetType.Pipeline]: 'Pipeline',
    [AssetType.HVAC]: 'HVAC',
    [AssetType.CFM]: 'CFM',
    [AssetType.EM]: 'EM',
    [AssetType.MkLine]: 'MKLINE',
    [AssetType.FoodFreezer]: 'Food Freezer',
  };
};

export const buildEventRuleTypeTextMapping = (
  t: TFunction
): Record<EventRuleType, string> => {
  return {
    [EventRuleType.Level]: t('enum.eventruletype.level', 'Level'),
    [EventRuleType.MissingData]: t(
      'enum.eventruletype.missingdata',
      'Missing Data'
    ),
    [EventRuleType.ScheduledDeliveryTooEarly]: t(
      'enum.eventruletype.earlydelivery',
      'Early Delivery'
    ),
    [EventRuleType.ScheduledDeliveryTooLate]: t(
      'enum.eventruletype.latedelivery',
      'Late Delivery'
    ),
    [EventRuleType.ScheduledDeliveryMissed]: t(
      'enum.eventruletype.misseddelivery',
      'Missed Delivery'
    ),
    [EventRuleType.RTUChannelEvent]: t(
      'enum.eventruletype.rtuchannelevents',
      'RTU/RTU Channel Event'
    ),
    [EventRuleType.UsageRate]: t('enum.eventruletype.usagerate', 'Usage Rate'),
    [EventRuleType.GeoFencing]: t(
      'enum.eventruletype.geofencing',
      'Geofencing'
    ),
  };
};

export const buildTankTypeTextMapping = (t: TFunction) => {
  return {
    [TankType.None]: t('enum.tanktype.none', 'None'),
    [TankType.RectangularBox]: t('enum.tanktype.rectangular', 'Rectangular'),
    [TankType.SphericalTank]: t('enum.tanktype.spherical', 'Spherical'),
    [TankType.TotalizedTank]: t(
      'enum.tanktype.totalizedtank',
      'Totalized Tank'
    ),
    [TankType.HorizontalWith2To1EllipsoidalEnds]: t(
      'enum.tanktype.horizontalwith2-1ellipsoidalends',
      'Horizontal with 2:1 Ellipsoidal Ends'
    ),
    [TankType.HorizontalWithFlatEnds]: t(
      'enum.tanktype.horizontalwithflatends',
      'Horizontal with Flat Ends'
    ),
    [TankType.HorizontalWithHemisphericalEnds]: t(
      'enum.tanktype.horizontalwithhemisphericalends',
      'Horizontal with Hemispherical Ends'
    ),
    [TankType.HorizontalWithVariableDishedEnds]: t(
      'enum.tanktype.horizontalwithvariabledishedends',
      'Horizontal with Variable Dished Ends'
    ),
    [TankType.VerticalWith2To1EllipsoidalEnds]: t(
      'enum.tanktype.verticalwith2-1ellipsodialend',
      'Vertical with 2:1 Ellipsoidal Ends'
    ),
    [TankType.VerticalWithConicalBottomEnd]: t(
      'enum.tanktype.verticalwithconicalbottomend',
      'Vertical with Conical Bottom End'
    ),
    [TankType.VerticalWithFlatEnds]: t(
      'enum.tanktype.verticalwithflatends',
      'Vertical with Flat Ends'
    ),
    [TankType.VerticalWithHemisphericalEnds]: t(
      'enum.tanktype.verticalwithhemisphericalends',
      'Vertical with Hemispherical Ends'
    ),
    [TankType.VerticalWithVariableDishedEnds]: t(
      'enum.tanktype.verticalwithvariabledishedends',
      'Vertical with Variable Dished Ends'
    ),
  };
};

export const buildUnitsOfMeasureTextMapping = (
  t: TFunction
): Record<UnitType, string> => {
  return {
    [UnitType.WaterColumnMillimeters]: t('enum.unittype.mmwc', 'mm WC'),
    [UnitType.WaterColumnCentimeters]: t('enum.unittype.cmwc', 'cm WC'),
    [UnitType.WaterColumnMeters]: t('enum.unittype.mwc', 'm WC'),
    [UnitType.HydrogenMillimeters]: t('enum.unittype.mh2', 'mm H2'),
    [UnitType.HydrogenCentimeters]: t('enum.unittype.cmh2', 'cm H2'),
    [UnitType.HydrogenMeters]: t('enum.unittype.mmh2', 'm H2'),
    [UnitType.WaterColumnInches]: t('enum.unittype.inswc', 'Ins WC'),
    [UnitType.HydrogenInches]: t('enum.unittype.insh2', 'Ins H2'),
    [UnitType.Millimeters]: t('enum.unittype.mm', 'mm'),
    [UnitType.Centimeters]: t('enum.unittype.cm', 'cm'),
    [UnitType.Meters]: t('enum.unittype.m', 'm'),
    [UnitType.Inches]: t('enum.unittype.in', 'in'),
    [UnitType.Feet]: t('enum.unittype.ft', 'ft'),
    [UnitType.Yards]: t('enum.unittype.yd', 'yd'),
    [UnitType.Liters]: t('enum.unittype.l', 'l'),
    [UnitType.KiloLiters]: t('enum.unittype.kl', 'kl'),
    [UnitType.CubicMeters]: t('enum.unittype.m3', 'm3'),
    [UnitType.Default]: t('enum.unittype.default', 'default'),
    [UnitType.USGallons]: t('enum.unittype.galus', 'gal US'),
    [UnitType.UKGallons]: t('enum.unittype.galimp', 'gal Imp'),
    [UnitType.CubicInches]: t('enum.unittype.in3', 'in3'),
    [UnitType.CubicFeet]: t('enum.unittype.ft3', 'ft3'),
    [UnitType.CubicYards]: t('enum.unittype.yd3', 'yd3'),
    [UnitType.Kilograms]: t('enum.unittype.kg', 'kg'),
    [UnitType.MetricTonne]: t('enum.unittype.t', 't'),
    [UnitType.Pounds]: t('enum.unittype.lbs', 'lbs'),
    [UnitType.TonUS]: t('enum.unittype.ton', 'tons'),
    [UnitType.StandardCubicMeter]: t('enum.unittype.scm', 'SCM'),
    [UnitType.StandardCubicFoot]: t('enum.unittype.scf', 'SCF'),
    [UnitType.PercentFull]: t('enum.unittype.percentfull', '% Full'),
  };
};

export const buildUnitTypeEnumTextMapping = (
  t: TFunction
): Record<UnitTypeEnum, string> => {
  return {
    [UnitTypeEnum.WaterColumnMillimeters]: t('enum.unittype.mmwc', 'mm WC'),
    [UnitTypeEnum.WaterColumnCentimeters]: t('enum.unittype.cmwc', 'cm WC'),
    [UnitTypeEnum.WaterColumnMeters]: t('enum.unittype.mwc', 'm WC'),
    [UnitTypeEnum.HydrogenMillimeters]: t('enum.unittype.mh2', 'mm H2'),
    [UnitTypeEnum.HydrogenCentimeters]: t('enum.unittype.cmh2', 'cm H2'),
    [UnitTypeEnum.HydrogenMeters]: t('enum.unittype.mmh2', 'm H2'),
    [UnitTypeEnum.WaterColumnInches]: t('enum.unittype.inswc', 'Ins WC'),
    [UnitTypeEnum.HydrogenInches]: t('enum.unittype.insh2', 'Ins H2'),
    [UnitTypeEnum.Millimeters]: t('enum.unittype.mm', 'mm'),
    [UnitTypeEnum.Centimeters]: t('enum.unittype.cm', 'cm'),
    [UnitTypeEnum.Meters]: t('enum.unittype.m', 'm'),
    [UnitTypeEnum.Inches]: t('enum.unittype.in', 'in'),
    [UnitTypeEnum.Feet]: t('enum.unittype.ft', 'ft'),
    [UnitTypeEnum.Yards]: t('enum.unittype.yd', 'yd'),
    [UnitTypeEnum.Liters]: t('enum.unittype.l', 'l'),
    [UnitTypeEnum.KiloLiters]: t('enum.unittype.kl', 'kl'),
    [UnitTypeEnum.CubicMeters]: t('enum.unittype.m3', 'm3'),
    [UnitTypeEnum.Default]: t('enum.unittype.default', 'default'),
    [UnitTypeEnum.USGallons]: t('enum.unittype.galus', 'gal US'),
    [UnitTypeEnum.UKGallons]: t('enum.unittype.galimp', 'gal Imp'),
    [UnitTypeEnum.CubicInches]: t('enum.unittype.in3', 'in3'),
    [UnitTypeEnum.CubicFeet]: t('enum.unittype.ft3', 'ft3'),
    [UnitTypeEnum.CubicYards]: t('enum.unittype.yd3', 'yd3'),
    [UnitTypeEnum.Kilograms]: t('enum.unittype.kg', 'kg'),
    [UnitTypeEnum.MetricTonne]: t('enum.unittype.t', 't'),
    [UnitTypeEnum.Pounds]: t('enum.unittype.lbs', 'lbs'),
    [UnitTypeEnum.TonUS]: t('enum.unittype.ton', 'tons'),
    [UnitTypeEnum.StandardCubicMeter]: t('enum.unittype.scm', 'SCM'),
    [UnitTypeEnum.StandardCubicFoot]: t('enum.unittype.scf', 'SCF'),
    [UnitTypeEnum.PercentFull]: t('enum.unittype.percentfull', '% Full'),
    [UnitTypeEnum.DefaultScaled]: t(
      'enum.unittype.defaultScaled',
      'Non-Volumetric'
    ),
  };
};

export const buildOperatorTypeMapping = () => {
  return {
    [VirtualChannelOperatorType.None]: '',
    [VirtualChannelOperatorType.Addition]: '+',
    [VirtualChannelOperatorType.Subtraction]: '-',
    [VirtualChannelOperatorType.Multiplication]: '*',
    [VirtualChannelOperatorType.Division]: '/',
    [VirtualChannelOperatorType.And]: '&',
    [VirtualChannelOperatorType.Or]: '|',
    [VirtualChannelOperatorType.Expoentiation]: '^',
  };
};

export const buildPollScheduleTypeMapping = (t: TFunction) => {
  return {
    [RTUPollScheduleType.None]: t('enum.typeOfSchedule.none', 'None'),
    [RTUPollScheduleType.Interval]: t(
      'enum.typeOfSchedule.interval',
      'Interval'
    ),
    [RTUPollScheduleType.PointInTime]: t(
      'enum.typeOfSchedule.timeOfDay',
      'Time of Day'
    ),
  };
};

export const buildGeoAreaCategoryTypeTextMapping = (t: TFunction) => {
  return {
    [GeoAreaCategory.None]: t('enum.geoAreaCategoryType.none', 'None'),
    [GeoAreaCategory.Port]: t('enum.geoAreaCategoryType.port', 'Port'),
    [GeoAreaCategory.MaintenanceArea]: t(
      'enum.geoAreaCategoryType.maintenanceArea',
      'Maintenance Area'
    ),
    [GeoAreaCategory.ParkingLot]: t(
      'enum.geoAreaCategoryType.parkingLot',
      'Parking Lot'
    ),
    [GeoAreaCategory.CustomerLocation]: t(
      'enum.geoAreaCategoryType.customerLocation',
      'Customer Location'
    ),
    [GeoAreaCategory.FillSite]: t(
      'enum.geoAreaCategoryType.fillSite',
      'Fill Site'
    ),
  };
};

export const buildZoomRangeOptionsMapping = (t: TFunction) => {
  return {
    [ReadingsChartZoomLevel.NotSet]: t(
      'enum.readingschartzoomlevel.notset',
      'Custom'
    ),
    [ReadingsChartZoomLevel.TwoYears]: t(
      'enum.readingschartzoomlevel.twoyears',
      '2 Years'
    ),
    [ReadingsChartZoomLevel.OneYear]: t(
      'enum.readingschartzoomlevel.oneyear',
      '1 Year'
    ),
    [ReadingsChartZoomLevel.TwelveWeeks]: t(
      'enum.readingschartzoomlevel.twelveweeks',
      '12 Weeks'
    ),
    [ReadingsChartZoomLevel.FourWeeks]: t(
      'enum.readingschartzoomlevel.fourweeks',
      '4 Weeks'
    ),
    [ReadingsChartZoomLevel.TwoWeeks]: t(
      'enum.readingschartzoomlevel.twoweeks',
      '2 Weeks'
    ),
    [ReadingsChartZoomLevel.OneWeek]: t(
      'enum.readingschartzoomlevel.oneweek',
      '1 Week'
    ),
    [ReadingsChartZoomLevel.FourDays]: t(
      'enum.readingschartzoomlevel.fourdays',
      '4 Days'
    ),
    [ReadingsChartZoomLevel.TwoDays]: t(
      'enum.readingschartzoomlevel.twodays',
      '2 Days'
    ),
    [ReadingsChartZoomLevel.OneDay]: t(
      'enum.readingschartzoomlevel.oneday',
      '1 Day'
    ),
    [ReadingsChartZoomLevel.TwelveHours]: t(
      'enum.readingschartzoomlevel.twelvehours',
      '12 Hours'
    ),
    [ReadingsChartZoomLevel.SixHours]: t(
      'enum.readingschartzoomlevel.sixhours',
      '6 Hours'
    ),
    [ReadingsChartZoomLevel.ThreeHour]: t(
      'enum.readingschartzoomlevel.threehour',
      '3 Hours'
    ),
  };
};

export const buildDaysRunOutOptionsMapping = (t: TFunction) => {
  return {
    [ReadingsChartZoomLevel.NotSet]: t(
      'enum.readingschartzoomlevel.notset',
      'Custom'
    ),
    [ReadingsChartZoomLevel.TwoYears]: t(
      'enum.readingschartzoomlevel.twoyears',
      '2 Years'
    ),
    [ReadingsChartZoomLevel.OneYear]: t(
      'enum.readingschartzoomlevel.oneyear',
      '1 Year'
    ),
    [ReadingsChartZoomLevel.TwelveWeeks]: t(
      'enum.readingschartzoomlevel.twelveweeks',
      '12 Weeks'
    ),
    [ReadingsChartZoomLevel.FourWeeks]: t(
      'enum.readingschartzoomlevel.fourweeks',
      '4 Weeks'
    ),
    [ReadingsChartZoomLevel.TwoWeeks]: t(
      'enum.readingschartzoomlevel.twoweeks',
      '2 Weeks'
    ),
    [ReadingsChartZoomLevel.OneWeek]: t(
      'enum.readingschartzoomlevel.oneweek',
      '1 Week'
    ),
    [ReadingsChartZoomLevel.FourDays]: t(
      'enum.readingschartzoomlevel.fourdays',
      '4 Days'
    ),
    [ReadingsChartZoomLevel.TwoDays]: t(
      'enum.readingschartzoomlevel.twodays',
      '2 Days'
    ),
    [ReadingsChartZoomLevel.OneDay]: t(
      'enum.readingschartzoomlevel.oneday',
      '1 Day'
    ),
    [ReadingsChartZoomLevel.TwelveHours]: t(
      'enum.readingschartzoomlevel.twelvehours',
      '12 Hours'
    ),
    [ReadingsChartZoomLevel.SixHours]: t(
      'enum.readingschartzoomlevel.sixhours',
      '6 Hours'
    ),
    [ReadingsChartZoomLevel.ThreeHour]: t(
      'enum.readingschartzoomlevel.threehour',
      '3 Hours'
    ),
  };
};

export const buildProblemReportPriorityTextMapping = (
  t: TFunction
): Record<ProblemReportPriorityEnum, string> => {
  return {
    [ProblemReportPriorityEnum.High]: t(
      'enum.problemreportpriority.high',
      'Very High'
    ),
    [ProblemReportPriorityEnum.Medium]: t(
      'enum.problemreportpriority.medium',
      'High'
    ),
    [ProblemReportPriorityEnum.Low]: t(
      'enum.problemreportpriority.low',
      'Medium'
    ),
  };
};

export const buildAPCIUnitTypeTextMapping = (
  t: TFunction
): Record<APCIUnitType, string> => {
  return {
    [APCIUnitType.WaterColumnMillimeters]: t('enum.unittype.mmH2o', 'mmH2O'),
    [APCIUnitType.WaterColumnCentimeters]: t('enum.unittype.cmH2o', 'cmH2O'),
    [APCIUnitType.WaterColumnMeters]: t('enum.unittype.mH2o', 'mH2O'),
    [APCIUnitType.HydrogenMillimeters]: t('enum.unittype.mmH2', 'mmH2'),
    [APCIUnitType.HydrogenCentimeters]: t('enum.unittype.cmH2', 'cmH2'),
    [APCIUnitType.HydrogenMeters]: t('enum.unittype.mH2', 'mH2'),
    [APCIUnitType.WaterColumnInches]: t('enum.unittype.inh2o', 'inH2O'),
    [APCIUnitType.HydrogenInches]: t('enum.unittype.inh2', 'inH2'),
    [APCIUnitType.Millimeters]: t('enum.unittype.MM', 'MM'),
    [APCIUnitType.Centimeters]: t('enum.unittype.CM', 'CM'),
    [APCIUnitType.Meters]: t('enum.unittype.M', 'M'),
    [APCIUnitType.Inches]: t('enum.unittype.inch', 'Inch'),
    [APCIUnitType.Liters]: t('enum.unittype.lt', 'LT'),
    [APCIUnitType.USGallons]: t('enum.unittype.gal', 'Gal'),
    [APCIUnitType.Kilograms]: t('enum.unittype.KG', 'KG'),
    [APCIUnitType.Pounds]: t('enum.unittype.lb', 'LB'),
    [APCIUnitType.StandardCubicFoot]: t('enum.unittype.scf', 'SCF'),
    [APCIUnitType.PercentageFull]: t('enum.unittype.pct', 'Pct'),
    [APCIUnitType.GaugePressureBarg]: t('enum.apciunittype.barg', 'BARG'),
    [APCIUnitType.GaugePressurePSI]: t('enum.apciunittype.psig', 'PSIG'),
    [APCIUnitType.Volt]: t('enum.apciunittype.volt', 'Volt'),
    [APCIUnitType.Amp]: t('enum.apciunittype.amp', 'A'),
    [APCIUnitType.Counts]: t('enum.apciunittype.counts', 'Counts'),
    [APCIUnitType.DegreeCelcius]: t('enum.apciunittype.degc', 'Deg C'),
    [APCIUnitType.DegreeFahrenheit]: t('enum.apciunittype.degf', 'Deg F'),
    [APCIUnitType.DegreeKelvin]: t('enum.apciunittype.degk', 'Deg K'),
    [APCIUnitType.Hours]: t('enum.apciunittype.hrs', 'Hrs'),
    [APCIUnitType.KilogramPerHour]: t('enum.apciunittype.kgperh', 'KG/H'),
    [APCIUnitType.KilogramMin]: t('enum.apciunittype.kgm', 'KGM'),
    [APCIUnitType.KiloWatt]: t('enum.apciunittype.kw', 'KW'),
    [APCIUnitType.KiloWattHour]: t('enum.apciunittype.kwh', 'KWH'),
    [APCIUnitType.MilliAmp]: t('enum.apciunittype.ma', 'MA'),
    [APCIUnitType.MilliBar]: t('enum.apciunittype.mbar', 'mBAR'),
    [APCIUnitType.Minute]: t('enum.apciunittype.min', 'Min'),
    [APCIUnitType.NormalCubicMeter]: t('enum.apciunittype.nm3', 'NM3'),
    [APCIUnitType.NormalCubicMeterPerHr]: t(
      'enum.apciunittype.nm3perh',
      'NM3/H'
    ),
    [APCIUnitType.Ohms]: t('enum.apciunittype.ohms', 'Ohms'),
    [APCIUnitType.PartPerMillion]: t('enum.apciunittype.ppm', 'PPM'),
    [APCIUnitType.PartPerMillionO2]: t('enum.apciunittype.ppmo2', 'ppmO2'),
    [APCIUnitType.GaugePressurePSIAbsolute]: t(
      'enum.apciunittype.psia',
      'PSIA'
    ),
    [APCIUnitType.StandardCubicFootPerHr]: t('enum.apciunittype.scfh', 'SCFH'),
    [APCIUnitType.Tonne]: t('enum.apciunittype.tonne', 'Tonne'),
    [APCIUnitType.Second]: t('enum.apciunittype.sec', 'Sec'),
    [APCIUnitType.MegaPascal]: t('enum.apciunittype.mpascal', 'MPa'),
  };
};

export const buildAPCITankFunctionTypeTextMapping = (
  t: TFunction
): Record<APCITankFunctionType, string> => {
  return {
    [APCITankFunctionType.Main]: t('enum.apcitankfunctiontype.main', 'Main'),
    [APCITankFunctionType.Reserve1]: t(
      'enum.apcitankfunctiontype.reserve1',
      'Reserve1'
    ),
    [APCITankFunctionType.Reserve2]: t(
      'enum.apcitankfunctiontype.reserve2',
      'Reserve2'
    ),
    [APCITankFunctionType.Reserve3]: t(
      'enum.apcitankfunctiontype.reserve3',
      'Reserve3'
    ),
    [APCITankFunctionType.Reserve4]: t(
      'enum.apcitankfunctiontype.reserve4',
      'Reserve4'
    ),
    [APCITankFunctionType.Reserve5]: t(
      'enum.apcitankfunctiontype.reserve5',
      'Reserve5'
    ),
    [APCITankFunctionType.Reserve6]: t(
      'enum.apcitankfunctiontype.reserve6',
      'Reserve6'
    ),
    [APCITankFunctionType.Reserve7]: t(
      'enum.apcitankfunctiontype.reserve7',
      'Reserve7'
    ),
    [APCITankFunctionType.Reserve8]: t(
      'enum.apcitankfunctiontype.reserve8',
      'Reserve8'
    ),
    [APCITankFunctionType.Reserve9]: t(
      'enum.apcitankfunctiontype.reserve9',
      'Reserve9'
    ),
  };
};

export const buildUnitConversionModeTextMapping = (
  t: TFunction
): Record<UnitConversionModeEnum, string> => {
  return {
    [UnitConversionModeEnum.Basic]: t('enum.unitConversionMode.Basic', 'Basic'),
    [UnitConversionModeEnum.SimplifiedVolumetric]: t(
      'enum.unitConversionMode.simplifiedVolumetric',
      'Simplified Volumetric'
    ),
    [UnitConversionModeEnum.Volumetric]: t(
      'enum.unitConversionMode.volumetric',
      'Volumetric'
    ),
  };
};

// TODO: These translations should probably be verified?
export const buildGasMixerDataChannelTypeTextMapping = (t: TFunction) => {
  return {
    [GasMixerDataChannelType.None]: t('enum.assettype.none', 'None'),
    [GasMixerDataChannelType.Gas1Pressure]: t(
      'enum.gasMixer.gas1Pressure',
      'Gas 1 Pressure'
    ),
    [GasMixerDataChannelType.Gas2Pressure]: t(
      'enum.gasMixer.gas2Pressure',
      'Gas 2 Pressure'
    ),
    [GasMixerDataChannelType.Gas3Pressure]: t(
      'enum.gasMixer.gas3Pressure',
      'Gas 3 Pressure'
    ),
    [GasMixerDataChannelType.DigitalGasInValve]: t(
      'enum.gasMixer.digitalGasInletValve',
      'Digital Gas-In Valve'
    ),
    [GasMixerDataChannelType.MixingTankPressure]: t(
      'enum.gasMixer.mixingTankPressure',
      'Mixing Tank Pressure'
    ),
    [GasMixerDataChannelType.GasOutAnalyzer]: t(
      'enum.gasMixer.gasOutAnalyzer',
      'Gas Out Analyzer'
    ),
    [GasMixerDataChannelType.MixerOutputPressure]: t(
      'enum.gasMixer.mixerOutputPressure',
      'Mixer Output Pressure'
    ),
  };
};

export const buildFtpFileFormatCategoryTextMapping = (t: TFunction) => {
  return {
    [FtpFileFormatCategory.None]: t('enum.ftpFileFormat.none', 'None'),
    [FtpFileFormatCategory.Praxair]: t('enum.ftpFileFormat.praxair', 'Praxair'),
    [FtpFileFormatCategory.Apci]: t('enum.ftpFileFormat.apci', 'APCI'),
    [FtpFileFormatCategory.Intellitrans]: t(
      'enum.ftpFileFormat.intellitrans',
      'Intellitrans'
    ),
    [FtpFileFormatCategory.Yara]: t('enum.ftpFileFormat.yara', 'Yara'),
    [FtpFileFormatCategory.Generic]: t('enum.ftpFileFormat.generic', 'Generic'),
    [FtpFileFormatCategory.Linde]: t('enum.ftpFileFormat.linde', 'Linde'),
    [FtpFileFormatCategory.Generic2]: t(
      'enum.ftpFileFormat.generic2',
      'Generic 2'
    ),
    [FtpFileFormatCategory.WhiteMartins]: t(
      'enum.ftpFileFormat.whiteMartins',
      'White Martins'
    ),
    [FtpFileFormatCategory.EndressHauser]: t(
      'enum.ftpFileFormat.endressHauser',
      'Endress Hauser'
    ),
    [FtpFileFormatCategory.PraxairHelium]: t(
      'enum.ftpFileFormat.praxairHelium',
      'Praxair Helium'
    ),
    [FtpFileFormatCategory.GenericJson]: t(
      'enum.ftpFileFormat.genericJson',
      'Generic Json'
    ),
    [FtpFileFormatCategory.Ortec]: t('enum.ftpFileFormat.ortec', 'Ortec'),
    [FtpFileFormatCategory.VolumetricJson]: t(
      'enum.ftpFileFormat.volumetricjson',
      'Volumetric Json'
    ),
    [FtpFileFormatCategory.BasicJson]: t(
      'enum.ftpFileFormat.basicjson',
      'Basic Json'
    ),
  };
};

export const buildDataSourceTypeTextMapping = (t: TFunction) => {
  return {
    [DataChannelDataSource.Manual]: t(
      'enum.datachanneldatasourcetype.manual',
      'Manual'
    ),
    [DataChannelDataSource.RTU]: t('ui.common.rtu', 'RTU'),
    [DataChannelDataSource.PublishedDataChannel]: t(
      'enum.datachanneldatasourcetype.publisheddatachannel',
      'Published Data Channel'
    ),
    [DataChannelDataSource.DataChannel]: t(
      'enum.datachanneldatasourcetype.dataChannels',
      'Data Channels'
    ),
  };
};

export const buildForecastModeTextMapping = (t: TFunction) => {
  return {
    [ForecastMode.NoForecast]: t(
      'enum.forecastmodetype.noforecast',
      'No Forecast'
    ),
    [ForecastMode.HistoricalUsageRate]: t(
      'enum.forecastmodetype.historicalusageratesmoothed',
      'Historical Usage Rate Smoothed'
    ),
    [ForecastMode.ManualUsageRate]: t(
      'enum.forecastmodetype.manualusagerate',
      'Manual Usage Rate'
    ),
    [ForecastMode.Unsmoothed]: t(
      'enum.forecastmodetype.historicalusagerateunsmoothed',
      'Historical Usage Rate Unsmoothed'
    ),
  };
};
export const buildRTUPowerModeTypeEnumTextMapping = (t: TFunction) => {
  return {
    [RTUPowerModeTypeEnum.LowPower]: t(
      'enum.rtupowermodetype.lowpower',
      'Low Power'
    ),
    [RTUPowerModeTypeEnum.Pollable]: t(
      'enum.rtupowermodetype.pollable',
      'Pollable'
    ),
  };
};
export const buildDigitalChannelModeTextMapping = (t: TFunction) => {
  return {
    [DigitalChannelMode.NormallyOpen]: t(
      'enum.modbusdigitalchannelmode.open',
      'Open'
    ),
    [DigitalChannelMode.NormallyClosed]: t(
      'enum.modbusdigitalchannelmode.closed',
      'Closed'
    ),
  };
};
export const buildDataLoggingModeTextMapping = (t: TFunction) => {
  return {
    [DataLoggingMode.None]: t('ui.common.off', 'Off'),
    [DataLoggingMode.Perodic]: t('ui.common.perodic', 'Perodic'),
    [DataLoggingMode.Event]: t('ui.common.event', 'Event'),
    [DataLoggingMode.Both]: t(
      'enum.modbusdataloggingmode.periodicandevent',
      'Perodic and Event'
    ),
  };
};
export const buildDataLogAlignmentUnitTextMapping = (t: TFunction) => {
  return {
    [DataLogAlignmentUnit.None]: t('ui.common.none', 'None'),
    [DataLogAlignmentUnit.Hour]: t('ui.common.hour', 'Hour'),
    [DataLogAlignmentUnit.Day]: t('ui.common.day', 'Day'),
    [DataLogAlignmentUnit.Week]: t('ui.common.week', 'Week'),
    [DataLogAlignmentUnit.Month]: t('ui.common.month', 'Month'),
  };
};
export const buildReversePollEdgeTextMapping = (t: TFunction) => {
  return {
    [ModbusReversePollEdge.Disabled]: t(
      'enum.modbusreversepolledge.disabled',
      'Disabled'
    ),
    [ModbusReversePollEdge.Rising]: t(
      'enum.modbusreversepolledge.rising',
      'Rising'
    ),
    [ModbusReversePollEdge.Falling]: t(
      'enum.modbusreversepolledge.falling',
      'Falling'
    ),
    [ModbusReversePollEdge.RisingAndFalling]: t(
      'enum.modbusreversepolledge.risingandfalling',
      'Rising and Falling'
    ),
  };
};
export const buildHornerRtuModeTextMapping = (t: TFunction) => {
  return {
    [HornerRtuMode.Compressor]: t(
      'enum.hornerrtutype.compressor',
      'Compressor'
    ),
    [HornerRtuMode.Dispenser]: t('enum.hornerrtutype.dispenser', 'Dispenser'),
  };
};

export const buildHornerRtuCategoryTextMapping = (t: TFunction) => {
  return {
    [HornerRtuCategory.Ip]: t('enum.hornermodeltype.ip', 'IP'),
    [HornerRtuCategory.Serial]: t('enum.hornermodeltype.serial', 'Serial'),
  };
};

export const buildHornerMessageTemplateTextMapping = (t: TFunction) => {
  return {
    [HornerMessageTemplateType.Detail]: t(
      'enum.hornermessagetemplatetype.detail',
      'Detail'
    ),
    [HornerMessageTemplateType.Transaction]: t(
      'enum.hornermessagetemplatetype.transaction',
      'Transaction'
    ),
  };
};

export const buildHornerRtuTransportTypeEnumTextMapping = (t: TFunction) => {
  return {
    [RTUTransportTypeEnum.Tcp]: t(
      'enum.rtutransporttype.tcpipcellular',
      'TCP/IP Cellular'
    ),
  };
};
export const buildHornerRtuCarrierTypeEnumTextMapping = (t: TFunction) => {
  return {
    [TelecommunicationsCarrier.AttApci]: t('enum.carrier.attapci', 'AT&T-APCI'),
    [TelecommunicationsCarrier.Att4GApci]: t(
      'enum.carrier.att4gapci',
      'AT&T-APCI-4G'
    ),
  };
};

export const buildRTUAutoTimingCorrectionSourceEnumTextMapping = (
  t: TFunction
) => {
  return {
    [RTUAutoTimingCorrectionSourceEnum.None]: t(
      'enum.rtuautotimingcorrectionsource.none',
      'None'
    ),
    [RTUAutoTimingCorrectionSourceEnum.InitialCorrection]: t(
      'enum.rtuautotimingcorrectionsource.initialcorrection',
      'Initial Correction'
    ),
    [RTUAutoTimingCorrectionSourceEnum.LazyCorrection]: t(
      'enum.rtuautotimingcorrectionsource.lazycorrection',
      'Lazy Correction'
    ),
    [RTUAutoTimingCorrectionSourceEnum.UseServerTimestamp]: t(
      'enum.rtuautotimingcorrectionsource.useservertimestamp',
      'Use Server Timestamp'
    ),
  };
};

export const buildSmsRTUAutoTimingCorrectionSourceEnumTextMapping = (
  t: TFunction
) => {
  return {
    [RTUAutoTimingCorrectionSourceEnum.None]: t(
      'enum.rtuautotimingcorrectionsource.manual',
      'Manual'
    ),
    [RTUAutoTimingCorrectionSourceEnum.InitialCorrection]: t(
      'enum.rtuautotimingcorrectionsource.firstdatapacketthenhistorical',
      'First Data Packet then Historical'
    ),
    [RTUAutoTimingCorrectionSourceEnum.LazyCorrection]: t(
      'enum.rtuautotimingcorrectionsource.historical',
      'Historical'
    ),
    [RTUAutoTimingCorrectionSourceEnum.UseServerTimestamp]: t(
      'enum.rtuautotimingcorrectionsource.usepacketreceivetime',
      'Use Packet Receive Time'
    ),
  };
};

export const buildRawUnitsTypeTextMapping = (t: TFunction) => {
  return {
    [RawUnitTypeEnum.MilliAmps]: t('enum.rawunittype.milliamps', 'MilliAmps'),
    [RawUnitTypeEnum.Adc]: t('enum.rawunittype.adc', 'ADC'),
  };
};

export const buildMaxDailyTransmissionSmsRtuTypeTextMapping = (
  t: TFunction
) => {
  return {
    [MaxDailyTransmissionSmsRtuType.Unlimited]: t(
      'enum.maxdailytransmissionsmsrtutype.unlimited',
      '0 - Unlimited'
    ),
    [MaxDailyTransmissionSmsRtuType.Two]: t(
      'enum.maxdailytransmissionsmsrtutype.two',
      '2'
    ),
    [MaxDailyTransmissionSmsRtuType.Four]: t(
      'enum.maxdailytransmissionsmsrtutype.four',
      '4'
    ),
    [MaxDailyTransmissionSmsRtuType.Six]: t(
      'enum.maxdailytransmissionsmsrtutype.six',
      '6'
    ),
    [MaxDailyTransmissionSmsRtuType.Eight]: t(
      'enum.maxdailytransmissionsmsrtutype.eight',
      '8'
    ),
    [MaxDailyTransmissionSmsRtuType.Ten]: t(
      'enum.maxdailytransmissionsmsrtutype.ten',
      '10'
    ),
    [MaxDailyTransmissionSmsRtuType.Twelve]: t(
      'enum.maxdailytransmissionsmsrtutype.twelve',
      '12'
    ),
    [MaxDailyTransmissionSmsRtuType.Fourteen]: t(
      'enum.maxdailytransmissionsmsrtutype.fourteen',
      '14'
    ),
  };
};
export const buildSensorLoopVoltageTypeTextMapping = (t: TFunction) => {
  return {
    [SensorLoopVoltageType.FifteenVolts]: t(
      'enum.sensorloopvoltagetype.fifteenvolts',
      '15 Volts'
    ),
    [SensorLoopVoltageType.TwentyThreeVolts]: t(
      'enum.sensorloopvoltagetype.twentythreevolts',
      '23 Volts'
    ),
  };
};
export const validScalingModeTypes = [
  ScalingModeType.Linear,
  ScalingModeType.Prescaled,
];

export const validScalingModeTypesForSensorCalibration = [
  ScalingModeType.Linear,
  ScalingModeType.Mapped,
  ScalingModeType.Prescaled,
];

export const allZoomRangeOptions = [
  ReadingsChartZoomLevel.NotSet,
  ReadingsChartZoomLevel.TwoYears,
  ReadingsChartZoomLevel.OneYear,
  ReadingsChartZoomLevel.TwelveWeeks,
  ReadingsChartZoomLevel.FourWeeks,
  ReadingsChartZoomLevel.TwoWeeks,
  ReadingsChartZoomLevel.OneWeek,
  ReadingsChartZoomLevel.FourDays,
  ReadingsChartZoomLevel.TwoDays,
  ReadingsChartZoomLevel.OneDay,
  ReadingsChartZoomLevel.TwelveHours,
  ReadingsChartZoomLevel.SixHours,
  ReadingsChartZoomLevel.ThreeHour,
];
export const nonCustomZoomRangeOptions = allZoomRangeOptions.filter(
  (option) => option !== ReadingsChartZoomLevel.NotSet
);

const supportedAssetTypes = [AssetType.Tank, AssetType.HeliumIsoContainer];

const operatorTypes = [
  VirtualChannelOperatorType.Addition,
  VirtualChannelOperatorType.Subtraction,
  VirtualChannelOperatorType.Multiplication,
  VirtualChannelOperatorType.Division,
  VirtualChannelOperatorType.And,
  VirtualChannelOperatorType.Or,
  VirtualChannelOperatorType.Expoentiation,
];

const pollScheduleTypes = [
  RTUPollScheduleType.Interval,
  RTUPollScheduleType.PointInTime,
];

const simplifiedVolumetricUnits = [
  UnitType.Liters,
  UnitType.KiloLiters,
  UnitType.CubicMeters,
  UnitType.USGallons,
  UnitType.UKGallons,
  UnitType.CubicInches,
  UnitType.CubicFeet,
  UnitType.CubicYards,
  UnitType.Kilograms,
  UnitType.MetricTonne,
  UnitType.Pounds,
  UnitType.TonUS,
  UnitType.StandardCubicMeter,
  UnitType.StandardCubicFoot,
  UnitType.PercentFull,
];

const volumetricDisplayUnits = [
  UnitType.WaterColumnMillimeters,
  UnitType.WaterColumnCentimeters,
  UnitType.WaterColumnMeters,
  UnitType.HydrogenMillimeters,
  UnitType.HydrogenCentimeters,
  UnitType.HydrogenMeters,
  UnitType.WaterColumnInches,
  UnitType.HydrogenInches,
  UnitType.Millimeters,
  UnitType.Centimeters,
  UnitType.Meters,
  UnitType.Inches,
  UnitType.Feet,
  UnitType.Yards,
  UnitType.Liters,
  UnitType.KiloLiters,
  UnitType.CubicMeters,
  // Default seems to no longer be used
  // UnitType.Default,
  UnitType.USGallons,
  UnitType.UKGallons,
  UnitType.CubicInches,
  UnitType.CubicFeet,
  UnitType.CubicYards,
  UnitType.Kilograms,
  UnitType.MetricTonne,
  UnitType.Pounds,
  UnitType.TonUS,
  UnitType.StandardCubicMeter,
  UnitType.StandardCubicFoot,
  UnitType.PercentFull,
];

const unitsOfMeasure = [
  UnitType.Centimeters,
  UnitType.Feet,
  UnitType.Inches,
  UnitType.Meters,
  UnitType.Millimeters,
  UnitType.Yards,
];

const unitsOfVolume = [
  UnitType.CubicFeet,
  UnitType.UKGallons,
  UnitType.USGallons,
  UnitType.CubicInches,
  UnitType.KiloLiters,
  UnitType.Liters,
  UnitType.CubicMeters,
  UnitType.CubicYards,
];

const unitsOfVolumeAndMass = [
  UnitType.Liters,
  UnitType.KiloLiters,
  UnitType.CubicMeters,
  UnitType.USGallons,
  UnitType.UKGallons,
  UnitType.CubicInches,
  UnitType.CubicFeet,
  UnitType.CubicYards,
  UnitType.Kilograms,
  UnitType.MetricTonne,
  UnitType.Pounds,
  UnitType.TonUS,
  UnitType.StandardCubicMeter,
  UnitType.StandardCubicFoot,
];

export enum UnitOfMeasureCategory {
  Volume = 'volume',
  Mass = 'mass',
  Distance = 'distance',
  Other = 'other',
}

export const buildUnitOfMeasureCategoryMapping = (t: TFunction) => {
  return {
    [UnitOfMeasureCategory.Volume]: t(
      'enum.unitOfMeasureCategory.volume',
      'Volume'
    ),
    [UnitOfMeasureCategory.Mass]: t('enum.unitOfMeasureCategory.mass', 'Mass'),
    [UnitOfMeasureCategory.Distance]: t(
      'enum.unitOfMeasureCategory.distance',
      'Distance'
    ),
    [UnitOfMeasureCategory.Other]: t(
      'enum.unitOfMeasureCategory.other',
      'Other'
    ),
  };
};

const unitToCategoryMapping: Record<UnitType, UnitOfMeasureCategory> = {
  [UnitType.WaterColumnMillimeters]: UnitOfMeasureCategory.Distance,
  [UnitType.WaterColumnCentimeters]: UnitOfMeasureCategory.Distance,
  [UnitType.WaterColumnMeters]: UnitOfMeasureCategory.Distance,
  [UnitType.HydrogenMillimeters]: UnitOfMeasureCategory.Distance,
  [UnitType.HydrogenCentimeters]: UnitOfMeasureCategory.Distance,
  [UnitType.HydrogenMeters]: UnitOfMeasureCategory.Distance,
  [UnitType.WaterColumnInches]: UnitOfMeasureCategory.Distance,
  [UnitType.HydrogenInches]: UnitOfMeasureCategory.Distance,
  [UnitType.Millimeters]: UnitOfMeasureCategory.Distance,
  [UnitType.Centimeters]: UnitOfMeasureCategory.Distance,
  [UnitType.Meters]: UnitOfMeasureCategory.Distance,
  [UnitType.Inches]: UnitOfMeasureCategory.Distance,
  [UnitType.Feet]: UnitOfMeasureCategory.Distance,
  [UnitType.Yards]: UnitOfMeasureCategory.Distance,
  [UnitType.Liters]: UnitOfMeasureCategory.Volume,
  [UnitType.KiloLiters]: UnitOfMeasureCategory.Volume,
  [UnitType.CubicMeters]: UnitOfMeasureCategory.Volume,
  [UnitType.Default]: UnitOfMeasureCategory.Other,
  [UnitType.USGallons]: UnitOfMeasureCategory.Volume,
  [UnitType.UKGallons]: UnitOfMeasureCategory.Volume,
  [UnitType.CubicInches]: UnitOfMeasureCategory.Volume,
  [UnitType.CubicFeet]: UnitOfMeasureCategory.Volume,
  [UnitType.CubicYards]: UnitOfMeasureCategory.Volume,
  [UnitType.Kilograms]: UnitOfMeasureCategory.Mass,
  [UnitType.MetricTonne]: UnitOfMeasureCategory.Mass,
  [UnitType.Pounds]: UnitOfMeasureCategory.Mass,
  [UnitType.TonUS]: UnitOfMeasureCategory.Mass,
  [UnitType.StandardCubicMeter]: UnitOfMeasureCategory.Volume,
  [UnitType.StandardCubicFoot]: UnitOfMeasureCategory.Volume,
  [UnitType.PercentFull]: UnitOfMeasureCategory.Other,
};

export const buildFreezerAssetSubTypeMapping = (
  t: TFunction
): Record<AssetSubTypeEnum, string> => {
  return {
    [AssetSubTypeEnum.CompactSpiral]: t(
      'enum.assetSubType.compactSpiral',
      'Compact Spiral'
    ),
    [AssetSubTypeEnum.SuperContact]: t(
      'enum.assetSubType.superContact',
      'Super Contact'
    ),
    [AssetSubTypeEnum.Cryowave]: t('enum.assetSubType.cryowave', 'Cryowave'),
    [AssetSubTypeEnum.ModularTunnel]: t(
      'enum.assetSubType.modularTunnel',
      'Modular Tunnel'
    ),
  };
};

// TODO: The code below could instead start with the filtered array, instead of
// getting all units of measure, then filtering using the filtered array
// todo: model related stuff should not be in utils, and should live on model or beside it instead
export const getSimplifiedVolumetricUnits = (t: TFunction) => {
  const unitsOfMeasureMapping = buildUnitsOfMeasureTextMapping(t);
  return simplifiedVolumetricUnits
    .filter((unit) => !!unitsOfMeasureMapping[unit])
    .map((unit) => ({
      label: unitsOfMeasureMapping[unit],
      value: unit,
      type: unitToCategoryMapping[unit],
    }));
};

export const getVolumetricDisplayUnits = (t: TFunction) => {
  const unitsOfMeasureMapping = buildUnitsOfMeasureTextMapping(t);
  return volumetricDisplayUnits
    .filter((unit) => !!unitsOfMeasureMapping[unit])
    .map((unit) => ({
      label: unitsOfMeasureMapping[unit],
      value: unit,
      type: unitToCategoryMapping[unit],
    }));
};

export const getUnitTypeOptions = (
  t: TFunction,
  unitTypes?: UnitType[] | null
) => {
  const unitsOfMeasureMapping = buildUnitsOfMeasureTextMapping(t);
  return (
    Object.keys(unitsOfMeasureMapping)
      .filter((unit) =>
        unitTypes ? unitTypes.includes(Number(unit) as UnitType) : true
      )
      // @ts-ignore
      .map((unit) => ({ label: unitsOfMeasureMapping[unit], value: unit }))
      .sort((a, b) => a.label.localeCompare(b.label))
  );
};

export const getTankDimensionTypeOptions = (t: TFunction) => {
  const tankTypeMapping = buildTankTypeTextMapping(t);
  return (
    Object.keys(tankTypeMapping)
      .filter((type) => Number(type) !== TankType.TotalizedTank)
      // @ts-ignore
      .map((type) => ({ label: tankTypeMapping[type], value: Number(type) }))
      .sort((a, b) => a.label.localeCompare(b.label))
  );
};

export const getTankDimensionUnitsOfMeasureOptions = (t: TFunction) => {
  const unitsOfMeasureMapping = buildUnitsOfMeasureTextMapping(t);
  return (
    Object.keys(unitsOfMeasureMapping)
      .filter((unit) => unitsOfMeasure.includes(Number(unit) as UnitType))
      // @ts-ignore
      .map((unit) => ({ label: unitsOfMeasureMapping[unit], value: unit }))
      .sort((a, b) => a.label.localeCompare(b.label))
  );
};

export const getUnitsOfVolumeOptions = (t: TFunction) => {
  const unitsOfMeasureMapping = buildUnitsOfMeasureTextMapping(t);
  return unitsOfVolume
    .map((unit) => ({
      label: unitsOfMeasureMapping[unit],
      value: unit,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const buildRTUCategoryTypeTextMapping = (t: TFunction) => {
  return {
    [RTUCategoryType.Unknown]: t('enum.rtucategorytype.unknown', 'Unknown'),
    [RTUCategoryType.SMS]: t('enum.rtucategorytype.sms', 'SMS'),
    [RTUCategoryType.Modbus]: t('enum.rtucategorytype.modbus', 'Modbus'),
    [RTUCategoryType.Clover]: t('enum.rtucategorytype.clover', 'Clover'),
    [RTUCategoryType.Metron2]: t('enum.rtucategorytype.metron', 'Metron 2'),
    [RTUCategoryType.Horner]: t('enum.rtucategorytype.horner', 'Horner'),
    [RTUCategoryType.File]: t('enum.rtucategorytype.file', 'File'),
    [RTUCategoryType.FourHundredSeries]: t(
      'enum.rtucategorytype.FourHundredSeries',
      '400 Series'
    ),
    [RTUCategoryType.Utm]: t('enum.rtucategorytype.utm', 'Utm'),
    [RTUCategoryType.Rtm]: t('enum.rtucategorytype.rtm', 'Rtm'),
  };
};

export const buildRTUCommunicationHourlyMaskTextMapping = (t: TFunction) => {
  return {
    [UtmRtuCommunicationHourlyMaskType.NotSet]: t(
      'enum.rtuutmcommunicationmask.notset',
      'Not Set'
    ),
    [UtmRtuCommunicationHourlyMaskType.One]: t(
      'enum.rtuutmcommunicationmask.one',
      '1'
    ),
    [UtmRtuCommunicationHourlyMaskType.Two]: t(
      'enum.rtuutmcommunicationmask.two',
      '2'
    ),
    [UtmRtuCommunicationHourlyMaskType.Three]: t(
      'enum.rtuutmcommunicationmask.three',
      '3'
    ),
    [UtmRtuCommunicationHourlyMaskType.Four]: t(
      'enum.rtuutmcommunicationmask.four',
      '4'
    ),
    [UtmRtuCommunicationHourlyMaskType.Six]: t(
      'enum.rtuutmcommunicationmask.six',
      '6'
    ),
    [UtmRtuCommunicationHourlyMaskType.Eight]: t(
      'enum.rtuutmcommunicationmask.eight',
      '8'
    ),
    [UtmRtuCommunicationHourlyMaskType.Twelve]: t(
      'enum.rtuutmcommunicationmask.twelve',
      '12'
    ),
  };
};
export const buildRTUTypeTextMapping = (t: TFunction) => {
  return {
    [RTUType.Compak]: t('enum.rtutype.compak', 'Compak'),
    [RTUType.Infact]: t('enum.rtutype.infact', 'Infact'),
    [RTUType.GGPlant]: t('enum.rtutype.ggplant', 'GG Plant'),
    [RTUType.Demo4Channel]: t('enum.rtutype.demo4channel', 'Demo 4 Channel'),
    [RTUType.FA]: t('enum.rtutype.fa', 'Class 1'),
    [RTUType.FakeWired4Channel]: t(
      'enum.rtutype.fakewired4channel',
      'Fake Wired 4 Channel'
    ),
    [RTUType.FE]: t('enum.rtutype.fe', 'FE'),
    [RTUType.FE0]: t('enum.rtutype.fe0', 'FE0'),
    [RTUType.FF1]: t('enum.rtutype.ff1', 'FF1'),
    [RTUType.FF4]: t('enum.rtutype.ff4', 'FF4'),
    [RTUType.FF5]: t('enum.rtutype.ff5', 'FF5'),
    [RTUType.FF6]: t('enum.rtutype.ff6', 'FF6'),
    [RTUType.FF70]: t('enum.rtutype.ff70', 'FF70'),
    [RTUType.FF71]: t('enum.rtutype.ff71', 'FF71'),
    [RTUType.FF72]: t('enum.rtutype.ff72', 'FF72'),
    [RTUType.FF74]: t('enum.rtutype.ff74', 'FF74'),
    [RTUType.FF75]: t('enum.rtutype.ff75', 'FF75'),
    [RTUType.FF78]: t('enum.rtutype.ff78', 'FF78'),
    [RTUType.FF79]: t('enum.rtutype.ff79', 'FF79'),
    [RTUType.FF7A]: t('enum.rtutype.ff7a', 'FF7A'),
    [RTUType.FF7B]: t('enum.rtutype.ff7b', 'FF7B'),
    [RTUType.FF7D]: t('enum.rtutype.ff7d', 'FF7D'),
    [RTUType.FF7E]: t('enum.rtutype.ff7e', 'FF7E'),
    [RTUType.FF7F]: t('enum.rtutype.ff7f', 'FF7F'),
    [RTUType.FF8]: t('enum.rtutype.ff8', 'FF8'),
    [RTUType.FF9]: t('enum.rtutype.ff9', 'FF9'),
    [RTUType.FFA]: t('enum.rtutype.ffa', 'FFA'),
    [RTUType.FFB]: t('enum.rtutype.ffb', 'FFB'),
    [RTUType.FFD]: t('enum.rtutype.ffd', 'FFD'),
    [RTUType.FFE]: t('enum.rtutype.ffe', 'FFE'),
    [RTUType.File]: t('enum.rtutype.file', 'File'),
    [RTUType.Horner]: t('enum.rtutype.horner', 'Horner'),
    [RTUType.Metron2]: t('enum.rtutype.metron2', 'Metron 2'),
    [RTUType.None]: t('enum.rtutype.none', 'None'),
    [RTUType.TrippleHash]: t('enum.rtutype.tripplehash', 'Tripple Hash'),
    [RTUType.DP489]: t('enum.rtutype.dp489', 'DP489'),
    [RTUType.BC474]: t('enum.rtutype.bc474', 'BC474'),
    [RTUType.FF73]: t('enum.rtutype.ff73', 'FF73'),
    [RTUType.LC490]: t('enum.rtutype.lc490', 'LC490'),
    [RTUType.GU476]: t('enum.rtutype.gu476', 'GU476'),
    [RTUType.FF7C]: t('enum.rtutype.ff7c', 'FF7C'),
    [RTUType.FF17]: t('enum.rtutype.ff17', 'FF17'),
    [RTUType.EaglePaymeter]: t('enum.rtutype.eaglepaymeter', 'Eagle Paymeter'),
    [RTUType.KT09]: t('enum.rtutype.kt09', 'KT09'),
    [RTUType.EG501]: t('enum.rtutype.eg501', 'EG501'),
  };
};

export const buildDeviceOperationalStateTextMapping = (t: TFunction) => {
  return {
    [DeviceOperationalState.Unknown]: t(
      'enum.devicestatetype.unknown',
      'Unknown'
    ),
    [DeviceOperationalState.Transport]: t(
      'enum.devicestatetype.transport',
      'Transport'
    ),
    [DeviceOperationalState.Production]: t(
      'enum.devicestatetype.production',
      'Production'
    ),
    [DeviceOperationalState.Operational]: t(
      'enum.devicestatetype.operational',
      'Operational'
    ),
    [DeviceOperationalState.Decommissioned]: t(
      'enum.devicestatetype.decommissioned',
      'Decommissioned'
    ),
  };
};

export const buildFastPollingErrorTextMapping = (t: TFunction) => {
  return {
    [DolErrorCode.Success]: t('enum.dolerrortype.Success', 'Success'),
    [DolErrorCode.AlreadyActive]: t(
      'enum.dolerrortype.AlreadyActive',
      'Already Active'
    ),
    [DolErrorCode.ConcurrencyError]: t(
      'enum.dolerrortype.ConcurrencyError',
      'Concurrency Error'
    ),
    [DolErrorCode.ConfigurationError]: t(
      'enum.dolerrortype.ConfigurationError',
      'Configuration Error'
    ),
    [DolErrorCode.ConnectionTerminatedError]: t(
      'enum.dolerrortype.connectionterminatederror',
      'Connection Terminated'
    ),
    [DolErrorCode.DatabaseAccessError]: t(
      'enum.dolerrortype.DatabaseAccessError',
      'Database Access Error'
    ),
    [DolErrorCode.DatabaseUpdateError]: t(
      'enum.dolerrortype.DatabaseUpdateError',
      'Database Update Error'
    ),
    [DolErrorCode.DeviceIdMismatchError]: t(
      'enum.dolerrortype.DeviceIdMismatchError',
      'DeviceId Mismatch Error'
    ),
    [DolErrorCode.DeviceIdNotFoundError]: t(
      'enum.dolerrortype.DeviceIdNotFoundError',
      'DeviceId Not Found Error'
    ),
    [DolErrorCode.DevicePhoneLineIsBusyError]: t(
      'enum.dolerrortype.DevicePhoneLineIsBusyError',
      'Device Phone Line Is Busy Error'
    ),
    [DolErrorCode.DuplicateError]: t(
      'enum.dolerrortype.DuplicateError',
      'Duplicate Error'
    ),
    [DolErrorCode.SystemError]: t(
      'enum.dolerrortype.SystemError',
      'System Error'
    ),
    [DolErrorCode.EmptyError]: t('enum.dolerrortype.EmptyError', 'Empty Error'),
    [DolErrorCode.FunctionNotSupported]: t(
      'enum.dolerrortype.FunctionNotSupported',
      'Function Not Supported'
    ),
    [DolErrorCode.GeneralError]: t(
      'enum.dolerrortype.GeneralError',
      'General Error'
    ),
    [DolErrorCode.ModemError]: t('enum.dolerrortype.ModemError', 'Modem Error'),
    [DolErrorCode.NoAnswerError]: t(
      'enum.dolerrortype.NoAnswerError',
      'No Answer Error'
    ),
    [DolErrorCode.NoDialtoneError]: t(
      'enum.dolerrortype.NoDialtoneError',
      'No Dialtone Error'
    ),

    [DolErrorCode.NoOutboundPhoneLinesAvailableError]: t(
      'enum.dolerrortype.NoOutboundPhoneLinesAvailableError',
      'No Outbound Phone Lines Available Error'
    ),
    [DolErrorCode.NotFoundError]: t(
      'enum.dolerrortype.NotFoundError',
      'Not Found Error'
    ),

    [DolErrorCode.NotReadyError]: t(
      'enum.dolerrortype.NotReadyError',
      'Not Ready Error'
    ),
    [DolErrorCode.OpenError]: t('enum.dolerrortype.OpenError', 'Open Error'),
    [DolErrorCode.ReferrentialIntegrityError]: t(
      'enum.dolerrortype.ReferrentialIntegrityError',
      'Referrential Integrity Error'
    ),
    [DolErrorCode.RetriesExceededError]: t(
      'enum.dolerrortype.RetriesExceededError',
      'Retries Exceeded Error'
    ),
    [DolErrorCode.SecurityAccessDeniedError]: t(
      'enum.dolerrortype.SecurityAccessDeniedError',
      'Security Access Denied Error'
    ),
    [DolErrorCode.SecurityModuleError]: t(
      'enum.dolerrortype.SecurityModuleError',
      'Security Module Error'
    ),
    [DolErrorCode.SecurityOperationDeniedError]: t(
      'enum.dolerrortype.SecurityOperationDeniedError',
      'Security Operation Denied Error'
    ),
    [DolErrorCode.UserCancelError]: t(
      'enum.dolerrortype.UserCancelError',
      'User Cancel Error'
    ),
    [DolErrorCode.ValidationError]: t(
      'enum.dolerrortype.ValidationError',
      'Validation Error'
    ),
    [DolErrorCode.AccessDeniedModbusError]: t(
      'enum.dolerrortype.AccessDeniedModbusError',
      'Access Denied Modbus Error'
    ),
    [DolErrorCode.UnsupportFunctionModbusError]: t(
      'enum.dolerrortype.UnsupportFunctionModbusError',
      'Unsupport Function Modbus Error'
    ),
    [DolErrorCode.RS485TimeoutModbusError]: t(
      'enum.dolerrortype.RS485TimeoutModbusError',
      'RS485 Timeout Modbus Error'
    ),
    [DolErrorCode.ReadBackMismatchModbusError]: t(
      'enum.dolerrortype.ReadBackMismatchModbusError',
      'Read Back Mismatch Modbus Error'
    ),
    [DolErrorCode.QueueFullModbusError]: t(
      'enum.dolerrortype.QueueFullModbusError',
      'Queue Full Modbus Error'
    ),
    [DolErrorCode.OutOfSyncResponseModbusError]: t(
      'enum.dolerrortype.OutOfSyncResponseModbusError',
      'Out Of Sync Response Modbus Error'
    ),
    [DolErrorCode.OutOfMemoryModbusError]: t(
      'enum.dolerrortype.OutOfMemoryModbusError',
      'Out Of Memory Modbus Error'
    ),
    [DolErrorCode.NoDeviceIdModbusError]: t(
      'enum.dolerrortype.NoDeviceIdModbusError',
      'No Device Id Modbus Error'
    ),
    [DolErrorCode.ModuleIoErrorModbusError]: t(
      'enum.dolerrortype.ModuleIoErrorModbusError',
      'ModuleIo Error Modbus Error'
    ),
    [DolErrorCode.InvalidResponseLengthModbusError]: t(
      'enum.dolerrortype.InvalidResponseLengthModbusError',
      'Invalid Response Length Modbus Error'
    ),
    [DolErrorCode.InvalidRegisterModbusError]: t(
      'enum.dolerrortype.InvalidRegisterModbusError',
      'Invalid Register Modbus Error'
    ),
    [DolErrorCode.InvalidDataModbusError]: t(
      'enum.dolerrortype.InvalidDataModbusError',
      'Invalid Data Modbus Error'
    ),
    [DolErrorCode.InvalidDataLengthModbusError]: t(
      'enum.dolerrortype.InvalidDataLengthModbusError',
      'Invalid Data Length Modbus Error'
    ),
    [DolErrorCode.ChannelNotFoundModbusError]: t(
      'enum.dolerrortype.ChannelNotFoundModbusError',
      'Channel Not Found Modbus Error'
    ),
    [DolErrorCode.BadCrcModbusError]: t(
      'enum.dolerrortype.BadCrcModbusError',
      'Bad Crc Modbus Error'
    ),
    [DolErrorCode.ReadBackMismatchCloverError]: t(
      'enum.dolerrortype.ReadBackMismatchCloverError',
      'Read Back Mismatch Clover Error'
    ),
    [DolErrorCode.OutOfSyncCloverError]: t(
      'enum.dolerrortype.OutOfSyncCloverError',
      'Out Of Sync Clover Error'
    ),
    [DolErrorCode.InvalidFormatCloverError]: t(
      'enum.dolerrortype.InvalidFormatCloverError',
      'Invalid Format Clover Error'
    ),
    [DolErrorCode.InvalidChecksumCloverError]: t(
      'enum.dolerrortype.InvalidChecksumCloverError',
      'Invalid Checksum Clover Error'
    ),
    [DolErrorCode.UnsupportFunctionHornerError]: t(
      'enum.dolerrortype.UnsupportFunctionHornerError',
      'Unsupport Function Horner Error'
    ),
    [DolErrorCode.ReadBackMismatchHornerError]: t(
      'enum.dolerrortype.ReadBackMismatchHornerError',
      'Read Back Mismatch Horne Error'
    ),
    [DolErrorCode.OutOfSyncResponseHornerError]: t(
      'enum.dolerrortype.OutOfSyncResponseHornerError',
      'Out Of Sync Response Horner Error'
    ),
    [DolErrorCode.InvalidResponseLengthHornerError]: t(
      'enum.dolerrortype.InvalidResponseLengthHornerError',
      'Invalid Response Length Horner Error'
    ),
    [DolErrorCode.InvalidRegisterHornerError]: t(
      'enum.dolerrortype.InvalidRegisterHornerError',
      'Invalid Register Horner Error'
    ),
    [DolErrorCode.InvalidDataLengthHornerError]: t(
      'enum.dolerrortype.InvalidDataLengthHornerError',
      'Invalid Data Length Horner Error'
    ),
    [DolErrorCode.InvalidDataHornerError]: t(
      'enum.dolerrortype.InvalidDataHornerError',
      'Invalid Data Horner Error'
    ),
    [DolErrorCode.TransmissionErrorMetron2Error]: t(
      'enum.dolerrortype.TransmissionErrorMetron2Error',
      'Transmission Error Metron2 Error'
    ),
    [DolErrorCode.BadCrcMetron2Error]: t(
      'enum.dolerrortype.BadCrcMetron2Error',
      'Bad Crc Metron2 Error'
    ),
    [DolErrorCode.MessageRejectedMetron2Error]: t(
      'enum.dolerrortype.MessageRejectedMetron2Error',
      'Message Rejected Metron2 Error'
    ),
    [DolErrorCode.InvalidBulkMessageMetron2Error]: t(
      'enum.dolerrortype.InvalidBulkMessageMetron2Error',
      'Invalid Bulk Message Metron2 Error'
    ),
    [DolErrorCode.Error]: t('enum.dolerrortype.Error', 'Error'),
    [DolErrorCode.AlreadyInuseByUserError]: t(
      'enum.dolerrortype.AlreadyInuseByUserError',
      'Already Inuse By User Error'
    ),
    [DolErrorCode.ActiveCallError]: t(
      'enum.dolerrortype.ActiveCallError',
      'Active Call Error'
    ),
    [DolErrorCode.InvalidFormatKt09Error]: t(
      'enum.dolerrortype.InvalidFormatKt09Error',
      'Invalid Format Kt09 Error'
    ),
  };
};

export const buildDeviceOperationalStateRtmTextMapping = (t: TFunction) => {
  return {
    [DevicePlatformState.EndOfLife]: t(
      'enum.devicestatetype.EndOfLife',
      'End Of Life'
    ),
    [DevicePlatformState.Active]: t('enum.devicestatetype.Active', 'Active'),
    [DevicePlatformState.Transport]: t(
      'enum.devicestatetype.transport',
      'Transport'
    ),

    [DevicePlatformState.WaitingDecommissioning]: t(
      'enum.devicestatetype.WaitingDecommissioning',
      'Waiting Decommissioning'
    ),
    [DevicePlatformState.Decommissioned]: t(
      'enum.devicestatetype.decommissioned',
      'Decommissioned'
    ),
    [DevicePlatformState.WaitingActivation]: t(
      'enum.devicestatetype.WaitingActivation',
      'Waiting Activation'
    ),
    [DevicePlatformState.WaitingTransport]: t(
      'enum.devicestatetype.WaitingTransport',
      'Waiting Transport'
    ),
    [DevicePlatformState.Testing]: t('enum.devicestatetype.Testing', 'Testing'),
  };
};

export const buildRtuUTMdescription = (t: TFunction) => {
  return {
    SC415A655EH2A: t(
      'enum.rtutype.decriptionSC415A655EH2A',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Fusion Global SIM, no mount'
    ),
    SC415A655EH3A: t(
      'enum.rtutype.decriptionSC415A655EH3A',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Fusion Global SIM, PCB v3, no mount'
    ),

    SC415A665EH2A: t(
      'enum.rtutype.decriptionSC415A665EH2A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Fusion Global SIM, no mount'
    ),
    SC415A665EH3A: t(
      'enum.rtutype.decriptionSC415A665EH3A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Fusion Global SIM, PCB v3, no mount'
    ),

    SC415B655EH2A: t(
      'enum.rtutype.decriptionSC415B655EH2A',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Fusion Global SIM, no mount'
    ),
    SC415B655EH3A: t(
      'enum.rtutype.decriptionSC415B655EH3A',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Fusion Global SIM, PCB v3, no mount'
    ),

    SC415B665EH2A: t(
      'enum.rtutype.decriptionSC415B665EH2A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Fusion Global SIM, no mount'
    ),
    SC415B665EG2A: t(
      'enum.rtutype.decriptionSC415B665EG2A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Orange SIM, no mount'
    ),
    SC415B665EE2A: t(
      'enum.rtutype.decriptionSC415B665EE2A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Telecom Italia SIM, no mount'
    ),
    SC415B665EH3A: t(
      'enum.rtutype.decriptionSC415B665EH3A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Fusion Global SIM, PCB v3, no mount'
    ),
    SC415B665EG3A: t(
      'enum.rtutype.decriptionSC415B665EG3A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Orange SIM, PCB v3, no mount'
    ),
    SC415B665EE3A: t(
      'enum.rtutype.decriptionSC415B665EE3A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Telecom Italia SIM, PCB v3, no mount'
    ),

    SC415C655EC2A: t(
      'enum.rtutype.decriptionSC415C655EC2A',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Fusion NA SIM, no mount'
    ),
    SC415C655EC2C: t(
      'enum.rtutype.decriptionSC415C655EC2C',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Fusion NA SIM, magnet mount'
    ),
    SC415C655EL2A: t(
      'enum.rtutype.decriptionSC415C655EL2A',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Universal SIM, no mount'
    ),
    SC415C655EL2C: t(
      'enum.rtutype.decriptionSC415C655EL2C',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Universal SIM, magnet mount'
    ),
    SC415C655EC3A: t(
      'enum.rtutype.decriptionSC415C655EC3A',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Fusion NA SIM, PCB v3, no mount'
    ),
    SC415C655EC3C: t(
      'enum.rtutype.decriptionSC415C655EC3C',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Fusion NA SIM, PCB v3, magnet mount'
    ),
    SC415C655EL3A: t(
      'enum.rtutype.decriptionSC415C655EL3A',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Universal SIM, PCB v3, no mount'
    ),
    SC415C655EL3C: t(
      'enum.rtutype.decriptionSC415C655EL3C',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Universal SIM, PCB v3, magnet mount'
    ),
    SC415C665EC2A: t(
      'enum.rtutype.decriptionSC415C665EC2A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Fusion NA SIM, no mount'
    ),
    SC415C665EC2C: t(
      'enum.rtutype.decriptionSC415C665EC2C',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Fusion NA SIM, magnet mount'
    ),
    SC415C665EL2A: t(
      'enum.rtutype.decriptionSC415C665EL2A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Universal SIM, no mount'
    ),
    SC415C665EL2C: t(
      'enum.rtutype.decriptionSC415C665EL2C',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Universal SIM, magnet mount'
    ),
    SC415C665EC3A: t(
      'enum.rtutype.decriptionSC415C665EC3A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Fusion NA SIM, PCB v3, no mount'
    ),
    SC415C665EC3C: t(
      'enum.rtutype.decriptionSC415C665EC3C',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Fusion NA SIM, PCB v3, magnet mount'
    ),
    SC415C665EL3A: t(
      'enum.rtutype.decriptionSC415C665EL3A',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Universal SIM, PCB v3, no mount'
    ),
    SC415C665EL3C: t(
      'enum.rtutype.decriptionSC415C665EL3C',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Universal SIM, PCB v3, magnet mount'
    ),

    SC415C675EC2A: t(
      'enum.rtutype.decriptionSC415C675EC2A',
      'LTE Cat-M1 Cellular Dialer incl. GPS, NA, Fusion NA, no mount'
    ),
    SC415C675EC2C: t(
      'enum.rtutype.decriptionSC415C675EC2C',
      'LTE Cat-M1 Cellular Dialer incl. GPS, NA, Fusion NA, magnetic mount'
    ),
    RadarSC415C655: t('enum.rtutype.decriptionRadarSC415C655', '-'),
    SC415C655EL3D: t(
      'enum.rtutype.descriptionSC415C655EL3D',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Universal SIM, PCB v3, no bracket'
    ),

    SC415C655EL2D: t(
      'enum.rtutype.decriptionSC415C655EL2D',
      'LTE Cat-M1 Cellular Dialer incl. GPS, Universal SIM, PCB v2, no bracket'
    ),

    SC415A665EB3B: t(
      'enum.rtutype.decriptionSC415A665EB3B',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Aeris AT&T SIM, PCB v3, 3M-DL mount'
    ),
    SC415C665EB3B: t(
      'enum.rtutype.decriptionSC415C665EB3B',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Aeris AT&T SIM, PCB v3, 3M-DL mount'
    ),
    SC415A665ED3B: t(
      'enum.rtutype.decriptionSC415A665ED3B',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Telstra SIM, PCB v3, 3M-DL mount'
    ),
    SC415C665EL3D: t(
      'enum.rtutype.descriptionSC415C665EL3D',
      'LTE Cat-M1 Cellular Dialer excl. GPS, Universal SIM, PCB v3, no bracket'
    ),
  };
};

export const buildRtuDeviceTypeTextMapping = (t: TFunction) => {
  return {
    [RtuDeviceType.Compak]: t('enum.rtutype.compak', 'Compak'),
    [RtuDeviceType.Infact]: t('enum.rtutype.infact', 'Infact'),
    [RtuDeviceType.GGPlant]: t('enum.rtutype.ggplant', 'GG Plant'),
    [RtuDeviceType.Demo4Channel]: t(
      'enum.rtutype.demo4channel',
      'Demo 4 Channel'
    ),
    [RtuDeviceType.FA]: t('enum.rtutype.fa', 'Class 1'),
    [RtuDeviceType.FakeWired4Channel]: t(
      'enum.rtutype.fakewired4channel',
      'Fake Wired 4 Channel'
    ),
    [RtuDeviceType.FE]: t('enum.rtutype.fe', 'FE'),
    [RtuDeviceType.FE0]: t('enum.rtutype.fe0', 'FE0'),
    [RtuDeviceType.FF1]: t('enum.rtutype.ff1', 'FF1'),
    [RtuDeviceType.FF4]: t('enum.rtutype.ff4', 'FF4'),
    [RtuDeviceType.FF5]: t('enum.rtutype.ff5', 'FF5'),
    [RtuDeviceType.FF6]: t('enum.rtutype.ff6', 'FF6'),
    [RtuDeviceType.FF70]: t('enum.rtutype.ff70', 'FF70'),
    [RtuDeviceType.FF71]: t('enum.rtutype.ff71', 'FF71'),
    [RtuDeviceType.FF72]: t('enum.rtutype.ff72', 'FF72'),
    [RtuDeviceType.FF74]: t('enum.rtutype.ff74', 'FF74'),
    [RtuDeviceType.FF75]: t('enum.rtutype.ff75', 'FF75'),
    [RtuDeviceType.FF78]: t('enum.rtutype.ff78', 'FF78'),
    [RtuDeviceType.FF79]: t('enum.rtutype.ff79', 'FF79'),
    [RtuDeviceType.FF7A]: t('enum.rtutype.ff7a', 'FF7A'),
    [RtuDeviceType.FF7B]: t('enum.rtutype.ff7b', 'FF7B'),
    [RtuDeviceType.FF7D]: t('enum.rtutype.ff7d', 'FF7D'),
    [RtuDeviceType.FF7E]: t('enum.rtutype.ff7e', 'FF7E'),
    [RtuDeviceType.FF7F]: t('enum.rtutype.ff7f', 'FF7F'),
    [RtuDeviceType.FF8]: t('enum.rtutype.ff8', 'FF8'),
    [RtuDeviceType.FF9]: t('enum.rtutype.ff9', 'FF9'),
    [RtuDeviceType.FFA]: t('enum.rtutype.ffa', 'FFA'),
    [RtuDeviceType.FFB]: t('enum.rtutype.ffb', 'FFB'),
    [RtuDeviceType.FFD]: t('enum.rtutype.ffd', 'FFD'),
    [RtuDeviceType.FFE]: t('enum.rtutype.ffe', 'FFE'),
    [RtuDeviceType.File]: t('enum.rtutype.file', 'File'),
    [RtuDeviceType.Horner]: t('enum.rtutype.horner', 'Horner'),
    [RtuDeviceType.Metron2]: t('enum.rtutype.metron2', 'Metron 2'),
    [RtuDeviceType.None]: t('enum.rtutype.none', 'None'),
    [RtuDeviceType.TrippleHash]: t('enum.rtutype.tripplehash', 'Tripple Hash'),
    [RtuDeviceType.DP489]: t('enum.rtutype.dp489', 'DP489'),
    [RtuDeviceType.BC474]: t('enum.rtutype.bc474', 'BC474'),
    [RtuDeviceType.FF73]: t('enum.rtutype.ff73', 'FF73'),
    [RtuDeviceType.LC490]: t('enum.rtutype.lc490', 'LC490'),
    [RtuDeviceType.GU476]: t('enum.rtutype.gu476', 'GU476'),
    [RtuDeviceType.FF7C]: t('enum.rtutype.ff7c', 'FF7C'),
    [RtuDeviceType.FF17]: t('enum.rtutype.ff17', 'FF17'),
    [RtuDeviceType.EaglePaymeter]: t(
      'enum.rtutype.eaglepaymeter',
      'Eagle Paymeter'
    ),
    [RtuDeviceType.KT09]: t('enum.rtutype.kt09', 'KT09'),
    [RtuDeviceType.EG501]: t('enum.rtutype.eg501', 'EG501'),
    [RtuDeviceType.PR451]: t('enum.rtutype.pr451', 'PR451'),
    [RtuDeviceType.EG504]: t('enum.rtutype.eg504', 'EG504'),

    [RtuDeviceType.SC415A655EH2A]: t(
      'enum.rtutype.SC415A655EH2A',
      'SC415A655EH2A'
    ),
    [RtuDeviceType.SC415A655EH3A]: t(
      'enum.rtutype.SC415A655EH3A',
      'SC415A655EH3A'
    ),

    [RtuDeviceType.SC415A665EH2A]: t(
      'enum.rtutype.SC415A665EH2A',
      'SC415A665EH2A'
    ),
    [RtuDeviceType.SC415A665EH3A]: t(
      'enum.rtutype.SC415A665EH3A',
      'SC415A665EH3A'
    ),

    [RtuDeviceType.SC415B655EH2A]: t(
      'enum.rtutype.SC415B655EH2A',
      'SC415B655EH2A'
    ),
    [RtuDeviceType.SC415B655EH3A]: t(
      'enum.rtutype.SC415B655EH3A',
      'SC415B655EH3A'
    ),

    [RtuDeviceType.SC415B665EH2A]: t(
      'enum.rtutype.SC415B665EH2A',
      'SC415B665EH2A'
    ),
    [RtuDeviceType.SC415B665EG2A]: t(
      'enum.rtutype.SC415B665EG2A',
      'SC415B665EG2A'
    ),
    [RtuDeviceType.SC415B665EE2A]: t(
      'enum.rtutype.SC415B665EE2A',
      'SC415B665EE2A'
    ),
    [RtuDeviceType.SC415B665EH3A]: t(
      'enum.rtutype.SC415B665EH3A',
      'SC415B665EH3A'
    ),
    [RtuDeviceType.SC415B665EG3A]: t(
      'enum.rtutype.SC415B665EG3A',
      'SC415B665EG3A'
    ),
    [RtuDeviceType.SC415B665EE3A]: t(
      'enum.rtutype.SC415B665EE3A',
      'SC415B665EE3A'
    ),

    [RtuDeviceType.SC415C655EC2A]: t(
      'enum.rtutype.SC415C655EC2A',
      'SC415C655EC2A'
    ),
    [RtuDeviceType.SC415C655EC2C]: t(
      'enum.rtutype.SC415C655EC2C',
      'SC415C655EC2C'
    ),
    [RtuDeviceType.SC415C655EL2A]: t(
      'enum.rtutype.SC415C655EL2A',
      'SC415C655EL2A'
    ),
    [RtuDeviceType.SC415C655EL2C]: t(
      'enum.rtutype.SC415C655EL2C',
      'SC415C655EL2C'
    ),
    [RtuDeviceType.SC415C655EC3A]: t(
      'enum.rtutype.SC415C655EC3A',
      'SC415C655EC3A'
    ),
    [RtuDeviceType.SC415C655EC3C]: t(
      'enum.rtutype.SC415C655EC3C',
      'SC415C655EC3C'
    ),
    [RtuDeviceType.SC415C655EL3A]: t(
      'enum.rtutype.SC415C655EL3A',
      'SC415C655EL3A'
    ),
    [RtuDeviceType.SC415C655EL3C]: t(
      'enum.rtutype.SC415C655EL3C',
      'SC415C655EL3C'
    ),
    [RtuDeviceType.SC415C665EC2A]: t(
      'enum.rtutype.SC415C665EC2A',
      'SC415C665EC2A'
    ),
    [RtuDeviceType.SC415C665EC2C]: t(
      'enum.rtutype.SC415C665EC2C',
      'SC415C665EC2C'
    ),
    [RtuDeviceType.SC415C665EL2A]: t(
      'enum.rtutype.SC415C665EL2A',
      'SC415C665EL2A'
    ),
    [RtuDeviceType.SC415C665EL2C]: t(
      'enum.rtutype.SC415C665EL2C',
      'SC415C665EL2C'
    ),
    [RtuDeviceType.SC415C665EC3A]: t(
      'enum.rtutype.SC415C665EC3A',
      'SC415C665EC3A'
    ),
    [RtuDeviceType.SC415C665EC3C]: t(
      'enum.rtutype.SC415C665EC3C',
      'SC415C665EC3C'
    ),
    [RtuDeviceType.SC415C665EL3A]: t(
      'enum.rtutype.SC415C665EL3A',
      'SC415C665EL3A'
    ),
    [RtuDeviceType.SC415C665EL3C]: t(
      'enum.rtutype.SC415C665EL3C',
      'SC415C665EL3C'
    ),

    [RtuDeviceType.SC415C675EC2A]: t(
      'enum.rtutype.SC415C675EC2A',
      'SC415C675EC2A'
    ),
    [RtuDeviceType.SC415C675EC2C]: t(
      'enum.rtutype.SC415C675EC2C',
      'SC415C675EC2C'
    ),
    [RtuDeviceType.RadarSC415C655]: t(
      'enum.rtutype.RadarSC415C655',
      'RadarSC415C655'
    ),
    [RtuDeviceType.DP505]: t('enum.rtutype.DP505', 'DP505'),
    [RtuDeviceType.RTM77_100_078]: t(
      'enum.rtutype.RTM77_100_078',
      '77-100-078'
    ),
    [RtuDeviceType.SC415C655EL3D]: t(
      'enum.rtutype.SC415C655EL3D',
      'SC415C655EL3D'
    ),

    [RtuDeviceType.SC415C655EL2D]: t(
      'enum.rtutype.SC415C655EL2D',
      'SC415C655EL2D'
    ),
    [RtuDeviceType.SC415A665EB3B]: t(
      'enum.rtutype.SC415A665EB3B',
      'SC415A665EB3B'
    ),
    [RtuDeviceType.SC415C665EB3B]: t(
      'enum.rtutype.SC415C665EB3B',
      'SC415C665EB3B'
    ),
    [RtuDeviceType.SC415A665ED3B]: t(
      'enum.rtutype.SC415A665ED3B',
      'SC415A665ED3B'
    ),
    [RtuDeviceType.SC415C665EL3D]: t(
      'enum.rtutype.SC415C665EL3D',
      'SC415C665EL3D'
    ),
  };
};

export const buildRtuProtocolTypeEnumTextMapping = (t: TFunction) => {
  return {
    [RtuProtocolTypeEnum.None]: t('ui.common.none', 'None'),
    [RtuProtocolTypeEnum.ModbusGeneric]: t(
      'enum.modbusprotocoltype.modbusgeneric',
      'Modbus Generic'
    ),
    [RtuProtocolTypeEnum.ModbusApci]: t(
      'enum.modbusprotocoltype.modbusa',
      'Modbus A'
    ),
    [RtuProtocolTypeEnum.ModbusPraxair]: t(
      'enum.modbusprotocoltype.modbusp',
      'Modbus P'
    ),
    [RtuProtocolTypeEnum.CloverGeneric]: t(
      'enum.modbusprotocoltype.clovergeneric',
      'Clover Generic'
    ),
    [RtuProtocolTypeEnum.CloverInfAct]: t(
      'enum.modbusprotocoltype.cloverinfack',
      'Clover InfAck'
    ),
    [RtuProtocolTypeEnum.CloverCompak]: t(
      'enum.modbusprotocoltype.clovercompak',
      'Clover Compak'
    ),
    [RtuProtocolTypeEnum.Unknown]: t(
      'enum.modbusprotocoltype.unknown',
      'Unknown'
    ),
    [RtuProtocolTypeEnum.Mertron2]: t(
      'enum.modbusprotocoltype.metron2',
      'Metron 2'
    ),
    [RtuProtocolTypeEnum.Horner]: t('enum.modbusprotocoltype.horner', 'Horner'),
    [RtuProtocolTypeEnum.KT09]: t('enum.rtuprotocoltype.kt09', 'KT09'),
    [RtuProtocolTypeEnum.Echo]: t('ui.rtuProtocolType.echo', 'Echo'),
  };
};

export const buildRtuTransportTypeEnumTextMapping = (t: TFunction) => {
  return {
    [RTUTransportTypeEnum.None]: t('ui.common.none', 'None'),
    [RTUTransportTypeEnum.Pstn]: t('enum.rtutransporttype.pstn', 'PSTN'),
    [RTUTransportTypeEnum.Tcp]: t('enum.rtutransporttype.tcpip', 'TCP/IP'),
    [RTUTransportTypeEnum.Udp]: t('enum.rtutransporttype.udp', 'UDP'),
  };
};

export const buildRateOfChangeAggregationMode = (t: TFunction) => {
  return {
    [RateOfChangeAggregationMode.None]: t('ui.common.none', 'None'),
    [RateOfChangeAggregationMode.Average]: t(
      'enum.rateofchangeaggregationmode.average',
      'Average'
    ),
    [RateOfChangeAggregationMode.RollingAverage]: t(
      'enum.rateofchangeaggregationmode.rollingaverage',
      'Rolling Average'
    ),
    [RateOfChangeAggregationMode.Sum]: t(
      'enum.rateofchangeaggregationmode.sum',
      'Sum'
    ),
    [RateOfChangeAggregationMode.RollingSum]: t(
      'enum.rateofchangeaggregationmode.rollingsum',
      'Rolling Sum'
    ),
  };
};
export const getRateOfChangeAggregationMode = (t: TFunction) => {
  const textMapping = buildRateOfChangeAggregationMode(t);
  return Object.keys(textMapping).map((type) => ({
    label: textMapping[Number(type) as RateOfChangeAggregationMode],
    value: Number(type) as RateOfChangeAggregationMode,
  }));
};
export const buildPasswordStrengthTextMapping = (t: TFunction) => {
  return {
    [OverallPasswordStrength.None]: t('ui.common.notapplicable', 'N/A'),
    [OverallPasswordStrength.Weak]: t('enum.passwordStrength.weak', 'Weak'),
    [OverallPasswordStrength.Fair]: t('enum.passwordStrength.fair', 'Fair'),
    [OverallPasswordStrength.Good]: t('enum.passwordStrength.good', 'Good'),
    [OverallPasswordStrength.Strong]: t(
      'enum.passwordStrength.strong',
      'Strong'
    ),
    [OverallPasswordStrength.Excellent]: t(
      'enum.passwordStrength.excellent',
      'Excellent'
    ),
  };
};

export const buildRtuDevicePollFilterTextMapping = (t: TFunction) => {
  return {
    [RtuDevicePollFilter.InstantaneousAndHistoricalReadings]: t(
      'enum.pollfiltertype.instantaneousandhistoricalreadings',
      'Instantaneous & Historical Readings'
    ),
    [RtuDevicePollFilter.InstantaneousReadingsOnly]: t(
      'enum.pollfiltertype.instantaneousreadingsonly',
      'Instantaneous Readings Only'
    ),
  };
};

export const getTextForPasswordStrengthLevel = (
  t: TFunction,
  level: OverallPasswordStrength
) => {
  const textMapping = buildPasswordStrengthTextMapping(t);
  return textMapping[level] || textMapping[OverallPasswordStrength.None];
};

export const getUnitDisplayTypeOptions = (t: TFunction) => {
  const textMapping = buildUnitDisplayTypeTextMapping(t);
  return Object.keys(textMapping).map((type) => ({
    label: textMapping[Number(type) as UnitDisplayType],
    value: Number(type) as UnitDisplayType,
  }));
};

export const getPollScheduleTypeOptions = (t: TFunction) => {
  const pollScheduleTypeMapping = buildPollScheduleTypeMapping(t);
  return pollScheduleTypes.map((item) => ({
    label: pollScheduleTypeMapping[item],
    value: item,
  }));
};

export const getOperatorTypeOptions = () => {
  const operatorTypeMapping = buildOperatorTypeMapping();
  return operatorTypes.map((item) => ({
    label: operatorTypeMapping[item],
    value: item,
  }));
};

export const getAssetTypeOptions = (t: TFunction) => {
  const mapping = buildAssetTypeTextMapping(t);
  return Object.keys(AssetType).map((type) => ({
    label: mapping[Number(type) as AssetType],
    value: Number(type) as AssetType,
  }));
};

export const getEventImportanceLevelOptions = (t: TFunction) => {
  const textMapping = buildImportanceLevelTextMapping(t);
  return Object.keys(textMapping).map((type) => ({
    label: textMapping[Number(type) as EventImportanceLevelType],
    value: Number(type) as EventImportanceLevelType,
  }));
};

export const getSupportedAssetTypeOptions = (t: TFunction) => {
  const assetTypeOptions = getAssetTypeOptions(t);
  return assetTypeOptions.filter((option) =>
    supportedAssetTypes.includes(option.value)
  );
};

function getOptionsFromArray<T>(
  t: TFunction,
  // @ts-ignore
  textMappingFn: (t: TFunction) => Record<T, string>,
  arrayOfEnums: T[]
) {
  const textMapping = textMappingFn(t);
  return arrayOfEnums
    .map((enumValue) => ({
      label: textMapping[enumValue],
      value: enumValue,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

function getOptionsForTextMapping<T>(
  t: TFunction,
  // @ts-ignore
  textMappingFn: (t: TFunction) => { [key: T]: string }
) {
  const textMapping = textMappingFn(t);
  return Object.entries(textMapping).map(([key, value]) => ({
    label: value as string,
    value: (Number(key) as unknown) as T,
  }));
}

function getArrayOptions<T>(
  t: TFunction,
  // @ts-ignore
  textMappingFn: (t: TFunction) => { [key: T]: string }
) {
  const textMapping = textMappingFn(t);
  return Object.values(textMapping).map((val) => val);
}

export const getForecastModeTypeOptions = (t: TFunction) => {
  return getOptionsForTextMapping<ForecastModeType>(
    t,
    buildForecastModeTypeTextMapping
  );
};

export const getSupportedUOMTypeOptionsForTankSetup = (t: TFunction) => {
  return getOptionsForTextMapping<SupportedUOMType>(
    t,
    buildSupportedUOMTypeTextMapping
  ).filter((option) => option.value !== SupportedUOMType.BasicWithPercentFull);
};

export const getGeoAreaCategoryTypeOptions = (t: TFunction) => {
  const mappings = getOptionsForTextMapping<GeoAreaCategory>(
    t,
    buildGeoAreaCategoryTypeTextMapping
  );
  return mappings.sort((itemA: any, itemB: any) =>
    itemA.label.localeCompare(itemB.label)
  );
};

export const getAllUnitOptions = (t: TFunction) => {
  return getOptionsForTextMapping<UnitType>(
    t,
    buildUnitsOfMeasureTextMapping
  ).map((option) => ({
    ...option,
    type: unitToCategoryMapping[option.value],
  }));
};

export const getUnitsOfVolumeAndMassOptions = (t: TFunction) => {
  return getOptionsFromArray(
    t,
    buildUnitsOfMeasureTextMapping,
    unitsOfVolumeAndMass
  );
};

export const getScalingModeTypeOptions = (t: TFunction) => {
  return getOptionsForTextMapping<ScalingModeType>(
    t,
    buildScalingModeTypeTextMapping
  ).filter((option) => validScalingModeTypes.includes(option.value));
};

export const getScalingModeTypeOptionsForSensorCalibration = (t: TFunction) => {
  return getOptionsForTextMapping<ScalingModeType>(
    t,
    buildScalingModeTypeTextMapping
  ).filter((option) =>
    validScalingModeTypesForSensorCalibration.includes(option.value)
  );
};

interface GetUnitOfMeasureTypeOptions {
  supportedUOMType?: SupportedUOMType;
  scaledUnit?: string | null;
  t: TFunction;
}

export const getUnitOfMeasureTypeOptions = ({
  supportedUOMType,
  scaledUnit,
  t,
}: GetUnitOfMeasureTypeOptions) => {
  const scaledUnitOption = {
    label: scaledUnit || '',
    value: '',
    type: UnitOfMeasureCategory.Other,
  };

  switch (supportedUOMType) {
    case SupportedUOMType.Basic:
      return [scaledUnitOption];
    case SupportedUOMType.BasicWithPercentFull:
      return [
        scaledUnitOption,
        {
          label: t('enum.unittype.percentfull', '% Full'),
          value: UnitType.PercentFull,
          type: UnitOfMeasureCategory.Other,
        },
      ];
    case SupportedUOMType.SimplifiedVolumetric: {
      const unitOptions = getSimplifiedVolumetricUnits(t);
      return [scaledUnitOption, ...unitOptions];
    }
    case SupportedUOMType.Volumetric:
      return getVolumetricDisplayUnits(t);
    default:
      return [];
  }
};

// Problem Report Status Options
export const buildProblemReportStatusEnumTextMapping = (t: TFunction) => {
  return {
    [ProblemReportStatusFilterEnum.Open]: t(
      'enum.problemreportstatus.open',
      'Open'
    ),
    [ProblemReportStatusFilterEnum.Closed]: t(
      'enum.problemreportstatus.closed',
      'Closed'
    ),
    [ProblemReportStatusFilterEnum.Both]: t(
      'enum.problemreportstatus.both',
      'Both'
    ),
  };
};

// Filter Days
export const buildDaysRunOutEnumTextMapping = (t: TFunction) => {
  return {
    [DaysRunOut.All]: t('enum.daysrunout.all', 'All'),
    [DaysRunOut.ThreeDays]: t('enum.daysrunout.threeDays', '3 Days'),
    [DaysRunOut.FiveDays]: t('enum.daysrunout.fiveDays', '5 Days'),
    [DaysRunOut.TenDays]: t('enum.daysrunout.tenDays', '10 Days'),
  };
};

// Report Type

export const buildReportTypeEnumTextMapping = (t: TFunction) => {
  return {
    [ReportType.None]: t('enum.reports.none', 'None'),
    [ReportType.AssetSummaryList]: t(
      'enum.reports.assetsummarylist',
      'Asset Summary List'
    ),
    [ReportType.DataChannelsWithMissingReadings]: t(
      'enum.reports.datachannelswithmissingreadings',
      'Data Channels With Missing Readings'
    ),
    [ReportType.MissingData]: t('enum.reports.missingdata', 'Missing Data'),
    [ReportType.NewAssetList]: t('enum.reports.newassetlist', 'New Asset List'),
    [ReportType.LowBatteryVoltage]: t(
      'enum.reports.lowbatteryvoltage',
      'Low Battery Voltage'
    ),
    [ReportType.DataChannelReading]: t(
      'enum.reports.datachannelreading',
      'Data Channel Reading'
    ),
    [ReportType.DataChannelForecast]: t(
      'enum.reports.datachannelforecast',
      'Data Channel Forecast'
    ),
    [ReportType.AssetTubeTrailerFillList]: t(
      'enum.reports.assettubetrailerfilllist',
      'Asset Tube Trailer FillList'
    ),
    [ReportType.AssetList]: t('enum.reports.assetlist', 'Asset List'),
    [ReportType.RTUList]: t('enum.reports.RTUlist', 'RTU List'),
    [ReportType.DataChannelList]: t(
      'enum.reports.datachannellist',
      'Data Channel List'
    ),
    [ReportType.SiteList]: t('enum.reports.sitelist', 'Site List'),
    [ReportType.TankDimensionList]: t(
      'enum.reports.tankdimensionlist',
      'Tank Dimension List'
    ),
    [ReportType.ProductList]: t('enum.reports.productlist', 'Product List'),
    [ReportType.AssetGroupList]: t(
      'enum.reports.assetgrouplist',
      'Asset Group List'
    ),
    [ReportType.DomainList]: t('enum.reports.domainList', 'Domain List'),
    [ReportType.UserList]: t('enum.reports.userList', 'User List'),
    [ReportType.UserRoleList]: t('enum.reports.userrolelist', 'User Role List'),
    [ReportType.EventRuleGroupList]: t(
      'enum.reports.eventrulegrouplist',
      'Event Rule Group List'
    ),
    [ReportType.DataChannelTemplateList]: t(
      'enum.reports.datachanneltemplatelist',
      'Data Channel TemplateList'
    ),
    [ReportType.CustomCSVReport]: t(
      'enum.reports.customCSVreport',
      'Custom CSV Report'
    ),
    [ReportType.HornerDOEReport]: t(
      'enum.reports.hornerDOEreport',
      'Horner DOE Report'
    ),
    [ReportType.HornerDetailReport]: t(
      'enum.reports.hornerdetailreport',
      'Horner Detail Report'
    ),

    [ReportType.StoredProcedureReport01]: t(
      'enum.reports.StoredProcedureReport01',
      'Stored Procedure Report 01'
    ),
    [ReportType.StoredProcedureReport02]: t(
      'enum.reports.StoredProcedureReport02',
      'Stored Procedure Report 02'
    ),
    [ReportType.StoredProcedureReport03]: t(
      'enum.reports.StoredProcedureReport03',
      'Stored Procedure Report 03'
    ),
    [ReportType.StoredProcedureReport04]: t(
      'enum.reports.StoredProcedureReport04',
      'Stored Procedure Report 04'
    ),
    [ReportType.StoredProcedureReport05]: t(
      'enum.reports.StoredProcedureReport05',
      'Stored Procedure Report 05'
    ),
    [ReportType.StoredProcedureReport06]: t(
      'enum.reports.StoredProcedureReport06',
      'Stored Procedure Report 06'
    ),
    [ReportType.StoredProcedureReport07]: t(
      'enum.reports.StoredProcedureReport07',
      'Stored Procedure Report 07'
    ),
    [ReportType.StoredProcedureReport08]: t(
      'enum.reports.StoredProcedureReport08',
      'Stored Procedure Report 08'
    ),
    [ReportType.StoredProcedureReport09]: t(
      'enum.reports.StoredProcedureReport09',
      'Stored Procedure Report 09'
    ),
    [ReportType.StoredProcedureReport10]: t(
      'enum.reports.StoredProcedureReport10',
      'Stored Procedure Report 10'
    ),
    [ReportType.StoredProcedureReport11]: t(
      'enum.reports.StoredProcedureReport11',
      'Stored Procedure Report 11'
    ),
    [ReportType.StoredProcedureReport12]: t(
      'enum.reports.StoredProcedureReport12',
      'Stored Procedure Report 12'
    ),
    [ReportType.StoredProcedureReport13]: t(
      'enum.reports.StoredProcedureReport13',
      'Stored Procedure Report 13'
    ),
    [ReportType.StoredProcedureReport14]: t(
      'enum.reports.StoredProcedureReport14',
      'Stored Procedure Report 14'
    ),
    [ReportType.StoredProcedureReport15]: t(
      'enum.reports.StoredProcedureReport15',
      'Stored Procedure Report 15'
    ),
    [ReportType.StoredProcedureReport16]: t(
      'enum.reports.StoredProcedureReport16',
      'Stored Procedure Report 16'
    ),
    [ReportType.StoredProcedureReport17]: t(
      'enum.reports.StoredProcedureReport17',
      'Stored Procedure Report 17'
    ),
    [ReportType.StoredProcedureReport18]: t(
      'enum.reports.StoredProcedureReport18',
      'Stored Procedure Report 18'
    ),
    [ReportType.StoredProcedureReport19]: t(
      'enum.reports.StoredProcedureReport19',
      'Stored Procedure Report 19'
    ),
    [ReportType.StoredProcedureReport20]: t(
      'enum.reports.StoredProcedureReport20',
      'Stored Procedure Report 20'
    ),
    [ReportType.StoredProcedureReport21]: t(
      'enum.reports.StoredProcedureReport21',
      'Stored Procedure Report 21'
    ),
    [ReportType.StoredProcedureReport22]: t(
      'enum.reports.StoredProcedureReport22',
      'Stored Procedure Report 22'
    ),
    [ReportType.StoredProcedureReport23]: t(
      'enum.reports.StoredProcedureReport23',
      'Stored Procedure Report 23'
    ),
    [ReportType.StoredProcedureReport24]: t(
      'enum.reports.StoredProcedureReport24',
      'Stored Procedure Report 24'
    ),
    [ReportType.StoredProcedureReport25]: t(
      'enum.reports.StoredProcedureReport25',
      'Stored Procedure Report 25'
    ),
    [ReportType.StoredProcedureReport26]: t(
      'enum.reports.StoredProcedureReport26',
      'Stored Procedure Report 26'
    ),
    [ReportType.StoredProcedureReport27]: t(
      'enum.reports.StoredProcedureReport27',
      'Stored Procedure Report 27'
    ),
    [ReportType.StoredProcedureReport28]: t(
      'enum.reports.StoredProcedureReport28',
      'Stored Procedure Report 28'
    ),
    [ReportType.StoredProcedureReport29]: t(
      'enum.reports.StoredProcedureReport29',
      'Stored Procedure Report 29'
    ),
    [ReportType.StoredProcedureReport30]: t(
      'enum.reports.StoredProcedureReport30',
      'Stored Procedure Report 30'
    ),
    [ReportType.StoredProcedureReport31]: t(
      'enum.reports.StoredProcedureReport31',
      'Stored Procedure Report 31'
    ),
    [ReportType.StoredProcedureReport32]: t(
      'enum.reports.StoredProcedureReport32',
      'Stored Procedure Report 32'
    ),
    [ReportType.StoredProcedureReport33]: t(
      'enum.reports.StoredProcedureReport33',
      'Stored Procedure Report 33'
    ),
    [ReportType.StoredProcedureReport34]: t(
      'enum.reports.StoredProcedureReport34',
      'Stored Procedure Report 34'
    ),
    [ReportType.StoredProcedureReport35]: t(
      'enum.reports.StoredProcedureReport35',
      'Stored Procedure Report 35'
    ),
    [ReportType.StoredProcedureReport36]: t(
      'enum.reports.StoredProcedureReport36',
      'Stored Procedure Report 36'
    ),
    [ReportType.StoredProcedureReport37]: t(
      'enum.reports.StoredProcedureReport37',
      'Stored Procedure Report 37'
    ),
    [ReportType.StoredProcedureReport38]: t(
      'enum.reports.StoredProcedureReport38',
      'Stored Procedure Report 38'
    ),
    [ReportType.StoredProcedureReport39]: t(
      'enum.reports.StoredProcedureReport39',
      'Stored Procedure Report 39'
    ),
    [ReportType.StoredProcedureReport40]: t(
      'enum.reports.StoredProcedureReport40',
      'Stored Procedure Report 40'
    ),
    [ReportType.StoredProcedureReport41]: t(
      'enum.reports.StoredProcedureReport41',
      'Stored Procedure Report 41'
    ),
    [ReportType.StoredProcedureReport42]: t(
      'enum.reports.StoredProcedureReport42',
      'Stored Procedure Report 42'
    ),
    [ReportType.StoredProcedureReport43]: t(
      'enum.reports.StoredProcedureReport43',
      'Stored Procedure Report 43'
    ),
    [ReportType.StoredProcedureReport44]: t(
      'enum.reports.StoredProcedureReport44',
      'Stored Procedure Report 44'
    ),
    [ReportType.StoredProcedureReport45]: t(
      'enum.reports.StoredProcedureReport45',
      'Stored Procedure Report 45'
    ),
    [ReportType.StoredProcedureReport46]: t(
      'enum.reports.StoredProcedureReport46',
      'Stored Procedure Report 46'
    ),
    [ReportType.StoredProcedureReport47]: t(
      'enum.reports.StoredProcedureReport47',
      'Stored Procedure Report 47'
    ),
    [ReportType.StoredProcedureReport48]: t(
      'enum.reports.StoredProcedureReport48',
      'Stored Procedure Report 48'
    ),
    [ReportType.StoredProcedureReport49]: t(
      'enum.reports.StoredProcedureReport49',
      'Stored Procedure Report 49'
    ),
    [ReportType.StoredProcedureReport50]: t(
      'enum.reports.StoredProcedureReport50',
      'Stored Procedure Report 50'
    ),
  };
};
export const getPaymeterEnumOptions = (t: TFunction) => {
  const textMapping = buildPaymeterTypeTextMapping(t);
  return Object.keys(textMapping).map((type) => ({
    label: textMapping[Number(type) as APCIPaymeterType],
    value: Number(type) as APCIPaymeterType,
  }));
};

export const getDaysRunOutEnumOptions = (t: TFunction) => {
  const textMapping = buildDaysRunOutEnumTextMapping(t);
  return Object.keys(textMapping).map((type) => ({
    label: textMapping[Number(type) as DaysRunOut],
    value: Number(type) as DaysRunOut,
  }));
};

export const getProblemReportStatusEnumOptions = (t: TFunction) => {
  return getOptionsForTextMapping<ProblemReportStatusFilterEnum>(
    t,
    buildProblemReportStatusEnumTextMapping
  );
};

export const getProblemReportPriorityLevelOptions = (t: TFunction) => {
  const textMapping = buildProblemReportPriorityTextMapping(t);
  return Object.keys(textMapping).map((type) => ({
    label: textMapping[Number(type) as ProblemReportPriorityEnum],
    value: Number(type) as ProblemReportPriorityEnum,
  }));
};

export const getAPCIUnitTypeOptionsForIntegrationInfo = (t: TFunction) => {
  return getOptionsForTextMapping<APCIUnitType>(
    t,
    buildAPCIUnitTypeTextMapping
  );
};

export const getAPCITankFunctionTypeOptionsForIntegrationInfo = (
  t: TFunction
) => {
  return getOptionsForTextMapping<APCIUnitType>(
    t,
    buildAPCITankFunctionTypeTextMapping
  );
};

export const getUnitConversionModeEnumOptionsForTankSetupInfo = (
  t: TFunction
) => {
  return getOptionsForTextMapping<UnitConversionModeEnum>(
    t,
    buildUnitConversionModeTextMapping
  );
};

export const getUnitTypeEnumOptionsForDataChannelEditor = (t: TFunction) => {
  return getOptionsForTextMapping<UnitTypeEnum>(
    t,
    buildUnitTypeEnumTextMapping
  );
};

export const getGasMixerDataChannelTypeOptions = (t: TFunction) => {
  return getOptionsForTextMapping<GasMixerDataChannelType>(
    t,
    buildGasMixerDataChannelTypeTextMapping
  );
};

export const getFtpFileFormatCategoryOptions = (t: TFunction) => {
  return getOptionsForTextMapping<FtpFileFormatCategory>(
    t,
    buildFtpFileFormatCategoryTextMapping
  );
};

export const getDataSourceTypeOptions = (t: TFunction) => {
  return getOptionsForTextMapping<DataChannelDataSource>(
    t,
    buildDataSourceTypeTextMapping
  );
};

export const getFreezerAssetSubTypeOptions = (t: TFunction) => {
  return getOptionsForTextMapping<AssetSubTypeEnum>(
    t,
    buildFreezerAssetSubTypeMapping
  );
};

export const getForecastModeOptions = (t: TFunction) => {
  return getOptionsForTextMapping<ForecastMode>(
    t,
    buildForecastModeTextMapping
  );
};

export const getInventoryStatusTypeOptions = (t: TFunction) => {
  return getOptionsForTextMapping<EventInventoryStatusType>(
    t,
    buildInventoryStatusTypeEnumTextMapping
  );
};

export const getRtuPacketCategoryOptions = (t: TFunction) => {
  return getOptionsForTextMapping<RtuPacketCategory>(
    t,
    buildPacketTypeGroupTextMapping
  );
};

export const getRTUCommunicationDirectionEnumOptions = (t: TFunction) => {
  return getOptionsForTextMapping<RtuPacketsChannelTypeForFilter>(
    t,
    buildRTUCommunicationDirectionTextMapping
  );
};

export const getRtuChannelTypeOptions = (t: TFunction) => {
  return getOptionsForTextMapping<RtuPacketsChannelType>(
    t,
    buildRtuChannelTypeTextMapping
  );
};

export const getRtuStatusTypeOptions = (t: TFunction) => {
  return getOptionsForTextMapping<RcmJournalItemStatusEnum>(
    t,
    buildRtuStatusTypeTextMapping
  );
};

export const getCarriersArray = (t: TFunction) => {
  return getArrayOptions<TelecommunicationsCarrier>(
    t,
    buildCommunicationMethodGroupTextMapping
  );
};
